import { API_URL } from "../utils/constants";
import { IConstructionProgressItem } from "./constructionProgressService";

export interface IPaymentPlanItem {
  id: number;
  due_date: string;
  description: string;
  percentage: number | null;
  fixed_amount: number | null;
  created_at: string;
}

export interface IProperty {
  property_id: number;
  property_name: string;
  property_image: string;
  property_location: string;
  property_map_link: string;
  email: string | null;
  phone_number: string | null;
  plot_no: number;
  plot_purchased_price: number;
  plot_size: number;
  total_build_up_area_size: number;
  registration_number: string;
  under_construction: boolean;
  created_at: string;
  payment_plan_items: IPaymentPlanItem[];
  construction_progress_items: IConstructionProgressItem[];
}

export const fetchProperty = async (
  propertyId: number
): Promise<IProperty | null> => {
  const response = await fetch(`${API_URL}/properties/${propertyId}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};
