import React from 'react';
import {
    DocumentRow,
    DocumentName,
    DocumentDate,
    DocumentCategory,
    ActionIcon,
    DocumentRowMobile,
} from './document-card.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';
import { IDocument } from '../../services/documentService';
import useIsMobile from '../../hooks/useIsMobile';

interface DocumentCardProps {
    document: IDocument;
    showCategory?: boolean;
    onDownload?: (document: IDocument) => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ document, showCategory = true, onDownload }) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <DocumentRowMobile>
                <div className="d-flex align-items-center" style={{ gap: 16 }}>
                    <MaterialIcon icon="draft" size={24} color={colorPalette.errorDark} />
                    <DocumentName>{document.file_name}</DocumentName>
                </div>
                {onDownload && (
                    <MaterialIcon icon="chevron_forward" style="outlined" size={24} color={colorPalette.blue500} onClick={() => onDownload(document)} />
                )}
            </DocumentRowMobile>
        );
    } else {
        return (
            <DocumentRow showCategory={showCategory}>
                <MaterialIcon icon="draft" size={24} color={colorPalette.errorDark} />
                <DocumentName>{document.file_name}</DocumentName>
                <DocumentDate>{new Date(document.created_at).toLocaleDateString()}</DocumentDate>
                {showCategory && (
                    <DocumentCategory>Unbekannt</DocumentCategory>
                )}
                {onDownload && (
                    <ActionIcon onClick={() => onDownload(document)}>
                        <MaterialIcon icon="file_save" style="outlined" size={24} color={colorPalette.blue500} />
                    </ActionIcon>
                )}
            </DocumentRow>
        );
    }
};

export default DocumentCard;
