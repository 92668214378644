import styled, { css } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

interface FooterContainerProps {
  themeVariant: "dark" | "light" | "white";
}

export const FooterContainer = styled.footer<FooterContainerProps>`
  width: 100%;

  ${({ themeVariant }) => {
    switch (themeVariant) {
      case "dark":
        return css`
          background-color: ${colorPalette.blue900};
          color: ${colorPalette.white};
        `;
      case "light":
        return css`
          background-color: ${colorPalette.blue300};
          color: ${colorPalette.textMain};
        `;
      case "white":
      default:
        return css`
          border-top: 1px solid ${colorPalette.blue300};
          background-color: ${colorPalette.white};
          color: ${colorPalette.textMain};
        `;
    }
  }}
`;

export const FooterContent = styled.div<FooterContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;

  ${({ themeVariant }) => {
    switch (themeVariant) {
      case "dark":
        return css`
          border-bottom: 1px solid ${colorPalette.blue700};
        `;
      case "light":
        return css`
          border-bottom: 1px solid #fafbfc66;
        `;
      case "white":
      default:
        return css`
          border-bottom: 1px solid ${colorPalette.blue200};
        `;
    }
  }}
`;

export const FooterLogo = styled.img`
  height: 20px;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const FooterLink = styled.a<FooterContainerProps>`
  margin: 0;
  text-decoration: none;
  color: ${({ themeVariant }) =>
    themeVariant === "dark" ? colorPalette.blue50 : colorPalette.textMain};
  ${typeScale.bodySMMain};

  &:hover {
    color: ${colorPalette.blue400};
  }
`;

export const FooterCopyright = styled.div<FooterContainerProps>`
  padding: 16px;
  text-align: center;
  ${typeScale.bodyXXSMain};

  ${({ themeVariant }) => {
    switch (themeVariant) {
      case "dark":
        return css`
          color: ${colorPalette.textDark};
        `;
      case "light":
        return css`
          color: ${colorPalette.blue200};
        `;
      case "white":
      default:
        return css`
          color: ${colorPalette.textDark};
        `;
    }
  }}
`;
