import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";

export interface IBankDetail {
  details_id: number;
  iban: string;
  swift: string;
  bank_name: string;
  account_holder_name: string;
  currency: string;
  branch_number?: string | null;
  account_number?: number | null;
  created_at: string;
  updated_at: string;
  documents: IDocument[];
}

export const createBankDetail = async (
  iban: string,
  swift: string,
  bank_name: string,
  currency: string,
  branch_number?: string | null,
  account_number?: number | null
): Promise<IBankDetail> => {
  const response = await fetch(`${API_URL}/bank-details`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      iban,
      swift,
      bank_name,
      currency,
      branch_number,
      account_number,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateBankDetail = async (
  details_id: number,
  iban: string,
  swift: string,
  bank_name: string,
  currency: string,
  branch_number?: string | null,
  account_number?: number | null
): Promise<IBankDetail> => {
  const response = await fetch(`${API_URL}/bank-details/${details_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      iban,
      swift,
      bank_name,
      currency,
      branch_number,
      account_number,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};
