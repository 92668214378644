import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 34px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${colorPalette.primaryDark};
  }
`;

export const NavItemLabel = styled.p.withConfig({
  shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>`
  margin: 0;
  color: ${({ active }) =>
    active ? colorPalette.blue500 : colorPalette.textMain};
  ${typeScale.bodyXXSMain}
`;

export const IconWrapper = styled.div`
  margin-bottom: 4px;
`;
