import styled from "styled-components";

export const GalleryContainer = styled.div`
  padding: 12px 16px;
`;

export const MainImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 395px;
  object-fit: cover;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;
`;

export const SmallImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 190px;
  object-fit: cover;
`;
