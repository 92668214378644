import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Container for the entire news section
export const NewsContainer = styled.div`
  width: 100%;
  margin-top: 48px;
  padding: 0 16px;

  .news-cards-wrapper {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const MobileNewsCardContainer = styled.div`
  width: 100%;
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
`;

export const MobileNewsCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorPalette.blue200};
  padding: 16px;

  &:last-child {
    border-bottom: none;
  }
`;

// Header Title
export const HeaderTitle = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    margin-bottom: 0;
    ${typeScale.headingSMMain};
  }
`;

// News Card
export const NewsCard = styled.div`
  width: 100%;
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  padding: 16px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(0.98);
    cursor: pointer;
  }
`;

// Content inside each News Card
export const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

// Date and Source (above the title)
export const NewsDate = styled.p`
  margin-bottom: 16px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};

  @media (max-width: 767.98px) {
    margin-bottom: 8px;
  }
`;

// Title of the news
export const NewsTitle = styled.h3`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};

  @media (max-width: 767.98px) {
    margin-bottom: 0px;
    ${typeScale.headingXXSMain};
  }
`;

// Description (below the title)
export const NewsDescription = styled.p`
  margin-bottom: 16px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;

// Read More link
export const ButtonWrapper = styled.div`
  text-align: start;
`;
