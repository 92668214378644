import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const CardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "centerContent",
})<{ centerContent?: boolean }>`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};

  ${({ centerContent }) =>
    centerContent &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  margin-top: 48px;
  margin-bottom: 16px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const ViewAllLink = styled.button`
  background: none;
  border: none;
  color: ${colorPalette.blue500};
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodySMMain}

  &:hover {
    cursor: pointer;
  }
`;

export const ExtraDetails = styled.div``;

export const RentSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Amount = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingL}
  font-weight: bold;
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

export const GrossReturn = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingL}
`;

export const Label = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySMMain}
`;

export const Transactions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-top: 1px solid ${colorPalette.blue200};
`;

export const TransactionAmount = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
`;

export const TransactionTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
`;

export const TransactionDueDate = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}

  span {
    margin-left: 4px;
    color: ${colorPalette.successMedium};
    ${typeScale.bodyXXS}
  }
`;

export const ExtraDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 14px;
  border-bottom: 1px solid ${colorPalette.blue100};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ExtraDetailHeadline = styled.div`
  padding: 24px 16px;
  color: ${colorPalette.blue900};
  ${typeScale.headingXXSMain}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  margin: 0;
  flex: 1;
  gap: 8px;
  align-items: center;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;

// Tooltip icon styling
export const TooltipIcon = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.white};

  &:hover {
    color: #ededed;
  }
`;

export const ExtraDetailLabel = styled.p`
  margin: 0;
  flex: 1;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
`;

export const ExtraDetailValue = styled.p`
  margin: 0;
  color: ${colorPalette.blue900};
  ${typeScale.bodyMDMain}
`;

export const NoActiveContractContainer = styled.div`
  padding: 24px;
  text-align: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically if needed */
`;

export const NoActiveContractTitle = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textDark};
  ${typeScale.headingXSMain}
`;

export const NoActiveContractDescription = styled.p`
  margin-bottom: 16px;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}
`;
