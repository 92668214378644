import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const SelectContainer = styled.div`
  position: relative;
`;

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormSelect = styled.select.withConfig({
  shouldForwardProp: (prop) => prop !== "isError",
})<{ isError: boolean }>`
  /* Hides the default dropdown arrow across browsers */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  background-image: none;

  height: 45px;
  width: 100%;
  padding: 1rem 0.75rem;
  padding-right: 30px;
  border: 1px solid
    ${({ isError }) =>
      isError ? colorPalette.errorDark : colorPalette.blue300};
  border-radius: 4px;
  transition: border-color 0.3s ease;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};

  &:focus {
    border-color: ${({ isError }) =>
      isError ? colorPalette.errorDark : colorPalette.blue400};
    outline: none;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: ${colorPalette.blue50};
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${colorPalette.textMain};
    ${typeScale.bodyMD};
  }
`;

export const InputField = styled.input.withConfig({
  shouldForwardProp: (prop) => prop !== "isError",
})<{ isError: boolean }>`
  appearance: none;
  height: 45px;
  width: 100%;
  padding: 1rem 0.75rem;
  padding-right: 30px;
  border: 1px solid
    ${({ isError }) =>
      isError ? colorPalette.errorDark : colorPalette.blue300};
  border-radius: 4px;
  transition: border-color 0.3s ease;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};

  &:focus {
    border-color: ${({ isError }) =>
      isError ? colorPalette.errorDark : colorPalette.blue400};
    outline: none;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: ${colorPalette.blue50};
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${colorPalette.textMain};
    ${typeScale.bodyMD};
  }
`;

export const Label = styled.label`
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS};
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${colorPalette.blue50};
  display: flex;
  align-items: center;

  &:hover {
    color: ${colorPalette.textMain};
  }
`;

export const DropdownIconContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "showClearIcon",
})<{ showClearIcon: boolean }>`
  position: absolute;
  right: ${({ showClearIcon }) => (showClearIcon ? "30px" : "10px")};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${colorPalette.blue50};
  display: flex;
  align-items: center;

  &:hover {
    color: ${colorPalette.textMain};
  }
`;

export const ErrorText = styled.small`
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: 0;
  color: ${colorPalette.errorDark};
  ${typeScale.bodyXS};
`;

export const SubtitleText = styled.p`
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: 0;
  max-width: 100%;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
`;

export const DropdownItem = styled.li`
  padding: 0.25rem 1.5rem;
  cursor: pointer;
  color: ${colorPalette.textMain};

  &:hover,
  &:focus {
    background-color: ${colorPalette.blue50};
  }
`;
