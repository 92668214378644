import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const ActionSheetContainer = styled.div`
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  padding: 32px;
  border-radius: 8px;
  min-width: 705px;
  text-align: center;
  z-index: 10000;

  @media (max-width: 767.98px) {
    min-width: 0;
    padding: 16px;
    margin: 16px;
  }
`;

export const ImageIllustration = styled.img`
  width: 200px;
  margin-bottom: 32px;

  @media (max-width: 767.98px) {
    width: 150px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Description = styled.p`
  margin-bottom: 32px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    ${typeScale.bodySM};
  }
`;

export const ButtonWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;
