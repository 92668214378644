import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

// Container for the entire card + banner
export const Container = styled.div`
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 100%;
  min-height: 200px;

  &:hover {
    transform: scale(1.01);
  }
`;

// Container for the entire card
export const UnitCardContainer = styled.div`
  display: block;
  background-color: ${colorPalette.white};
  overflow: hidden;
  position: relative;
  height: 100%;
`;

// Image container for the unit image
export const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  margin-right: 16px;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  overflow: hidden;
`;

// Content of the unit card
export const UnitContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: 0px;
  height: 100%;
`;

// Header for the title, tags, and subtitle
export const UnitHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;

// New container for title and tags (they share one row)
export const TitleTagsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
`;

// Title of the unit
export const UnitTitle = styled.h3`
  margin-bottom: 4px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}
`;

// Tags container for unit status and package
export const UnitTagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

// Subtitle container with location icon
export const SubtitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

// Subtitle for the property name
export const UnitSubtitle = styled.h4`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}
`;

// Details section
export const UnitDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

// Layout for the statistics
export const UnitStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-width: 1px 0px 1px;
  border-style: solid;
  border-color: ${colorPalette.blue200};
`;

// Individual statistic item
export const UnitStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodyXS}
  color: ${colorPalette.textMain};
`;

// Label for the statistics
export const UnitStatisticLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain}
`;

// Value of the statistic
export const UnitNumber = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyLGMain}
`;

// Layout for the unit status
export const UnitStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
`;

// Individual unit status item
export const UnitStatusItem = styled.div`
  gap: 4px;
`;

// Label for the unit status item
export const UnitStatusLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}
`;
