import styled, { keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Animation for the no units message
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 32px;
`;

export const HeaderContainer = styled.div`
  gap: 12px;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 767.98px) {
    gap: 8px;
  }
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingL}

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const UnitAmount = styled.h4`
  width: 36px;
  height: 36px;
  margin: 0;
  border-radius: 50%;
  background-color: ${colorPalette.primaryDark};
  color: ${colorPalette.white};
  display: flex;
  justify-content: center;
  align-items: center;
  ${typeScale.headingXS}

  @media (max-width: 767.98px) {
    width: 28px;
    height: 28px;
    ${typeScale.headingXXSMain}
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;

  /* Hide scrollbar for all browsers */
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export const NoUnitsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const NoUnitsMessage = styled.p`
  font-size: 14px;
  opacity: 0;
  animation: ${fadeInUp} 1s forwards 1s;
  margin-top: -120px;
  text-align: center;
`;

export const Row = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
`;

export const Col = styled.div`
  flex: 0 0 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
