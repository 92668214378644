import React from 'react';
import { useNavigate } from 'react-router-dom';
import BankIllustration from '../../../../assets/images/pencil_icon.png';
import CTAButton from '../../../../components/cta-button/cta-button';
import {
    BannerContainer,
    BannerMainWrapper,
    BannerImageWrapper,
    BannerContentWrapper,
    BannerText,
    BannerSubText,
    BannerButtonWrapper
} from './bank-details-banner.styles';

const BankDetailsBanner: React.FC = () => {
    const navigate = useNavigate();

    return (
        <BannerContainer>
            <BannerMainWrapper>
                <BannerImageWrapper>
                    <img src={BankIllustration} alt="Bank Illustration" />
                </BannerImageWrapper>
                <BannerContentWrapper>
                    <BannerText>Füge eine Bankverbindung hinzu.</BannerText>
                    <BannerSubText>
                        Bitte füge eine Bankverbindung hinzu, damit dir die Mieteinnahmen gutgeschrieben werden können.
                    </BannerSubText>
                </BannerContentWrapper>
            </BannerMainWrapper>

            {/* Desktop Button */}
            <BannerButtonWrapper className="d-none d-md-inline">
                <CTAButton
                    variant="primary"
                    size="S"
                    label="Jetzt Hinzufügen"
                    onClick={() => navigate("/profile/edit-bank-details")}
                />
            </BannerButtonWrapper>

            {/* Mobile Button */}
            <CTAButton
                className="d-md-none"
                variant="primary"
                size="S"
                label="Jetzt Hinzufügen"
                onClick={() => navigate("/profile/edit-bank-details")}
                fullWidth
            />
        </BannerContainer>
    );
};

export default BankDetailsBanner;
