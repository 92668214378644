import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IUnit } from "../services/unitService";

export const UNIT_STORAGE_NAME = "unit-storage";

interface UnitState {
  units: IUnit[];
  currentPage: number;
  isLoading: boolean;
  isInitialLoaded: boolean;
  moreDataAvailable: boolean;
  setUnits: (units: IUnit[]) => void;
  setCurrentPage: (page: number) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsInitialLoaded: (isInitialLoaded: boolean) => void;
  setMoreDataAvailable: (moreData: boolean) => void;
  reset: () => void;
}

const useUnitStore = create<UnitState>()(
  devtools(
    persist(
      (set) => ({
        units: [],
        currentPage: 1,
        isLoading: true,
        isInitialLoaded: false,
        moreDataAvailable: true,
        setUnits: (units: IUnit[]) => set({ units }),
        setCurrentPage: (page: number) => set({ currentPage: page }),
        setIsLoading: (isLoading: boolean) => set({ isLoading: isLoading }),
        setIsInitialLoaded: (isInitialLoaded: boolean) =>
          set({ isInitialLoaded: isInitialLoaded }),
        setMoreDataAvailable: (moreData: boolean) =>
          set({ moreDataAvailable: moreData }),
        reset: () => {
          set({
            units: [],
            isLoading: true,
            isInitialLoaded: false,
          });
        },
      }),
      {
        name: UNIT_STORAGE_NAME,
        getStorage: () => sessionStorage,
      }
    )
  )
);

export default useUnitStore;
