import React from 'react';
import {
    NewsContainer,
    NewsCard,
    NewsContent,
    NewsDate,
    NewsTitle,
    NewsDescription,
    ButtonWrapper,
    HeaderTitle,
    MobileNewsCardContainer,
    MobileNewsCard
} from './news-updates.styles';
import CTAButton from '../cta-button/cta-button';
import { Col, Row } from 'react-bootstrap';
import useIsMobile from '../../hooks/useIsMobile';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';

interface NewsItem {
    id: number;
    title: string;
    date: string;
    source: string;
    description: string;
}

const mockNews: NewsItem[] = [
    {
        id: 1,
        title: 'Q3 2024 ist das stärkste Quartal für Immobilien-investitionen in Dubai',
        date: '22.09.2024',
        source: 'Dubai Land Department',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 2,
        title: 'Q3 2024 ist das stärkste Quartal für Immobilien-investitionen in Dubai',
        date: '22.09.2024',
        source: 'Dubai Land Department',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 3,
        title: 'Q3 2024 ist das stärkste Quartal für Immobilien-investitionen in Dubai',
        date: '22.09.2024',
        source: 'Dubai Land Department',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
];

const NewsUpdates: React.FC = () => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <>
                <NewsContainer>
                    <div className="d-flex align-items-center" style={{ gap: 4, marginBottom: 16 }} onClick={isMobile ? () => {
                        alert("Coming soon...");
                    } : undefined}>
                        <HeaderTitle>News & Updates</HeaderTitle>
                        <MaterialIcon className="d-md-none d-inline" icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </div>
                    <MobileNewsCardContainer>
                        {mockNews.map((newsItem) => (
                            <MobileNewsCard>
                                <div>
                                    <NewsDate>{newsItem.date} • {newsItem.source}</NewsDate>
                                    <NewsTitle>{newsItem.title}</NewsTitle>
                                </div>
                                <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} onClick={() => { }} />
                            </MobileNewsCard>
                        ))}
                    </MobileNewsCardContainer>
                </NewsContainer>
            </>
        );
    } else {
        return (
            <NewsContainer>
                <HeaderTitle>News & Updates</HeaderTitle>
                <Row className="gy-3 gx-3">
                    {mockNews.map((newsItem) => (
                        <Col sm={12} md={6} xxl={4} key={newsItem.id}>
                            <NewsCard>
                                <NewsContent>
                                    <NewsDate>{newsItem.date} • {newsItem.source}</NewsDate>
                                    <NewsTitle>{newsItem.title}</NewsTitle>
                                    <NewsDescription>{newsItem.description}</NewsDescription>
                                    <ButtonWrapper>
                                        <CTAButton
                                            variant="ghost"
                                            size="S"
                                            label="Weiterlesen"
                                            icon="arrow_outward"
                                            onClick={() => { }}
                                            noPadding
                                        />
                                    </ButtonWrapper>
                                </NewsContent>
                            </NewsCard>
                        </Col>
                    ))}
                </Row>
            </NewsContainer>
        );
    }
};

export default NewsUpdates;
