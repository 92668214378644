import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const FooterContainer = styled.footer`
  background-color: ${colorPalette.blue200};
  padding: 24px;
  border-top: 1px solid ${colorPalette.blue300};
`;

export const FooterLogo = styled.img`
  width: 125px;
  height: 24px;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const FooterLink = styled.a`
  color: ${colorPalette.textMain};
  text-decoration: none;
  ${typeScale.bodyLG};

  &:hover {
    text-decoration: underline;
  }
`;
