import React from 'react';
import { TabBarContainer, TabButton } from './tab-bar.styles';

interface Tab {
    label: string;
    value: string;
}

interface TabBarProps {
    tabs: Tab[];
    activeTab: string;
    onTabChange: (tabValue: string) => void;
}

const TabBar: React.FC<TabBarProps> = ({ tabs, activeTab, onTabChange }) => {
    return (
        <TabBarContainer>
            {tabs.map((tab) => (
                <TabButton
                    key={tab.value}
                    active={activeTab === tab.value}
                    onClick={() => onTabChange(tab.value)}
                >
                    {tab.label}
                </TabButton>
            ))}
        </TabBarContainer>
    );
};

export default TabBar;
