import React, { useCallback } from 'react';
import {
    LeaseCardContainer,
    LeaseInfo,
    LeaseTitle,
    LeaseSubtitle,
    LeaseAmountContainer,
    LeaseAmount,
    LeasePercentageChange,
    LeaseContractLink,
    ArrowIconContainer,
    IconWrapper,
    Container,
    Headline,
    LeaseDetailsContainer,
    LeaseContractContainer,
    LeaseContractText,
} from './lease-card.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { formatMoney } from '../../../../utils/helpers';
import { downloadLeaseDocument } from '../../../../services/leaseService';
import { IDocument } from '../../../../services/documentService';

interface LeaseCardProps {
    lease_id: number;
    lease_document?: IDocument;
    name: string;
    startDate: string;
    endDate: string;
    rentAmount: number;
    rentChangePercentage?: number; // +6% or -3%, etc.
}

const LeaseCard: React.FC<LeaseCardProps> = ({
    lease_id,
    lease_document,
    name,
    startDate,
    endDate,
    rentAmount,
    rentChangePercentage,
}) => {
    // Functions
    const onDownloadLeaseDocument = useCallback(async () => {
        if (lease_id && lease_document) {
            try {
                const blob: Blob | null = await downloadLeaseDocument(lease_id, lease_document.document_id);
                if (blob) {
                    const blobUrl = window.URL.createObjectURL(blob);

                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = lease_document.file_name;
                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            } catch (error) {
                console.log("error while downloading lease document: ", error);
            }
        }
    }, [lease_id, lease_document]);

    return (
        <Container>
            <Headline>{`${new Date(startDate).getFullYear()} - ${new Date(endDate).getFullYear()}`}</Headline>
            <LeaseCardContainer>
                <LeaseDetailsContainer>
                    <LeaseInfo>
                        <IconWrapper>
                            <MaterialIcon icon="output" size={24} color={colorPalette.textDark} />
                        </IconWrapper>
                        <div>
                            <LeaseTitle>{name}</LeaseTitle>
                            <LeaseSubtitle>{`${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`}</LeaseSubtitle>
                        </div>
                    </LeaseInfo>
                    <LeaseAmountContainer>
                        <LeaseAmount>AED {formatMoney(rentAmount, false)}</LeaseAmount>
                        {rentChangePercentage && (
                            <LeasePercentageChange>{rentChangePercentage}</LeasePercentageChange>
                        )}
                    </LeaseAmountContainer>
                </LeaseDetailsContainer>
                {document && (
                    <LeaseContractContainer onClick={onDownloadLeaseDocument}>
                        <LeaseContractLink>
                            <MaterialIcon icon="draft" size={24} color={colorPalette.errorDark} />
                            <LeaseContractText>Mietvertrag</LeaseContractText>
                        </LeaseContractLink>
                        <ArrowIconContainer>
                            <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                        </ArrowIconContainer>
                    </LeaseContractContainer>
                )}
            </LeaseCardContainer>
        </Container>
    );
};

export default LeaseCard;
