import React from 'react';
import { SwitchContainer, SwitchInput, SwitchLabel, Slider } from './switch-new.styles';

interface SwitchProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label?: string;
    disabled?: boolean;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange, label, disabled = false }) => {
    return (
        <SwitchContainer>
            <SwitchInput
                type="checkbox"
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
            />
            <Slider className="slider" />
            {label && <SwitchLabel>{label}</SwitchLabel>}
        </SwitchContainer>
    );
};

export default Switch;
