import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 48px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const ContactListContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  border: 1px solid ${colorPalette.blue100};
  border-radius: 8px;
  overflow: hidden;
`;

// Header Title
export const HeaderTitle = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const ContactCard = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};

  &:last-child {
    border-bottom: none;
  }
`;

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
`;

export const ContactDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const RoleText = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};
`;

export const NameText = styled.p`
  margin: 4px 0 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
`;

export const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const IconButton = styled.div<{ background: "light" | "dark" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: ${colorPalette.textMain};
  font-size: 16px;
  cursor: pointer;
  background-color: ${({ background }) =>
    background === "light" ? colorPalette.blue300 : colorPalette.primaryDark};
  color: ${({ background }) =>
    background === "light" ? colorPalette.textMain : colorPalette.white};

  &:hover {
    opacity: 0.8;
  }
`;
