import React, { useEffect, useRef, useState } from 'react';
import { Placeholder } from './lazy-load-image.styles';
import LightLogoIcon from '../../assets/icons/logo-icon-light.png';

interface LazyLoadImageProps {
    src: string;
    alt: string;
    margin?: string | number;
    maxWidth?: string | number;
    maxHeight?: string | number;
    width?: string | number;
    height?: string | number;
    borderRadius?: string;
    onClick?: () => void;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
    src,
    alt,
    margin,
    maxWidth,
    maxHeight,
    width,
    height,
    borderRadius,
    onClick,
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);

    const handleLoad = () => {
        setIsLoaded(true);
        setHasError(false);
    };

    const handleError = () => {
        setHasError(true);
        setIsLoaded(false);
    };

    useEffect(() => {
        const imgElement = imgRef.current;
        if (!imgElement) return;

        observer.current = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !isLoaded) {
                    imgElement.src = src;
                    observer.current?.unobserve(imgElement);
                }
            });
        });

        // Start observing the image
        observer.current.observe(imgElement);

        // Cleanup
        return () => {
            observer.current?.unobserve(imgElement);
        };
    }, [src, isLoaded]);

    return (
        <>
            {(!isLoaded || hasError) && (
                <Placeholder style={{ borderRadius: borderRadius ? borderRadius : 0, width, height, maxHeight, maxWidth, margin }}>
                    {/* Placeholder is visible while the image is loading */}
                    <img src={LightLogoIcon} alt="Placeholder image" style={{ width: 44, height: 44 }} />
                </Placeholder>
            )}
            <img
                ref={imgRef}
                src={isLoaded ? src : ''}
                alt={alt}
                style={{
                    width,
                    height,
                    maxHeight,
                    maxWidth,
                    margin,
                    borderRadius: borderRadius ? borderRadius : 0,
                    objectFit: 'cover',
                    opacity: isLoaded ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                    visibility: hasError ? 'hidden' : 'visible',
                }}
                onLoad={handleLoad}
                onError={handleError}
                onClick={onClick}
                draggable={false}
            />
        </>
    );
};

export default LazyLoadImage;
