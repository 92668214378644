import React, { useMemo } from 'react';
import {
    MobileCardContainer,
    MobileCardHeader,
    MobileCardTitle,
    MobileCardSubtitle,
    MobileCircularProgressWrapper,
    MobileCircularContentWrapper,
    MobileCircularIconWrapper,
    MobileCircularAmountWrapper,
} from './mobile-rental-payments-card.styles';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';

interface MobileRentalPaymentsCardProps {
    className?: string | undefined;
    totalPayments: number;
    receivedPayments: number;
}

const MobileRentalPaymentsCard: React.FC<MobileRentalPaymentsCardProps> = ({ className, totalPayments, receivedPayments }) => {
    const navigate = useCustomNavigate();

    // Memos
    const percentage = useMemo(() => {
        return (receivedPayments / totalPayments) * 100;
    }, [totalPayments, receivedPayments]);

    return (
        <MobileCardContainer className={className} onClick={() => navigate("/financials", { replace: true, clearHistory: true })}>
            <MobileCardHeader>
                <div className="d-flex align-items-center">
                    <MobileCardTitle>Zahlungen erhalten</MobileCardTitle>
                    <MaterialIcon icon="chevron_forward" size={18} color={colorPalette.blue500} />
                </div>
                <MobileCardSubtitle>2024</MobileCardSubtitle>
            </MobileCardHeader>

            <MobileCircularProgressWrapper>
                <svg width="0" height="0">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="53.8%" style={{ stopColor: '#16856C', stopOpacity: 1 }} />
                            <stop offset="68.28%" style={{ stopColor: '#0C473A', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                </svg>

                <CircularProgressbar
                    value={percentage}
                    circleRatio={0.7}
                    styles={buildStyles({
                        textColor: 'transparent',
                        pathColor: 'url(#gradient)',
                        trailColor: 'rgba(255,255,255,0.7)',
                        rotation: 0.65,
                    })}
                    strokeWidth={4}
                />
                <MobileCircularContentWrapper>
                    <MobileCircularIconWrapper>
                        <MaterialIcon icon="checkbook" size={28} color={colorPalette.successMedium} />
                    </MobileCircularIconWrapper>
                    <MobileCircularAmountWrapper>
                        {receivedPayments}/{totalPayments}
                    </MobileCircularAmountWrapper>
                </MobileCircularContentWrapper>
            </MobileCircularProgressWrapper>
        </MobileCardContainer>
    );
};

export default MobileRentalPaymentsCard;
