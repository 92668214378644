import styled, { keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Animation for the modal coming up from the bottom
const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  @media (max-width: 767.98px) {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const ModalContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  max-width: 500px;
  width: auto;
  z-index: 10000;

  /* Desktop animation */
  animation: fadeIn 0.3s ease-out forwards;

  /* Apply min-width only on larger screens */
  @media (min-width: 768px) {
    max-width: 600px;
  }

  /* Mobile-specific animation */
  @media (max-width: 767.98px) {
    width: 100%;
    border-radius: 16px 16px 0 0;
    min-height: 50vh;
    max-height: 90vh;
    animation: ${slideUp} 0.3s ease-out forwards;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};

  @media (max-width: 767.98px) {
    padding: 16px;
  }
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingSM}

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

export const HeaderSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain}
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const PaymentList = styled.div`
  overflow-y: auto;

  /* Apply min-width only on larger screens */
  @media (min-width: 768px) {
    max-height: 600px;
  }
`;

export const PaymentItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};
`;

export const PaymentIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${colorPalette.blue800};
`;

export const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentTitle = styled.p`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingXS}

  /* Apply min-width only on larger screens */
    @media (min-width: 768px) {
    ${typeScale.headingXXSMain}
  }
`;

export const PaymentDetail = styled.p`
  color: ${colorPalette.textDark};
  margin: 0;
  ${typeScale.bodyXS}
`;

export const PaymentAmount = styled.p`
  margin: 0;
  text-align: right;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingXS}

  /* Apply min-width only on larger screens */
    @media (min-width: 768px) {
    ${typeScale.headingXXSMain}
  }
`;

export const PaymentPercentage = styled.p`
  color: ${colorPalette.textDark};
  margin: 0;
  ${typeScale.bodyXS}
  text-align: right;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${colorPalette.blue100};
  padding: 16px;
  gap: 8px;
`;

export const FooterText = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}
`;
