import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Container for the filter button
export const FilterContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? colorPalette.blue800 : colorPalette.blue200};
  color: ${({ isSelected }) =>
    isSelected ? colorPalette.white : colorPalette.textMain};
  transition: background-color 0.3s ease, color 0.3s ease;
  ${({ isSelected }) => (isSelected ? typeScale.bodyMDMain : typeScale.bodyMD)};

  svg {
    margin-left: 8px;
    color: ${({ isSelected }) =>
      isSelected ? colorPalette.white : colorPalette.blue800};
  }

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? colorPalette.blue800 : colorPalette.blue100};
    color: ${({ isSelected }) =>
      isSelected ? colorPalette.white : colorPalette.blue800};
  }
`;

// Label for the filter button
export const FilterLabel = styled.span`
  font-size: inherit;
  font-weight: inherit;
`;

// Container for the dropdown items
export const DropdownContainer = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background-color: ${colorPalette.white};
  box-shadow: 0px 0px 10px 0px #00000040;
  border-radius: 4px;
  z-index: 10;
  min-width: 100%;
  max-width: 300px;
  max-height: 200px;
  overflow-y: auto;
`;

// Style for individual dropdown items
export const DropdownItem = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background-color: ${colorPalette.white};
  transition: background-color 0.2s ease;
  white-space: nowrap;

  &:hover {
    background-color: ${colorPalette.blue100};
  }
`;
