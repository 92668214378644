import React, { useCallback } from 'react';
import {
    CardContainer,
    ImageContainer,
    ProjectImage,
    InfoContainer,
    ProjectTitle,
    ProjectLocation,
    ViewDetailsButton,
    HeaderContainer,
    Title,
    Container,
} from './mobile-property-card.styles';
import { IProperty } from '../../../../services/propertyService';
import MaterialIcon from '../../../../components/material-icon';
import CTAButton from '../../../../components/cta-button/cta-button';
import { colorPalette } from '../../../../utils/theme';

interface MobilePropertyCardProps {
    property: IProperty;
}

const MobilePropertyCard: React.FC<MobilePropertyCardProps> = ({ property }) => {
    const handleViewDetailsClick = useCallback(() => {
        const url = `/project/${property.property_id}`;
        window.open(url, '_blank');
    }, [property]);

    return (
        <Container>
            <HeaderContainer>
                <Title>Das Projekt</Title>
            </HeaderContainer>
            <CardContainer>
                <ImageContainer>
                    <ProjectImage src={property.property_image} alt={property.property_name} />
                </ImageContainer>
                <InfoContainer>
                    <ProjectTitle>{property.property_name}</ProjectTitle>
                    <ProjectLocation>
                        <MaterialIcon icon="distance" size={16} color={colorPalette.blue300} />
                        {property.property_location}
                    </ProjectLocation>
                    <CTAButton
                        variant="ghost"
                        size="S"
                        label="Projektdetails ansehen"
                        icon="arrow_outward"
                        color={colorPalette.blue50}
                        onClick={handleViewDetailsClick}
                        noPadding
                    />
                </InfoContainer>
            </CardContainer>
        </Container>
    );
};

export default MobilePropertyCard;
