import React, { useMemo } from 'react';
import {
    CardContainer,
    CardHeader,
    CardTitle,
    CardSubtitle,
    CircularProgressWrapper,
    CircularContentWrapper,
    CircularIconWrapper,
    CircularAmountWrapper,
    CardFooter,
} from './units-rented-statistic-card.styles';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';

interface UnitsRentedStatisticCardProps {
    className?: string | undefined;
    totalUnits: number;
    unitsRented: number;
}

const UnitsRentedStatisticCard: React.FC<UnitsRentedStatisticCardProps> = ({ className, totalUnits, unitsRented }) => {
    const navigate = useCustomNavigate();

    // Memo
    const percentage = useMemo(() => {
        return Math.round((unitsRented / totalUnits) * 100);
    }, [unitsRented, totalUnits]);

    const color = useMemo(() => {
        return percentage < 100 ? colorPalette.primaryDark : colorPalette.successMedium
    }, [percentage]);

    return (
        <CardContainer className={className} onClick={() => navigate("/my-units", { replace: true, clearHistory: true })}>
            <CardHeader>
                <CardTitle>Einheiten vermietet</CardTitle>
                <CardSubtitle>Stand heute</CardSubtitle>
            </CardHeader>

            <CircularProgressWrapper>
                <CircularProgressbar
                    value={percentage}
                    circleRatio={0.7}
                    styles={buildStyles({
                        textColor: 'transparent',
                        pathColor: color,
                        trailColor: colorPalette.white,
                        rotation: 0.65,
                    })}
                    strokeWidth={5}
                />
                <CircularContentWrapper>
                    <CircularIconWrapper>
                        <MaterialIcon icon="location_away" size={40} color={color} />
                    </CircularIconWrapper>
                    <CircularAmountWrapper color={color}>
                        {percentage}%
                    </CircularAmountWrapper>
                </CircularContentWrapper>
            </CircularProgressWrapper>

            <CardFooter>
                {percentage < 100 ? "Wir suchen bereits nach einem Mieter für deine unvermieteten Einheiten." : "Alle deine Einheiten sind vermietet."}
            </CardFooter>
        </CardContainer>
    );
};

export default UnitsRentedStatisticCard;
