import styled, { keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Animation for sliding up from the bottom on mobile
const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Animation for sliding in from the right on desktop
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animation for sliding out to the right on desktop
const slideOutToRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

// Backdrop for Sidebar Modal
export const SidebarModalBackdrop = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "visible",
})<{ visible?: boolean }>`
  position: fixed;
  top: 0 !important;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1045;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media (max-width: 767px) {
    align-items: flex-end;
  }
`;

// Sidebar Modal container with mobile adaptation for bottom sheet and desktop animation
export const SidebarModal = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "visible" && prop !== "isPWA",
})<{ visible?: boolean; isPWA?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background: #fff;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  width: 30%;
  animation: ${({ visible }) => (visible ? slideInFromRight : slideOutToRight)} 0.3s ease-out;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

  @media (max-width: 767px) {
    top: unset;
    width: 100%;
    height: fit-content;
    min-height: 50vh;
    max-height: 90vh;
    border-radius: 16px 16px 0 0;
    animation: ${slideUp} 0.3s ease-out forwards;
  }
`;

// Sidebar Modal Header
export const SidebarModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};

  @media (max-width: 767.98px) {
    padding: 24px 16px;
  }
`;

// Headline container in the modal
export const HeadlineContainer = styled.div`
  display: block;
  gap: 8px;
`;

// Modal headline
export const SidebarModalHeadline = styled.h1`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

// Modal subtitle
export const SidebarModalSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}
`;

// Divider in the modal
export const SidebarModalDivider = styled.div`
  height: 1px;
  background-color: #f4f7fe;
`;

// Scrollable content with custom padding
export const SidebarModalScrollableContent = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "withDefaultPadding",
})<{ withDefaultPadding?: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: ${({ withDefaultPadding }) =>
    withDefaultPadding !== false ? "20px" : "0"};
  padding-bottom: 60px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 767px) {
    max-height: calc(100vh - 100px);
  }
`;

// Footer for the modal
export const SidebarModalFooter = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${colorPalette.blue200};
  padding: 16px;

  @media (max-width: 767px) {
    padding: 16px 24px;
  }
`;

// Bottom button container (sticky at the bottom)
export const StoreInvestorButton = styled.div`
  padding: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-sizing: border-box;
  border-top: 1px solid #ddd;
`;

// Media Query for Mobile Styles
export const MobileStyles = styled.div`
  @media (max-width: 767px) {
    ${SidebarModal} {
      width: 100%;
      border-radius: 0;
    }

    ${SidebarModalScrollableContent} {
      max-height: calc(100vh - 100px);
    }

    ${StoreInvestorButton} {
      padding: 10px;
    }
  }
`;

// Media Query for Desktop Styles
export const DesktopStyles = styled.div`
  @media (min-width: 768px) {
    ${SidebarModal} {
      width: 35%;
    }
  }
`;
