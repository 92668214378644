import { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";
import useInvestorDetailStore from "../../../../stores/investorDetailStore";
import {
    StyledContainer,
    HeaderContainer,
    Title,
    ContentSpacing,
    SectionItem,
    SectionListContainer,
    SectionText,
    CollapsibleSection,
    LogoutSection,
    VersionTitle,
    HeaderLeftContent,
    FormSection,
} from "./settings-page.styles";
import CTAButton from "../../../../components/cta-button/cta-button";
import CustomInputField from "../../../../components/custom-input-field/custom-input-field";
import { updateInvestorPassword } from "../../../../services/investorService";
import useAlertStore from "../../../../stores/alertStore";
import packageInfo from '../../../../../package.json';
import { logout } from "../../../../services/authService";
import useConstructionUpdateStore from "../../../../stores/constructionUpdateStore";
import useDashboardStore from "../../../../stores/dashboardStore";
import useUnitDetailStore from "../../../../stores/unitDetailStore";
import useUnitStore from "../../../../stores/unitStore";
import useCustomNavigate from "../../../../hooks/useCustomNavigate";
import useIsMobile from "../../../../hooks/useIsMobile";

const ProfileSettingsPage = () => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const { investor, reset: resetInvestoreDetailStore, } = useInvestorDetailStore();
    const resetUnitStore = useUnitStore(state => state.reset);
    const resetUnitDetailStore = useUnitDetailStore(state => state.reset);
    const resetConstructionUpdateStore = useConstructionUpdateStore(state => state.reset);
    const resetDashboardStore = useDashboardStore(state => state.reset);

    // States
    const [isLoading, setIsLoading] = useState(false);

    // Password States
    const [showPasswordSection, setShowPasswordSection] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    // Error States
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");

    // Functions
    const togglePasswordSection = () => {
        setShowPasswordSection(!showPasswordSection);
    };

    const handleUpdatePassword = useCallback(async () => {
        if (!investor) return;

        let isValid = true;

        // Check if the current password is not empty
        if (!currentPassword.trim()) {
            setCurrentPasswordError("Altes Passwort muss angegeben sein");
            isValid = false;
        }

        // Check if the new password is not empty
        if (!newPassword.trim()) {
            setNewPasswordError("Neues Passwort muss angegeben sein");
            isValid = false;
        }
        // Check if the new passwords are matching each other
        if (!confirmNewPassword.trim() || newPassword !== confirmNewPassword) {
            setConfirmNewPasswordError("Das Passwort stimmt nicht überein");
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);

                await updateInvestorPassword(investor.investor_id, currentPassword, newPassword);

                // Clear password fields
                setCurrentPassword("");
                setNewPassword("");
                setConfirmNewPassword("");

                // Hide password section
                setShowPasswordSection(false);

                showAlert("success", "Passwort erfolgreich geändert!");
            } catch (error) {
                console.log("error while updating investor password:", error);

                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [investor, currentPassword, newPassword, confirmNewPassword]);

    // Functions
    const onClickLogout = async () => {
        await logout();

        resetUnitStore();
        resetConstructionUpdateStore();
        resetInvestoreDetailStore();
        resetUnitDetailStore();
        resetDashboardStore();

        navigate("/login", { replace: true, clearHistory: true });
    };

    return (
        <StyledContainer>
            {investor && (
                <Row>
                    <Col xs={12} lg={8}>
                        <HeaderContainer>
                            <HeaderLeftContent>
                                {!isMobile && (
                                    <MaterialIcon icon="arrow_back" size={24} color={colorPalette.textMain} onClick={() => navigate(-1)} />
                                )}
                                <Title>Einstellungen</Title>
                            </HeaderLeftContent>
                        </HeaderContainer>

                        <FormSection>
                            <SectionListContainer>
                                {/* Change Password Section */}
                                <SectionItem onClick={togglePasswordSection}>
                                    <ContentSpacing>
                                        <MaterialIcon icon="encrypted" size={24} color={colorPalette.textMain} />
                                        <SectionText>Passwort ändern</SectionText>
                                    </ContentSpacing>
                                    <ContentSpacing>
                                        <MaterialIcon icon={showPasswordSection ? "expand_circle_down" : "chevron_forward"} size={24} color={colorPalette.blue500} />
                                    </ContentSpacing>
                                </SectionItem>

                                {showPasswordSection && (
                                    <CollapsibleSection>
                                        <Col className="mb-3" xs={12}>
                                            <CustomInputField
                                                id="current-password"
                                                type="password"
                                                value={currentPassword}
                                                placeholder="Aktuelles Passwort"
                                                subtitle="Aktuelles Passwort eingeben"
                                                errorMessage={currentPasswordError}
                                                onChange={(value) => {
                                                    setCurrentPassword(value);
                                                    if (value.trim().length > 0) {
                                                        setCurrentPasswordError("");
                                                    } else {
                                                        setCurrentPasswordError("Altes Passwort muss angegeben sein");
                                                    }
                                                }}
                                                onClear={() => {
                                                    setCurrentPassword("");
                                                    setCurrentPasswordError("");
                                                }}
                                                required
                                            />
                                        </Col>
                                        <Col className="mb-3" xs={12} xl={6}>
                                            <CustomInputField
                                                id="new-password"
                                                type="password"
                                                value={newPassword}
                                                placeholder="Neues Passwort"
                                                subtitle="Neues Passwort eingeben"
                                                errorMessage={newPasswordError}
                                                onChange={(value) => {
                                                    setNewPassword(value);
                                                    if (value.trim().length > 0) {
                                                        setNewPasswordError("");
                                                    } else {
                                                        setNewPasswordError("Neues Passwort muss angegeben sein");
                                                    }
                                                }}
                                                onClear={() => {
                                                    setNewPassword("");
                                                    setNewPasswordError("");
                                                }}
                                                required
                                            />
                                        </Col>
                                        <Col className="mb-3" xs={12} xl={6}>
                                            <CustomInputField
                                                id="confirm-new-password"
                                                type="password"
                                                value={confirmNewPassword}
                                                placeholder="Passwort Wiederholen"
                                                subtitle="Neues Passwort bestätigen"
                                                errorMessage={confirmNewPasswordError}
                                                onChange={(value) => {
                                                    setConfirmNewPassword(value);
                                                    if (value.trim().length > 0) {
                                                        setConfirmNewPasswordError("");
                                                    } else {
                                                        setConfirmNewPasswordError("Neues Passwort muss angegeben sein");
                                                    }
                                                }}
                                                onClear={() => {
                                                    setConfirmNewPassword("");
                                                    setConfirmNewPasswordError("");
                                                }}
                                                required
                                            />
                                        </Col>
                                        <Col className="text-end" xs={12}>
                                            <CTAButton
                                                variant="primary"
                                                size="S"
                                                label="Passwort aktualisieren"
                                                loading={isLoading}
                                                disabled={!currentPassword.trim() || !newPassword.trim() || !confirmNewPassword.trim() || isLoading}
                                                onClick={handleUpdatePassword}
                                            />
                                        </Col>
                                    </CollapsibleSection>
                                )}

                                {/* Notifications Section */}
                                {/*<SectionItem onClick={() => { }}>
                                    <ContentSpacing>
                                        <MaterialIcon icon="notifications_active" size={24} color={colorPalette.textMain} />
                                        <SectionText>Benachrichtigungen</SectionText>
                                    </ContentSpacing>
                                    <ContentSpacing>
                                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                                    </ContentSpacing>
                                </SectionItem>*/}
                            </SectionListContainer>

                            <LogoutSection>
                                <CTAButton
                                    color={colorPalette.textMain}
                                    variant="ghost"
                                    size="L"
                                    label="Abmelden"
                                    icon="logout"
                                    onClick={onClickLogout}
                                    noPadding
                                />
                                <VersionTitle>Version {packageInfo.version}</VersionTitle>
                            </LogoutSection>
                        </FormSection>
                    </Col>
                </Row>
            )}
        </StyledContainer>
    );
};

export default ProfileSettingsPage;
