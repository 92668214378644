// document-upload-modal.styles.ts
import styled, { keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Animation for the modal coming up from the bottom
const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  @media (max-width: 767.98px) {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Container = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  z-index: 10000;

  /* Desktop animation */
  animation: fadeIn 0.3s ease-out forwards;

  /* Mobile-specific animation */
  @media (max-width: 767.98px) {
    width: 100%; 
    border-radius: 16px 16px 0 0;
    max-height: 90vh;
    animation: ${slideUp} 0.3s ease-out forwards;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};

  @media (max-width: 767.98px) {
    padding: 24px 16px;
  }
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingSM}

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

export const ContentContainer = styled.div`
  padding: 24px 16px;

  @media (max-width: 767.98px) {
    padding: 16px;
  }
`;

export const CategoryTitle = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}
`;

export const ActionsContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isPWA",
})<{ isPWA: boolean }>`
  display: flex;
  padding: 16px;
  gap: 16px;
  justify-content: center;
  border-top: 1px solid ${colorPalette.blue100};

  @media (max-width: 767.98px) {
    flex-direction: column;
    padding: 16px;

    ${({ isPWA }) =>
      isPWA &&
      `
      padding-bottom: 24px;
    `}
  }
`;

export const FileCard = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: ${colorPalette.blue50};
  border-radius: 8px;
  gap: 12px;
`;

export const FileName = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain};
  flex: 1;
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: ${colorPalette.blue50};
  border-radius: 8px;
  cursor: pointer;

  label {
    color: ${colorPalette.blue500};
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    ${typeScale.bodySMMain};
  }

  &:hover {
    background-color: ${colorPalette.blue100};
  }
`;
