import styled from "styled-components";
import { colorPalette, typeScale } from "../../../utils/theme";

export const ContentHeader = styled.div`
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-radius: 12px;
  padding: 16px;
  margin: 12px;
  border: 1px solid ${colorPalette.blue200};
`;

export const ContentHeaderTitle = styled.h4`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
`;

export const ContentHeaderSubtitle = styled.p.withConfig({
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>`
  margin-bottom: 12px;
  color: ${({ color }) => color};
  ${typeScale.bodyXSMain};
`;

export const ContractDetails = styled.div`
    border-radius: 8px;
    border: 1px solid ${colorPalette.blue100};
    margin: 12px;
`;

export const ContractDetailItem = styled.div`
    display: flex;
    padding: 24px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-bottom: 1px solid ${colorPalette.blue200};

    &:last-child {
        border-bottom: none;
    }
`;

export const ContractDetailLabel = styled.div`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
`;

export const ContractDetailValue = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};
`;

export const CategoryHeadline = styled.h4`
  margin: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
`;

export const SpinnerWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin: 16px;
`;