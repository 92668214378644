// ProfileAddresDetailsPage.tsx
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";
import useInvestorDetailStore from "../../../../stores/investorDetailStore";
import { StyledContainer, HeaderContainer, Title, HeaderLeftContent, CardContainer, ItemContainer, Label, Value, CardTitle, Info, FormSection, Footer } from "./address-details-page.styles";
import CTAButton from "../../../../components/cta-button/cta-button";
import { useNavigate } from "react-router-dom";
import { usePWA } from "../../../../context/PWAContext";
import useIsMobile from "../../../../hooks/useIsMobile";

const ProfileAddresDetailsPage = () => {
    const navigate = useNavigate();
    const isPWA = usePWA();
    const isMobile = useIsMobile();

    // Stores
    const investor = useInvestorDetailStore(state => state.investor);

    return (
        <StyledContainer>
            {investor && (
                <>
                    <Row>
                        <Col xs={12} lg={10} xl={8}>
                            <HeaderContainer>
                                <HeaderLeftContent>
                                    {!isMobile && (
                                        <MaterialIcon icon="arrow_back" size={24} color={colorPalette.textMain} onClick={() => navigate(-1)} />
                                    )}
                                    <Title>Addresse & Kontakt</Title>
                                </HeaderLeftContent>
                                <CTAButton
                                    className="d-none d-md-block"
                                    variant="primary"
                                    size="S"
                                    label="Daten bearbeiten"
                                    onClick={() => navigate("/profile/edit-address")}
                                />
                            </HeaderContainer>

                            <FormSection className="gy-3">
                                <Col xs={12}>
                                    <CardTitle>Name</CardTitle>
                                    <CardContainer>
                                        <ItemContainer>
                                            <Label>Vollständiger Name</Label>
                                            <Value>{investor?.first_name} {investor?.last_name}</Value>
                                        </ItemContainer>
                                        <ItemContainer>
                                            <Label>Geburtsdatum</Label>
                                            <Value>{investor?.birth_date}</Value>
                                        </ItemContainer>
                                    </CardContainer>
                                    <Info>Wenn du deinen Namen ändern möchtest, kontaktiere uns.</Info>
                                </Col>

                                <Col xs={12}>
                                    <CardTitle>Kontakt</CardTitle>
                                    <CardContainer>
                                        <ItemContainer>
                                            <Label>E-Mail</Label>
                                            <Value>{investor?.email}</Value>
                                        </ItemContainer>
                                        <ItemContainer>
                                            <Label>Telefon</Label>
                                            <Value>{investor?.phone_number}</Value>
                                        </ItemContainer>
                                    </CardContainer>
                                </Col>

                                <Col xs={12}>
                                    <CardTitle>Anschrift</CardTitle>
                                    <CardContainer>
                                        <ItemContainer>
                                            <Label>Adresse</Label>
                                            <Value>{investor?.address.street_address1} {investor?.address.street_address2 ? `, ${investor.address.street_address2}` : ""}</Value>
                                        </ItemContainer>
                                        <ItemContainer>
                                            <Label>Stadt</Label>
                                            <Value>
                                                {investor.address.postal_code ? `${investor.address.postal_code}, ` : ""}
                                                {investor.address.city}
                                            </Value>
                                        </ItemContainer>
                                        {investor?.address.state_province_region && (
                                            <ItemContainer>
                                                <Label>Provinz</Label>
                                                <Value>{investor?.address.state_province_region} {investor?.address.country ? `` : ""}</Value>
                                            </ItemContainer>
                                        )}
                                        <ItemContainer>
                                            <Label>Land</Label>
                                            <Value>{investor?.address.country ?? "-"}</Value>
                                        </ItemContainer>
                                    </CardContainer>
                                </Col>

                                {isPWA && (
                                    <Col xs={12}>
                                        <CTAButton
                                            variant="primary"
                                            size="L"
                                            label="Daten bearbeiten"
                                            onClick={() => navigate("/profile/edit-bank-details")}
                                            fullWidth
                                        />
                                    </Col>
                                )}
                            </FormSection>
                        </Col>
                    </Row>

                    {!isPWA && (
                        <Footer isPWA={isPWA}>
                            <CTAButton
                                variant="primary"
                                size="L"
                                label="Daten bearbeiten"
                                onClick={() => navigate("/profile/edit-address")}
                                fullWidth
                            />
                        </Footer>
                    )}
                </>
            )}
        </StyledContainer>
    );
};

export default ProfileAddresDetailsPage;
