import React from 'react';
import {
    HeaderContainer,
    Title,
    Container
} from './more-details.styles';
import DetailItem from '../../../../components/detail-item/detail-item';
import { Col, Row } from 'react-bootstrap';

interface MoreDetailsProps {
    propertyMakaniNumber: string;
    gasNumber?: string | null;
    dewaPremiseNumber?: string | null;
}

const MoreDetails: React.FC<MoreDetailsProps> = ({ propertyMakaniNumber, gasNumber, dewaPremiseNumber }) => {
    return (
        <Container>
            <HeaderContainer>
                <Title>Weitere Details</Title>
            </HeaderContainer>

            <Row>
                <Col xs={12} md={6}>
                    <DetailItem icon="home_pin" label="Makani Nummer" value={propertyMakaniNumber.length > 0 ? propertyMakaniNumber : "-"} />
                </Col>
                <Col xs={12} md={6}>
                    <DetailItem icon="local_fire_department" label="Gas-Anschluss Nummer" value={gasNumber && gasNumber.length > 0 ? gasNumber : "-"} />
                </Col>
                <Col xs={12} md={6}>
                    <DetailItem icon="water_ec" label="Dewa Nummer" value={dewaPremiseNumber && dewaPremiseNumber.length > 0 ? dewaPremiseNumber : "-"} />
                </Col>
            </Row>
        </Container>
    );
};

export default MoreDetails;
