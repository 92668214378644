import styled from "styled-components";
import LoginBackground from "../../assets/images/login-bg.png";
import { colorPalette, typeScale } from "../../utils/theme";

// Left Panel
export const LeftPanel = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  background-image: url(${LoginBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 30px;
    font-weight: bold;
  }
`;

// Right Panel
export const RightPanel = styled.div`
  background: url("../../assets/images/dots-background.svg") no-repeat right top
    white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 768px) {
    background: white;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;

  @media (max-width: 767.98px) {
    top: 48px;
  }
`;

export const Title = styled.h1`
  margin-top: 54px;
  margin-left: 36px;
  text-align: left;
  color: ${colorPalette.blue50};
  ${typeScale.headingXLMain}

  span {
    display: block;
    color: ${colorPalette.blue300};
  }
`;

export const RightTitle = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const RightSubitle = styled.p`
  margin-bottom: 78px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyLG}

  @media (max-width: 767.98px) {
    ${typeScale.bodyMD}
  }
`;

// Image placeholders
export const ImagePlaceholder = styled.div`
  width: 100%;
  height: 200px;
  background-color: #ddd;
  margin-bottom: 20px;
`;

// Logo Images
export const HavensGardenImage = styled.img`
  width: 400px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 125px;
  display: block;

  @media (max-width: 991.98px) {
    width: 300px;
  }
`;

export const TheVybeImage = styled.img`
  width: 150px;
  position: absolute;
  bottom: -100px;
  left: 0;
  border-bottom-left-radius: 50px;
  z-index: 2;

  @media (max-width: 991.98px) {
    width: 100px;
    bottom: -70px;
  }
`;

// Footer
export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background-color: white;
`;

export const FooterText = styled.p`
  color: ${colorPalette.textDark};
  text-align: center;
  ${typeScale.bodyXS}
`;

// Login Form
export const LoginForm = styled.form`
  width: 50%;

  @media (max-width: 768px) {
    width: 80%;
  }
`;
