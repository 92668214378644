import styled from "styled-components";
import { typeScale, colorPalette } from "../../utils/theme";

// Container for the tag
export const TagContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "backgroundColor" && prop !== "color" && prop !== "size",
})<{ backgroundColor: string; color: string; size: string }>`
  height: ${({ size }) => (size === "L" ? "36px" : "24px")};
  padding: 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  ${({ size }) => (size === "L" ? typeScale.bodyMDMain : typeScale.bodySMMain)};
`;

// Optional logo on the left side
export const TagLogo = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 8px;
`;

// Tooltip icon styling
export const TagTooltipIcon = styled.span`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.successMedium};

  &:hover {
    color: #ededed;
  }
`;
