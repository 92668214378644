import styled from "styled-components";
import { typeScale, colorPalette } from "../../../../utils/theme";

export const ChartContainer = styled.div`
  width: 100%;
  height: inherit;
  background: #ffffffa6;
  backdrop-filter: blur(100px);
  border-radius: 12px;
  padding: 24px 0 24px 24px; /* Remove right padding */
  border: 1px solid ${colorPalette.blue200};
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 767.98px) {
    margin-top: 16px;
    padding: 12px 0 12px 12px; /* Remove right padding */
    background: #ffffffb2;
    box-shadow: 0px 0px 20px 0px #0000001a;
    backdrop-filter: blur(20px);
  }
`;

export const ChartContent = styled.div`
  width: 100%;
  height: 230px;
  margin-top: 16px;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: 767.98px) {
    height: 150px;
  }
`;

export const ChartHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PortfolioVolume = styled.h2`
  margin: 0;
  text-align: start;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const PortfolioSubtitle = styled.p`
  margin: 0;
  text-align: start;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};
`;

export const ROIContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;
`;

export const ROISubLabel = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const ROILabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};
`;
