import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-left: 12px;
  padding-right: 12px;

  @media (max-width: 767.98px) {
    margin-top: 24px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  border: 1px solid ${colorPalette.blue100};
  border-radius: 8px;
  background-color: ${colorPalette.blue50};
`;

export const Headline = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 16px;
  img {
    width: 80px;
    height: auto;
  }

  @media (max-width: 767.98px) {
    img {
      width: 60px;
      height: auto;
    }
  }
`;

export const MessageWrapper = styled.div`
  text-align: center;
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.headingXSMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingXXSMain};
  }
`;

export const Subtitle = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;
