import React from 'react';
import {
    ContactListContainer,
    ContactCard,
    ProfileImage,
    ContactDetails,
    RoleText,
    NameText,
    ActionIcons,
    IconButton,
    HeaderTitle,
    Container
} from './contacts-list.styles';
import DefaultAvatar from '../../../../assets/images/default-avatar.webp';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';

const ContactsList: React.FC = () => {
    return (
        <Container>
            <HeaderTitle>Kontakt</HeaderTitle>
            <ContactListContainer>
                <ContactCard>
                    <ProfileImage src={DefaultAvatar} alt="Profile" />
                    <ContactDetails>
                        <RoleText>Sales Agent</RoleText>
                        <NameText>Daniel Garofoli</NameText>
                    </ContactDetails>
                    <ActionIcons>
                        <IconButton className="d-none d-md-flex" background="light">
                            <MaterialIcon icon="call" size={24} color={colorPalette.textMain} />
                        </IconButton>
                        <IconButton background="dark">
                            <MaterialIcon icon="maps_ugc" size={24} color={colorPalette.white} />
                        </IconButton>
                    </ActionIcons>
                </ContactCard>

                <ContactCard>
                    <ProfileImage src={DefaultAvatar} alt="Profile" />
                    <ContactDetails>
                        <RoleText>Property Management</RoleText>
                        <NameText>Daniel Garofoli</NameText>
                    </ContactDetails>
                    <ActionIcons>
                        <IconButton className="d-none d-md-flex" background="light">
                            <MaterialIcon icon="call" size={24} color={colorPalette.textMain} />
                        </IconButton>
                        <IconButton background="dark">
                            <MaterialIcon icon="maps_ugc" size={24} color={colorPalette.white} />
                        </IconButton>
                    </ActionIcons>
                </ContactCard>
            </ContactListContainer>
        </Container>
    );
};

export default ContactsList;
