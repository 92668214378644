import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 48px;
  padding: 0 16px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const HeaderContainer = styled.div`
  margin-top: 48px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    margin-bottom: 0;
    ${typeScale.headingSMMain};
  }
`;
