import React, { useState } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import { FilterContainer, FilterLabel, DropdownContainer, DropdownItem } from './filter.styles';
import Checkbox from '../checkbox/checkbox';

interface DropdownItem {
    label: string;
    value: string;
    checked?: boolean;
}

interface FilterProps {
    label: string;
    hasDropdown?: boolean;
    items?: DropdownItem[];
    multiple?: boolean;
    isSelected?: boolean;
    disabled?: boolean;
    onSelect?: (value: string) => void;
}

const Filter: React.FC<FilterProps> = ({
    label,
    hasDropdown = false,
    items = [],
    multiple = false,
    isSelected = false,
    disabled = false,
    onSelect
}) => {
    const [selected, setSelected] = useState(isSelected);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

    const handleSelect = () => {
        if (disabled) return;
        setSelected(!selected);
        setIsOpen(!isOpen);
    };

    const handleItemClick = (value: string) => {
        const newSelectedItems = new Set(selectedItems);

        if (newSelectedItems.has(value)) {
            newSelectedItems.delete(value);
        } else {
            if (!multiple) newSelectedItems.clear();
            newSelectedItems.add(value);
        }

        setSelectedItems(newSelectedItems);
        if (onSelect) onSelect(value);
    };

    // Get the label for display based on the selected items
    const displayLabel = multiple
        ? label
        : selectedItems.size > 0
            ? `${label}: ${Array.from(selectedItems)
                .map((value) => items.find((item) => item.value === value)?.label)
                .join(', ')}`
            : label;

    return (
        <div style={{ position: 'relative' }}>
            <FilterContainer isSelected={selected} onClick={handleSelect} style={{ cursor: disabled ? 'auto' : 'pointer' }}>
                <FilterLabel>{displayLabel}</FilterLabel>
                {hasDropdown && <IoChevronDownOutline />}
            </FilterContainer>
            {hasDropdown && isOpen && (
                <DropdownContainer>
                    {items.map((item) => (
                        <DropdownItem key={item.value}>
                            <Checkbox
                                label={item.label}
                                checked={selectedItems.has(item.value)}
                                onChange={() => handleItemClick(item.value)}
                            />
                        </DropdownItem>
                    ))}
                </DropdownContainer>
            )}
        </div>
    );
};

export default Filter;
