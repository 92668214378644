import { MaterialSymbol } from "material-symbols";

export interface IMenuItem {
  id: number;
  name: string;
  icon: MaterialSymbol;
  path: string;
  isVisible: boolean;
  isActivated: boolean;
}

const MENU_ITEMS: IMenuItem[] = [
  {
    id: 1,
    name: "Dashboard",
    icon: "grid_view",
    path: "/",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 2,
    name: "Meine Einheiten",
    icon: "home_work",
    path: "/my-units",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 3,
    name: "Finanzen",
    icon: "table_chart_view",
    path: "/financials",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 4,
    name: "Verträge",
    icon: "description",
    path: "/contracts",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 5,
    name: "Knowledge Hub",
    icon: "school",
    path: "/knowledge-hub",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 6,
    name: "Account",
    icon: "account_circle",
    path: "/profile",
    isVisible: false,
    isActivated: true,
  },
];

export default MENU_ITEMS;
