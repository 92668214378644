// article-section.styles.ts
import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const ArticlesContainer = styled.div`
  .scroll-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  .updates-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding-left: 16px;
    padding-right: 16px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const ArticleItem = styled.div`
  width: 600px;
  margin-right: 16px;
  transition: transform 0.3s ease;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transform: scale(0.98);
    cursor: pointer;
  }

  @media (max-width: 767.98px) {
    min-width: 300px;
    max-width: 300px;
  }
`;

export const ArticleImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

export const ArticleTitle = styled.h3`
  margin: 16px 0 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain};

  @media (max-width: 767.98px) {
    margin: 16px 0 4px;
    ${typeScale.headingXSMain};
  }
`;

export const ArticleDescription = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;

export const ScrollButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  color: ${colorPalette.primaryDark};
  transition: background-color 0.2s ease;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.8);

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  &.scroll-left {
    left: 4px;
  }

  &.scroll-right {
    right: 4px;
  }
`;
