import React, { useEffect, useState } from 'react';
import {
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ImageCount,
    ImageTitle,
    NavigationButton,
    ArrowContainer,
    MobileCloseButton,
} from './image-modal.styles';
import { colorPalette } from '../../utils/theme';
import MaterialIcon from '../../components/material-icon';
import CTAButton from '../../components/cta-button/cta-button';
import useIsMobile from '../../hooks/useIsMobile';
import LazyLoadImage from '../../components/lazy-load-image/lazy-load-image';
import { useSwipeable } from 'react-swipeable';

interface ImageModalProps {
    images: string[];
    title: string;
    subtitle: string;
    isOpen: boolean;
    onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ images, title, subtitle, isOpen, onClose }) => {
    const isMobile = useIsMobile();

    // States
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Prevent scrolling down
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';

            const preventDefault = (e: TouchEvent) => {
                e.preventDefault();
            };

            document.addEventListener('touchmove', preventDefault, { passive: false });

            return () => {
                document.body.style.overflow = 'unset';
                document.removeEventListener('touchmove', preventDefault);
            };
        }
    }, [isOpen]);

    // Functions
    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrevious,
        delta: 30,
        trackMouse: true,
    });

    if (!isOpen) {
        return null;
    }

    return (
        <ModalOverlay>
            {isMobile && (
                <MobileCloseButton>
                    <MaterialIcon icon="close" size={24} color={colorPalette.white} onClick={onClose} />
                </MobileCloseButton>
            )}
            <ModalContent {...handlers}>
                <ModalHeader>
                    <ImageCount>{currentImageIndex + 1} / {images.length}</ImageCount>
                    {!isMobile && (
                        <CTAButton
                            variant="ghost"
                            size="S"
                            icon="close"
                            label="Schließen"
                            color={colorPalette.white}
                            onClick={onClose}
                        />
                    )}
                </ModalHeader>

                <ArrowContainer>
                    {images.length > 1 && (
                        <NavigationButton onClick={handlePrevious}>
                            <MaterialIcon size={32} icon="chevron_backward" color={colorPalette.white} />
                        </NavigationButton>
                    )}
                    <LazyLoadImage
                        margin="0 auto"
                        maxWidth={isMobile ? "100%" : "90%"}
                        maxHeight="calc(100vh - 300px)"
                        src={images[currentImageIndex]}
                        alt={`Image ${currentImageIndex + 1}`}
                    />
                    {images.length > 1 && (
                        <NavigationButton onClick={handleNext}>
                            <MaterialIcon size={32} icon="chevron_forward" color={colorPalette.white} />
                        </NavigationButton>
                    )}
                </ArrowContainer>

                <ModalFooter>
                    <ImageTitle>
                        {title}
                        <span>{subtitle}</span>
                    </ImageTitle>
                </ModalFooter>
            </ModalContent>
        </ModalOverlay>
    );
};

export default ImageModal;
