import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const MobileCardContainer = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  box-shadow: 0px 0px 20px 0px #0000001A;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid ${colorPalette.blue200};
  cursor: pointer;
`;

export const MobileCardHeader = styled.div`
  align-items: start;
`;

export const MobileCardTitle = styled.h3`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain};
  line-height: 1.1;
`;

export const MobileCardSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};
`;

export const MobileCircularProgressWrapper = styled.div`
  position: relative;
  width: 80%;
  height: 70%;
  margin: 8px auto 0;
  overflow: hidden;
`;

export const MobileCircularContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileCircularIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
`;

export const MobileCircularAmountWrapper = styled.div`
  margin: 0;
  color: ${colorPalette.successMedium};
  ${typeScale.bodyXLMain}
`;
