import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-left: 12px;
  padding-right: 12px;

  @media (max-width: 767.98px) {
    margin-top: 24px;
  }
`;

export const Headline = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Card = styled.div`
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  position: relative;
`;

export const CardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue200};

  &:last-child {
    border-bottom: none;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Date = styled.p`
  margin-bottom: 4px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}
`;

export const Unit = styled.p`
  margin-bottom: 4px;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain}
`;

export const Status = styled.p.withConfig({
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>`
  margin-bottom: 4px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXSMain}

  span {
    color: ${({ color }) => color};
  }
`;

export const Content = styled.div`
  gap: 4px;
`;
