// document-card.styles.ts
import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const DocumentCardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "enableHover",
})<{ enableHover: boolean }>`
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid ${colorPalette.blue200};

  &:first-of-type {
    border-top: none;
  }

  ${({ enableHover }) =>
    enableHover &&
    `
    &:hover {
      background-color: ${colorPalette.blue50};
      cursor: pointer;
    }
  `}
`;

export const DocumentIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const DocumentInfo = styled.div`
  flex: 1;
`;

export const DocumentTitle = styled.h5`
  margin-bottom: 4px;
  color: ${colorPalette.textMain};
  ${typeScale.bodyLGMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodySMMain};
  }
`;

export const DocumentSubtitle = styled.p.withConfig({
  shouldForwardProp: (prop) => prop !== "expired",
})<{ expired: boolean }>`
  margin: 0;
  color: ${({ expired }) =>
    expired ? colorPalette.errorDark : colorPalette.textDark};
  ${typeScale.bodyXS};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;

export const DocumentAction = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  p {
    margin: 0;
    color: ${colorPalette.textMain};
    ${typeScale.bodyXSMain};
  }
`;

export const SpinnerContainer = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid ${colorPalette.white};
  border-top: 3px solid ${colorPalette.blue800};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
