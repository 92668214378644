import React from 'react';
import { SPACES_ENDPOINT } from '../../../../../../utils/constants';
import { formatMoney } from '../../../../../../utils/helpers';
import {
    UnitCardContainer,
    ImageContainer,
    UnitContent,
    UnitHeader,
    TitleTagsContainer,
    UnitTitle,
    UnitSubtitle,
    UnitDetails,
    UnitStatistics,
    UnitStatistic,
    UnitNumber,
    UnitTagsContainer,
    UnitStatus,
    UnitStatusItem,
    UnitStatisticLabel,
    UnitStatusLabel,
    Container,
    SubtitleWithIcon,
} from './unit-card.styles';
import Tag from '../../../../../../components/tag/tag';
import { colorPalette } from '../../../../../../utils/theme';
import MaterialIcon from '../../../../../../components/material-icon';
import LazyLoadImage from '../../../../../../components/lazy-load-image/lazy-load-image';

interface UnitCardProps {
    imageSrc?: string;
    propertyName: string;
    title: string;
    bedroom: number;
    square_feet: number;
    purchasePrice: number;
    rent?: number;
    pmPackage?: "basic" | "premium";
    onClick: () => void;
}

const UnitCard: React.FC<UnitCardProps> = ({
    imageSrc,
    propertyName,
    title,
    bedroom,
    square_feet,
    purchasePrice,
    rent,
    pmPackage,
    onClick,
}) => {
    return (
        <Container>
            <UnitCardContainer onClick={onClick}>
                <ImageContainer>
                    <LazyLoadImage
                        src={`${SPACES_ENDPOINT}${imageSrc}`}
                        alt={title}
                        width="100%"
                        height={250}
                    />
                </ImageContainer>
                <UnitContent>
                    <UnitHeader>
                        <TitleTagsContainer>
                            <UnitTitle>{title} • {propertyName}</UnitTitle>
                            <UnitTagsContainer>
                                {rent && (
                                    <Tag label="Vermietet" type="unitStatus" variant="rented" size="S" />
                                )}
                            </UnitTagsContainer>
                        </TitleTagsContainer>
                        <SubtitleWithIcon>
                            <MaterialIcon icon="distance" size={12} color={colorPalette.textDark} />
                            <UnitSubtitle>Jumeirah Village Circle</UnitSubtitle>
                        </SubtitleWithIcon>
                    </UnitHeader>
                    <UnitDetails>
                        <UnitStatistics>
                            <UnitStatistic>
                                <MaterialIcon icon="bed" size={24} color={colorPalette.blue300} />
                                <UnitStatisticLabel>{bedroom} Bedroom</UnitStatisticLabel>
                            </UnitStatistic>
                            <UnitStatistic>
                                <MaterialIcon icon="straighten" size={24} color={colorPalette.blue300} />
                                <UnitStatisticLabel>{square_feet} Sqft</UnitStatisticLabel>
                            </UnitStatistic>
                            <UnitStatistic>
                                <MaterialIcon icon="shoppingmode" size={24} color={colorPalette.blue300} />
                                <UnitStatisticLabel>AED {formatMoney(purchasePrice, false)}</UnitStatisticLabel>
                            </UnitStatistic>
                        </UnitStatistics>
                        {rent ? (
                            <UnitStatus>
                                <UnitStatusItem>
                                    <UnitNumber>AED {formatMoney(rent, false)}</UnitNumber>
                                    <UnitStatusLabel>Jahresmiete</UnitStatusLabel>
                                </UnitStatusItem>
                                <UnitStatusItem>
                                    <UnitNumber className="text-end">{((rent / purchasePrice) * 100).toFixed(2)}%</UnitNumber>
                                    <UnitStatusLabel>Bruttorendite</UnitStatusLabel>
                                </UnitStatusItem>
                            </UnitStatus>
                        ) : (
                            <></>
                        )}
                    </UnitDetails>
                </UnitContent>
            </UnitCardContainer>
        </Container>
    );
};

export default UnitCard;
