import styled from "styled-components";
import { colorPalette } from "../../utils/theme";

export const Placeholder = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.blue200};
`;
