import React, { useState, useRef, useEffect } from 'react';
import { SixDigitCodeInputContainer, CodeInput, Divider } from './six-digit-code.styles';

interface SixDigitCodeInputProps {
    className?: string;
    onChange: (code: string) => void;
}

const SixDigitCodeInput: React.FC<SixDigitCodeInputProps> = ({ className, onChange }) => {
    const [code, setCode] = useState(Array(6).fill(''));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, 6);
    }, []);

    useEffect(() => {
        onChange(code.join(''));
    }, [code, onChange]);

    const handleChange = (digit: string, index: number) => {
        const newCode = [...code];
        newCode[index] = digit.substring(0, 1);
        setCode(newCode);

        // Move focus to the next input if not on the last input
        if (index < 5 && digit) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = e.clipboardData.getData('text').split('').filter((_, index) => index < 6);
        if (pastedData.length === 6) {
            setCode(pastedData);
            inputRefs.current[5]?.focus();
            e.preventDefault();
        }
    };

    return (
        <SixDigitCodeInputContainer className={className}>
            {code.map((digit, index) => (
                <React.Fragment key={index}>
                    <CodeInput
                        ref={(el: any) => (inputRefs.current[index] = el)}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e: any) => handleChange(e.target.value, index)}
                        onPaste={index === 0 ? handlePaste : undefined}
                    />
                    {index === 2 && <Divider>-</Divider>}
                </React.Fragment>
            ))}
        </SixDigitCodeInputContainer>
    );
};

export default SixDigitCodeInput;
