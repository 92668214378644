import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";
import { Row } from "react-bootstrap";

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;

  @media (max-width: 767.98px) {
    padding: 16px 16px;
    border-bottom: 1px solid ${colorPalette.blue200};
  }
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const FormSection = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;

  @media (max-width: 767.98px) {
    padding-bottom: 100px;
  }
`;

/* Card */
export const SectionListContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  border: 1px solid ${colorPalette.blue100};
  border-radius: 8px;
  overflow: hidden;
`;

export const SectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 10px;
  border-bottom: 1px solid ${colorPalette.blue200};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 767.98px) {
    padding: 24px 10px;
  }
`;

export const ContentSpacing = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SectionText = styled.p`
  flex-grow: 1;
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyLGMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodyMDMain};
  }
`;

/* Collapsible Section */
export const CollapsibleSection = styled(Row)`
  padding: 16px;
  background-color: ${colorPalette.blue50};
`;

export const LogoutSection = styled.div`
  margin-top: 48px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const VersionTitle = styled.p`
  margin-top: 16px;
  margin-bottom: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};
`;
