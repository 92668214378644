import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";
import { ILease } from "./leaseService";
import { IProperty } from "./propertyService";

export interface IPicture {
  picture_id: number;
  picture_path: string;
  created_at: string;
}

export interface IUnitOwner {
  unit_owner_id: number;
  shares: number;
  purchase_price: number;
  created_at: string;
  unit?: IUnit | null;
}

export enum UnitPurposeType {
  INVESTMENT = "investment",
  SELF_INTEREST = "self-interest",
}

export enum UnitSoldBy {
  DG_HOMES = "dg-homes",
  METRICAL_DEVELOPMENTS = "metrical-developments",
}

export const formatSoldBy = (soldBy: UnitSoldBy) => {
  switch (soldBy) {
    case UnitSoldBy.DG_HOMES:
      return "DG Homes";
    case UnitSoldBy.METRICAL_DEVELOPMENTS:
      return "Metrical Developments";
    default:
      return "-";
  }
};

export enum UnitStatus {
  AVAILABLE = "available",
}

export const formatUnitStatus = (status: UnitStatus) => {
  switch (status) {
    case UnitStatus.AVAILABLE:
      return "Verfügbar";
    default:
      return "-";
  }
};

export interface IUnit {
  unit_id: number;
  property: IProperty;
  unit_number: string;
  square_feet: number;
  bedroom: number;
  bathroom: number;
  floor: string;
  under_renovation: boolean;
  under_dispute: boolean;
  pm_contract_signed: boolean;
  furnished: boolean;
  dewa?: string | null;
  municipality_no?: string | null;
  gas_no?: string | null;
  minimum_annual_rent?: number;
  purpose_type: UnitPurposeType;
  sold_by: UnitSoldBy;
  owner: IUnitOwner | null;
  leases: ILease[];
  status: UnitStatus;
  created_at: string;
  updated_at: string;
  documents: IDocument[];
  pictures: IPicture[];
}

export const fetchUnits = async (
  property_id?: number,
  page?: number,
  pageSize?: number
): Promise<IUnit[]> => {
  let queryParams = new URLSearchParams();

  if (property_id !== undefined) {
    queryParams.append("property_id", property_id.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  let url = `${API_URL}/units?${queryParams.toString()}`;

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.units;
};

export const fetchUnit = async (unitId: number): Promise<IUnit> => {
  const response = await fetch(`${API_URL}/units/${unitId}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const downloadUnitDocument = async (
  unit_id: number,
  document_id: number
): Promise<Blob | null> => {
  const response = await fetch(
    `${API_URL}/units/${unit_id}/documents/${document_id}/download`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};
