import styled from "styled-components";
import { FaExclamationCircle } from "react-icons/fa";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const Card = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: "active" | "inactive" }>`
  background: ${({ status }) =>
    status === "active"
      ? "linear-gradient(247.44deg, #0C473A 2.55%, #16856C 82.9%)"
      : "linear-gradient(247.44deg, #811614 2.55%, #E31B07 82.9%)"};

  color: white;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  gap: 16px;
`;

export const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 12px;
  border-bottom: 1px solid #ffffff33;
  flex-shrink: 0; /* Prevent shrinking */
`;

export const ProfileImage = styled.img`
  width: 60px; /* Desired width */
  height: 60px; /* Desired height */
  border-radius: 50%;
  min-width: 60px; /* Prevent shrinking below 60px */
  min-height: 60px; /* Prevent shrinking below 60px */
`;

// Initials Avatar
export const InitialsAvatar = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>`
  width: 60px; /* Desired width */
  height: 60px; /* Desired height */
  border-radius: 50%;
  background-color: ${colorPalette.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ isActive }) =>
    isActive ? colorPalette.successMedium : colorPalette.errorDark};
  ${typeScale.headingXSMain};
  min-width: 60px; /* Prevent shrinking below 60px */
  min-height: 60px; /* Prevent shrinking below 60px */
`;

export const Name = styled.h2`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.headingL}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Subtext = styled.p`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.bodyXS}
`;

export const StatusBadge = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: "active" | "inactive" }>`
  height: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${({ status }) => (status === "active" ? colorPalette.successDark : colorPalette.errorDark)};
  font-weight: bold;
  font-size: 0.875rem;
`;

export const MessageSection = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 8px;
  flex-grow: 1;
  justify-content: space-between;
`;

export const MessageIcon = styled(FaExclamationCircle)`
  font-size: 1.25rem;
  flex-shrink: 0;
`;

export const MessageText = styled.p`
  margin: 0;
  flex-grow: 1;
  color: ${colorPalette.white};
  ${typeScale.bodyMDMain}

  @media (max-width: 767.98px) {
    ${typeScale.bodySMMain};
  }
`;

export const ActionButton = styled.button`
  background-color: ${colorPalette.white};
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f1f1f1;
  }
`;
