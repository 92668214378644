import Logo from "../../assets/images/logo/logo-multicolor.svg";
import { Container, Subtitle, Title, Headline, Text, Section } from "./terms-and-conditions-page.styles";
import Footer from "../../components/footer/footer";
import useIsMobile from "../../hooks/useIsMobile";
import { Row, Col } from "react-bootstrap";

export default function TermsAndConditionsPage() {
    const isMobile = useIsMobile();

    return (
        <Container>
            <img src={Logo} alt="Managd Logo" width={200} style={{ marginTop: 24 }} />
            <Row className="justify-content-center flex-grow-1" style={{ marginTop: 48, paddingLeft: 16, paddingRight: 16 }}>
                <Col xs={12} lg={8}>
                    <Title>Allgemeine Geschäftsbedingungen</Title>
                    <Subtitle>Stand: 11.11.2024</Subtitle>

                    {/* SECTION 1*/}
                    <Section>
                        <Headline>Section 1</Headline>
                        <Text>
                            Lorem ipsum odor amet, consectetuer adipiscing elit. Torquent turpis praesent euismod ac torquent massa adipiscing donec. Lectus vulputate ultricies convallis ridiculus sed; luctus laoreet fames. Taciti neque quisque cursus; taciti eget aliquam? Sem curabitur class proin urna urna. Hendrerit potenti gravida nisi conubia sollicitudin cubilia urna. Fames blandit accumsan nascetur imperdiet id risus turpis consequat.
                        </Text>
                        <Text>
                            Sagittis curae eleifend habitasse posuere dictum, est odio viverra. Torquent ridiculus diam id, porta commodo congue. Sed nostra ex vulputate torquent penatibus sit ullamcorper. Semper integer morbi quisque senectus sed tempus parturient dictum purus. Ullamcorper neque nisl id nec montes volutpat eget ultricies. Porta risus a vulputate torquent tempus sodales varius inceptos.
                        </Text>
                    </Section>

                    {/* SECTION 2*/}
                    <Section>
                        <Headline>Section 2</Headline>
                        <Text>
                            Lorem ipsum odor amet, consectetuer adipiscing elit. Torquent turpis praesent euismod ac torquent massa adipiscing donec. Lectus vulputate ultricies convallis ridiculus sed; luctus laoreet fames. Taciti neque quisque cursus; taciti eget aliquam? Sem curabitur class proin urna urna. Hendrerit potenti gravida nisi conubia sollicitudin cubilia urna. Fames blandit accumsan nascetur imperdiet id risus turpis consequat.
                        </Text>
                        <Text>
                            Sagittis curae eleifend habitasse posuere dictum, est odio viverra. Torquent ridiculus diam id, porta commodo congue. Sed nostra ex vulputate torquent penatibus sit ullamcorper. Semper integer morbi quisque senectus sed tempus parturient dictum purus. Ullamcorper neque nisl id nec montes volutpat eget ultricies. Porta risus a vulputate torquent tempus sodales varius inceptos.
                        </Text>
                    </Section>
                </Col>
            </Row>

            {!isMobile && <Footer theme="dark" />}
        </Container>
    );
}
