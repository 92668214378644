import { styled } from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const StyledContainer = styled.div`
  width: 100%;
  padding: 16px;
  min-height: calc(100 * var(--vh) - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerCard = styled.div`
  max-width: 600px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    border: 0;
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  width: 70%;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const IllustrationImage = styled.img`
  margin-bottom: 32px;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

export const Title = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 768px) {
    ${typeScale.headingSMMain};
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 32px;
  text-align: center;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};

  @media (max-width: 768px) {
    ${typeScale.bodySM};
    text-align: center;
  }
`;

export const InfoText = styled.p`
  margin-top: 32px;
  margin-bottom: 0;
  text-align: center;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};

  @media (max-width: 768px) {
    ${typeScale.bodyXXS};
    text-align: center;
  }
`;
