import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

// Shared Card Container
export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffffa6;
  backdrop-filter: blur(100px);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${colorPalette.blue200};
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
`;

// Card Header (Title and Subtitle)
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 16px;
`;

export const CardTitle = styled.h3`
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
  margin: 0;
`;

export const CardSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;

// Circular Progress Wrapper
export const CircularProgressWrapper = styled.div`
  position: relative;
  width: 70%;
  margin: 0 auto;
`;

// Content wrapper inside the circular progress bar (for icon and amount)
export const CircularContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Icon wrapper inside the circular progress
export const CircularIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px; // Space between the icon and the amount
`;

// Amount below the icon
export const CircularAmountWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>`
  margin: 0;
  color: ${({ color }) => color};
  ${typeScale.headingMain};
`;

// Card Footer Text
export const CardFooter = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS};
`;
