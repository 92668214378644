import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  height: 100%;
  overflow: hidden;

  @media (max-width: 767.98px) {
    border-radius: 0;
    border: 0;
    margin-left: -12px;
    margin-right: -12px;
  }
`;

// Layout for the statistics
export const UnitStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 8px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    margin-top: 0;
    padding: 12px 16px;
  }
`;

// Individual statistic item
export const UnitStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodyXS}
  color: ${colorPalette.textMain};
`;

// Label for the statistics
export const UnitStatisticLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain}
`;

export const ImageSlider = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SliderControl = styled.button<{ direction: "left" | "right" }>`
  position: absolute;
  top: 50%;
  ${({ direction }) => direction}: 8px;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
`;

export const ActionButtonsContainer = styled.div`
  position: absolute;
  border-radius: 4px;
  bottom: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 8px;
  backdrop-filter: blur(20px);
  background: #363a4d33;
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`;

export const DetailsSection = styled.div``;

export const AttributeRow = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const AttributeItem = styled.div`
  align-items: center;
`;

export const AttributeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const AttributeLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXSMain}
`;

export const PurchaseInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 14px;
  border-top: 1px solid ${colorPalette.blue200};

  &:first-of-type {
    border-top: none;
  }
`;

export const PurchaseInfoLabel = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
`;

export const PurchaseInfoValue = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;
