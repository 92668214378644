import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    CardContainer,
    Container,
    Title,
    UnitTitle,
    UnitDetailsRow,
    UnitDetail,
    UnitDetailLabel
} from './owned-units.styles';
import { fetchUnits, IUnit } from '../../../../services/unitService';
import { formatMoney } from '../../../../utils/helpers';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { useNavigate } from 'react-router-dom';

interface OwnedUnitsProps {
    className?: string | undefined;
    property_id: number;
    property_name: string;
}

const OwnedUnits: React.FC<OwnedUnitsProps> = ({ className, property_id, property_name }) => {
    const navigate = useNavigate();

    // States
    const [units, setUnits] = useState<IUnit[]>([]);

    const onClickUnit = (unit_id: number) => {
        navigate(`/my-units/${unit_id}`);
    }

    // Fetch units
    useEffect(() => {
        if (property_id) {
            const loadUnits = async () => {
                try {
                    const units = await fetchUnits(property_id);
                    setUnits(units);
                } catch (error) {
                    console.log('Error while fetching investor units:', error);
                }
            }
            loadUnits();
        }
    }, [property_id]);

    return (
        <Container className={className}>
            <Title>Einheiten im Portfolio</Title>
            <Row className="gy-3">
                {units.map((unit, index) => {
                    return (
                        <Col sm={12} md={6} xxl={4} key={index}>
                            <CardContainer onClick={() => onClickUnit(unit.unit_id)}>
                                <UnitTitle>
                                    Unit {unit.unit_number} • {property_name}
                                </UnitTitle>
                                <UnitDetailsRow>
                                    <UnitDetail>
                                        <MaterialIcon icon="bed" size={20} color={colorPalette.blue300} />
                                        <UnitDetailLabel>{unit.bedroom} Bedroom</UnitDetailLabel>
                                    </UnitDetail>
                                    <UnitDetail>
                                        <MaterialIcon icon="straighten" size={20} color={colorPalette.blue300} />
                                        <UnitDetailLabel>{unit.square_feet} Sqft</UnitDetailLabel>
                                    </UnitDetail>
                                    {unit.owner && (
                                        <UnitDetail>
                                            <MaterialIcon icon="shoppingmode" size={20} color={colorPalette.blue300} />
                                            <UnitDetailLabel>AED {formatMoney(unit.owner.purchase_price)}</UnitDetailLabel>
                                        </UnitDetail>
                                    )}
                                </UnitDetailsRow>
                            </CardContainer>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    );
};

export default OwnedUnits;
