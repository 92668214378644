import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    SidebarModalBackdrop,
    SidebarModal,
    SidebarModalHeadline,
    SidebarModalDivider,
    SidebarModalScrollableContent,
    HeadlineContainer,
    SidebarModalHeader,
} from '../sidemodal.styles';
import { downloadLeaseDocument, fetchLease, ILease, PaymentPurpose } from '../../../services/leaseService';
import MaterialIcon from '../../../components/material-icon';
import { colorPalette } from '../../../utils/theme';
import { IDocument } from '../../../services/documentService';
import CTAButton from '../../../components/cta-button/cta-button';
import { ContentHeaderTitle, ContentHeaderSubtitle, CategoryHeadline, ContractDetailItem, ContractDetailLabel, ContractDetailValue, ContentHeader, ContractDetails, SpinnerWrapper, ButtonWrapper } from './tenancy-contract-sidemodal.styles';
import useAlertStore from '../../../stores/alertStore';
import { formatMoney } from '../../../utils/helpers';
import { Spinner } from 'react-bootstrap';
import { usePWA } from '../../../context/PWAContext';

interface TenancyContractSidebarModalProps {
    leaseDocument?: IDocument;
    isVisible: boolean;
    onClose: () => void;
}

const TenancyContractSidebarModal: React.FC<TenancyContractSidebarModalProps> = ({
    leaseDocument,
    isVisible,
    onClose
}) => {
    // Stores
    const isPWA = usePWA();
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [lease, setLease] = useState<ILease | null>(null);

    // Prevent scrolling down
    useEffect(() => {
        // Disable background scrolling
        document.body.style.overflow = 'hidden';

        // Prevent touchmove only if the event is outside the modal
        const preventBackgroundScroll = (e: TouchEvent) => {
            if (!(e.target as HTMLElement).closest('.sidebar-modal-content')) {
                e.preventDefault();
            }
        };

        document.addEventListener('touchmove', preventBackgroundScroll, { passive: false });

        return () => {
            // Restore background scrolling
            document.body.style.overflow = 'unset';
            document.removeEventListener('touchmove', preventBackgroundScroll);
        };
    }, []);

    useEffect(() => {
        const fetchLeaseDetails = async () => {
            if (!leaseDocument || !leaseDocument.lease) return;

            try {
                setIsLoading(true);
                const lease = await fetchLease(leaseDocument.lease.lease_id);
                setLease(lease);
            } catch (error) {
                console.log("error while downloading lease document: ", error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchLeaseDetails();
    }, [leaseDocument, showAlert]);

    // Functions
    const onClickDownload = useCallback(async () => {
        if (!leaseDocument || !leaseDocument.lease) return;

        try {
            setIsDownloading(true);
            let blob: Blob | null = await downloadLeaseDocument(leaseDocument.lease.lease_id, leaseDocument.document_id);
            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);

                const anchor = document.createElement('a');
                anchor.href = blobUrl;
                anchor.download = leaseDocument.file_name;
                document.body.appendChild(anchor);
                anchor.click();

                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(anchor);
            }
        } catch (error) {
            console.log("error while downloading lease document: ", error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsDownloading(false);
        }
    }, [leaseDocument, showAlert]);

    // Memo
    const isContractActive = useMemo(() => {
        if (!lease) return false;

        const startDate = new Date(lease.start_date);
        const endDate = new Date(lease.end_date);
        const currentDate = new Date();

        if (currentDate >= startDate && currentDate <= endDate) return true;

        return false;
    }, [lease]);

    return (
        <SidebarModalBackdrop visible={isVisible} onClick={onClose}>
            <SidebarModal visible={isVisible} isPWA={isPWA}>
                <SidebarModalHeader>
                    <HeadlineContainer>
                        <SidebarModalHeadline>Mietvertrag</SidebarModalHeadline>
                    </HeadlineContainer>
                    <MaterialIcon icon="close" size={24} color={colorPalette.blue500} onClick={onClose} />
                </SidebarModalHeader>
                <SidebarModalDivider />
                <SidebarModalScrollableContent withDefaultPadding={false} className="sidebar-modal-content">
                    <ContentHeader>
                        <MaterialIcon icon="contact_page" size={40} color={isContractActive ? colorPalette.successMedium : colorPalette.blue300} />
                        <ContentHeaderTitle>Mietvertrag {leaseDocument?.unit?.unit_number} • {leaseDocument?.unit?.property.property_name}</ContentHeaderTitle>
                        <ContentHeaderSubtitle color={isContractActive ? colorPalette.successMedium : colorPalette.blue300}>{isContractActive ? "Aktiv" : "Ausgelaufen"}</ContentHeaderSubtitle>
                        <CTAButton
                            variant="ghost"
                            size="M"
                            icon="file_save"
                            label="Vertrag herunterladen"
                            onClick={onClickDownload}
                            loading={isDownloading}
                            noPadding
                        />
                    </ContentHeader>

                    <CategoryHeadline>Mieterdetails</CategoryHeadline>

                    {/* CONTRACT DETAIL ITEMS */}
                    {!lease && isLoading && (
                        <SpinnerWrapper>
                            <Spinner />
                        </SpinnerWrapper>
                    )}
                    {lease && (
                        <ContractDetails>
                            <ContractDetailItem>
                                <div className="d-flex" style={{ gap: 8 }}>
                                    <MaterialIcon icon="location_away" size={20} color={colorPalette.textDark} />
                                    <ContractDetailLabel>Mieter</ContractDetailLabel>
                                </div>
                                <ContractDetailValue>{lease.tenant.first_name} {lease.tenant.last_name}</ContractDetailValue>
                            </ContractDetailItem>
                            <ContractDetailItem>
                                <div className="d-flex" style={{ gap: 8 }}>
                                    <MaterialIcon icon="local_atm" size={20} color={colorPalette.textDark} />
                                    <ContractDetailLabel>Mieteinnahmen</ContractDetailLabel>
                                </div>
                                <ContractDetailValue>AED {formatMoney(lease.annual_rent_amount, false)}</ContractDetailValue>
                            </ContractDetailItem>
                            <ContractDetailItem>
                                <div className="d-flex" style={{ gap: 8 }}>
                                    <MaterialIcon icon="event_upcoming" size={20} color={colorPalette.textDark} />
                                    <ContractDetailLabel>Startdatum Mietvertrag</ContractDetailLabel>
                                </div>
                                <ContractDetailValue>{new Date(lease.start_date).toLocaleDateString()}</ContractDetailValue>
                            </ContractDetailItem>
                            <ContractDetailItem>
                                <div className="d-flex" style={{ gap: 8 }}>
                                    <MaterialIcon icon="event_repeat" size={20} color={colorPalette.textDark} />
                                    <ContractDetailLabel>Laufzeit</ContractDetailLabel>
                                </div>
                                <ContractDetailValue>1 Jahr</ContractDetailValue>
                            </ContractDetailItem>
                            <ContractDetailItem>
                                <div className="d-flex" style={{ gap: 8 }}>
                                    <MaterialIcon icon="checkbook" size={20} color={colorPalette.textDark} />
                                    <ContractDetailLabel>Anzahl an Schecks</ContractDetailLabel>
                                </div>
                                <ContractDetailValue>{lease.payments.filter(payment => payment.purpose === PaymentPurpose.RENT).length}</ContractDetailValue>
                            </ContractDetailItem>
                            <ContractDetailItem>
                                <div className="d-flex" style={{ gap: 8 }}>
                                    <MaterialIcon icon="move_to_inbox" size={20} color={colorPalette.textDark} />
                                    <ContractDetailLabel>Mietkaution</ContractDetailLabel>
                                </div>
                                <ContractDetailValue>AED {formatMoney(lease.deposit_amount, false)}</ContractDetailValue>
                            </ContractDetailItem>
                        </ContractDetails>
                    )}
                    {lease && (
                        <ButtonWrapper>
                            <CTAButton
                                variant="ghost"
                                size="S"
                                icon="contact_support"
                                label="Fragen? Kontaktiere uns"
                                onClick={() => alert("Coming soon...")}
                                noPadding
                            />
                        </ButtonWrapper>
                    )}
                </SidebarModalScrollableContent>
            </SidebarModal>
        </SidebarModalBackdrop>
    );
};

export default TenancyContractSidebarModal;
