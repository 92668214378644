import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-phone-number-input/style.css'
import 'material-symbols';

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// Routes
import ErrorPage from './routes/error-page/error-page';
import Root from './routes/root';
import LoginPage from './routes/login-page/login-page';
import ConstructionUpdatesPage from './routes/construction-updates-page';
import ConstructionUpdatePage from './routes/construction-update-page/construction-update-page';
import ProtectedRoute from './routes/protected-route';
import PageContainerWrapper from './routes/page-container-wrapper';
import ResetPasswordPage from './routes/reset-password-page/reset-password-page';
import CoursePage from './routes/course-page';
import MyUnitsPage from './routes/my-units-page/my-units-page';
import CourseDetailPage from './routes/course-detail-page/course-detail-page';
import * as Sentry from "@sentry/react";
import UnitDetailPage from './routes/unit-detail-page/unit-detail-page';
import PackageSelectionPage from './routes/package-selection-page/package-selection-page';
import DashboardPage from './routes/dashboard-page/dashboard-page';
import FinancialsPage from './routes/financials-page';
import ProjectPage from './routes/project-page/project-page';
import ContractsPage from './routes/contracts-page/contracts-page';
import KnowledgeHubPage from './routes/knowledge-hub/knowledge-hub';
import ProfilePage from './routes/profile-page/profile-page';
import ProfileBankDetailsPage from './routes/profile-page/sub-pages/bank-details-page/bank-details-page';
import ProfileAddresDetailsPage from './routes/profile-page/sub-pages/address-details-page/address-details-page';
import ProfileSettingsPage from './routes/profile-page/sub-pages/settings-page/settings-page';
import ProfileEditAddresDetailsPage from './routes/profile-page/sub-pages/edit-address-details-page/edit-address-details-page';
import ProfileEditBankDetailsPage from './routes/profile-page/sub-pages/edit-bank-details-page/edit-bank-details-page';
import ProfileDocumentDetailsPage from './routes/profile-page/sub-pages/document-details-page/document-details-page';
import FAQPage from './routes/knowledge-hub/sub-pages/faq-page/faq-page';
import * as serviceWorkerRegistration from "./serviceWorkerResgistration";
import { PWAProvider } from './context/PWAContext';
import PrivacyPolicyPage from './routes/privacy-policy-page/privacy-policy-page';
import TermsAndConditionsPage from './routes/terms-and-conditions-page/terms-and-conditions-page';
import ArticleDetailPage from './routes/knowledge-hub/sub-pages/article-detail-page/article-detail-page';
import OnboardingPage from './routes/onboarding-page/onboarding-page';

const isProduction = process.env.REACT_APP_ENV === "production";

Sentry.init({
  dsn: "https://352009d95c7053d2edcab1a5b0b50a3b@o4507089669849088.ingest.de.sentry.io/4507089726603344",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/getmanagd\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <ProtectedRoute>
          <PageContainerWrapper id="dashboard">
            <DashboardPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/onboarding",
        element: <ProtectedRoute>
          <PageContainerWrapper id="onboarding">
            <OnboardingPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile">
            <ProfilePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/bank-details",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-bank-details">
            <ProfileBankDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/edit-bank-details",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-edit-bank-details">
            <ProfileEditBankDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/address-details",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-address-details">
            <ProfileAddresDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/documents",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-documents">
            <ProfileDocumentDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/edit-address",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-edit-address">
            <ProfileEditAddresDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/settings",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-settings">
            <ProfileSettingsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/my-units",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-units">
            <MyUnitsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/my-units/:unitId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-units">
            <UnitDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/construction-updates",
        element: <ProtectedRoute>
          <PageContainerWrapper id="construction-updates">
            <ConstructionUpdatesPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/construction-update/:updateId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="construction-update">
            <ConstructionUpdatePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/course",
        element: <ProtectedRoute>
          <PageContainerWrapper id="course">
            <CoursePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/course/:itemId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="course-detail">
            <CourseDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/knowledge-hub",
        element: <ProtectedRoute>
          <PageContainerWrapper id="knowledge-hub">
            <KnowledgeHubPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/faq",
        element: <ProtectedRoute>
          <PageContainerWrapper id="faq">
            <FAQPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/articles/3",
        element: <ProtectedRoute>
          <PageContainerWrapper id="article">
            <ArticleDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/packages",
        element: <ProtectedRoute>
          <PageContainerWrapper id="package-selection">
            <PackageSelectionPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/financials",
        element: <ProtectedRoute>
          <PageContainerWrapper id="financials">
            <FinancialsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/contracts",
        element: <ProtectedRoute>
          <PageContainerWrapper id="contracts">
            <ContractsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/project/:propertyId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="project">
            <ProjectPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/reset-password",
        element: <PageContainerWrapper id="reset-password" fullPage>
          <ResetPasswordPage />
        </PageContainerWrapper>,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditionsPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PWAProvider>
      <RouterProvider router={router} />
    </PWAProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
