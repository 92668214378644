import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";
import { Row, Col } from "react-bootstrap";

export const StyledContainer = styled.div`
  overflow-x: hidden;
  width: 100%;
  padding-bottom: 32px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;

  @media (max-width: 767.98px) {
    padding: 16px 16px;
    border-bottom: 1px solid ${colorPalette.blue200};
  }
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const FormSection = styled(Row)`
  padding-left: 16px;
  padding-right: 16px;
`;

export const CategoryTitle = styled.h4`
  margin-top: 16px;
  margin-bottom: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}
`;

export const ButtonCol = styled(Col)`
  @media (min-width: 576px) {
    margin-top: 32px;
  }
`;
