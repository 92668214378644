import styled from "styled-components";
import { colorPalette, typeScale } from "../../../utils/theme";

export const SixDigitCodeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

export const CodeInput = styled.input`
  height: 60px;
  width: 50px;
  padding: 8px;
  text-align: center;
  border: 1px solid ${colorPalette.blue300};
  border-radius: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain};

  &:focus {
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
  }
`;

export const Divider = styled.span`
  color: ${colorPalette.textMain};
  font-size: 24px;
`;
