import React, { useMemo, useState } from 'react';
import {
    CardContainer,
    ImageSlider,
    SliderControl,
    DetailsSection,
    AttributeRow,
    AttributeItem,
    AttributeIcon,
    AttributeLabel,
    PurchaseInfoRow,
    PurchaseInfoLabel,
    PurchaseInfoValue,
    ActionButton,
    ActionButtonsContainer,
    UnitStatistic,
    UnitStatisticLabel,
    UnitStatistics,
} from './unit-details-card.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { formatMoney } from '../../../../utils/helpers';
import ImageModal from '../../../../modals/image-modal/image-modal';
import LazyLoadImage from '../../../../components/lazy-load-image/lazy-load-image';
import useIsMobile from '../../../../hooks/useIsMobile';
import TabBar from '../../../../components/tab-bar/tab-bar';
import { useSwipeable } from 'react-swipeable';

interface UnitDetailsCardProps {
    images: string[];
    bedrooms: number;
    bathrooms: number;
    size: number;
    purchaseDate: string;
    purchasePrice: number;
    unit_number: string;
    property_name: string;
}

const UnitDetailsCard: React.FC<UnitDetailsCardProps> = ({
    images,
    bedrooms,
    bathrooms,
    size,
    purchaseDate,
    purchasePrice,
    unit_number,
    property_name,
}) => {
    const isMobile = useIsMobile();

    // States
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevious = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrevious,
        delta: 30,
        trackMouse: true,
    });

    // If images array is empty, use the placeholder image
    const currentImage = useMemo(() => {
        return images.length > 0 ? images[currentImageIndex] : '';
    }, [images, currentImageIndex]);

    return (
        <>
            <CardContainer>
                {isMobile && (
                    <UnitStatistics>
                        <UnitStatistic>
                            <MaterialIcon icon="bed" size={isMobile ? 20 : 24} color={colorPalette.blue300} />
                            <UnitStatisticLabel>{bedrooms} Bedroom</UnitStatisticLabel>
                        </UnitStatistic>
                        <UnitStatistic>
                            <MaterialIcon icon="straighten" size={isMobile ? 20 : 24} color={colorPalette.blue300} />
                            <UnitStatisticLabel>{size} Sqft</UnitStatisticLabel>
                        </UnitStatistic>
                        <UnitStatistic>
                            <MaterialIcon icon="shoppingmode" size={isMobile ? 20 : 24} color={colorPalette.blue300} />
                            <UnitStatisticLabel>AED {formatMoney(purchasePrice, false)}</UnitStatisticLabel>
                        </UnitStatistic>
                    </UnitStatistics>
                )}
                <ImageSlider {...handlers}>
                    <LazyLoadImage
                        src={currentImage}
                        alt={`Unit Image ${currentImageIndex + 1}`}
                        width="100%"
                        height="100%"
                        onClick={() => setIsImageModalOpen(true)}
                    />
                    {images.length > 1 && (
                        <>
                            <SliderControl direction="left" onClick={handlePrevious}>
                                <MaterialIcon icon="arrow_back" size={24} color={colorPalette.white} />
                            </SliderControl>
                            <SliderControl direction="right" onClick={handleNext}>
                                <MaterialIcon icon="arrow_forward" size={24} color={colorPalette.white} />
                            </SliderControl>
                        </>
                    )}
                    <ActionButtonsContainer>
                        <ActionButton>
                            <MaterialIcon icon="3d_rotation" size={20} color={colorPalette.white} />
                        </ActionButton>
                        <ActionButton>
                            <MaterialIcon icon="zoom_out_map" size={20} color={colorPalette.white} onClick={() => setIsImageModalOpen(true)} />
                        </ActionButton>
                    </ActionButtonsContainer>
                </ImageSlider>
                {isMobile ? (
                    <TabBar
                        tabs={[
                            { label: "Handover", value: "handover" },
                            { label: "Dokumente", value: "documents" },
                            { label: "Wissenwertes", value: "good-to-know-articles" },
                            { label: "Bauupdates", value: "property-updates" }
                        ]}
                        activeTab="handover"
                        onTabChange={function (tabValue: string): void {
                            console.log("tabValue: " + tabValue);
                        }}
                    />
                ) : (
                    <DetailsSection>
                        <AttributeRow>
                            <AttributeItem>
                                <AttributeIcon>
                                    <MaterialIcon icon="bed" size={24} color={colorPalette.blue300} />
                                </AttributeIcon>
                                <AttributeLabel>{bedrooms} Bedroom</AttributeLabel>
                            </AttributeItem>
                            <AttributeItem>
                                <AttributeIcon>
                                    <MaterialIcon icon="bathtub" size={24} color={colorPalette.blue300} />
                                </AttributeIcon>
                                <AttributeLabel>{bathrooms} Bathroom</AttributeLabel>
                            </AttributeItem>
                            <AttributeItem>
                                <AttributeIcon>
                                    <MaterialIcon icon="straighten" size={24} color={colorPalette.blue300} />
                                </AttributeIcon>
                                <AttributeLabel>{size} Sqft</AttributeLabel>
                            </AttributeItem>
                        </AttributeRow>
                        <PurchaseInfoRow>
                            <PurchaseInfoLabel>
                                <MaterialIcon icon="event_available" size={20} color={colorPalette.blue600} />
                                Kaufdatum
                            </PurchaseInfoLabel>
                            <PurchaseInfoValue>{purchaseDate}</PurchaseInfoValue>
                        </PurchaseInfoRow>
                        <PurchaseInfoRow>
                            <PurchaseInfoLabel>
                                <MaterialIcon icon="shopping_bag" size={20} color={colorPalette.blue600} />
                                Kaufpreis
                            </PurchaseInfoLabel>
                            <PurchaseInfoValue>AED {formatMoney(purchasePrice, false)}</PurchaseInfoValue>
                        </PurchaseInfoRow>
                    </DetailsSection>
                )}
            </CardContainer>

            <ImageModal
                images={images}
                title={`Unit ${unit_number}`}
                subtitle={property_name}
                isOpen={isImageModalOpen}
                onClose={() => setIsImageModalOpen(false)}
            />
        </>
    );
};

export default UnitDetailsCard;
