import React, { useEffect, useRef, useState } from 'react';
import {
    ArticleCard,
    ArticleImage,
    ArticleContent,
    ArticleTitle,
    ArticleSubtitle,
    Title,
    ArticleAdditionalText,
    ArticleTitleWrapper,
    ArticlesContainer,
    HeaderContainer,
    ScrollButton
} from './good-to-know-articles.styles';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';
import CTAButton from '../cta-button/cta-button';
import useIsMobile from '../../hooks/useIsMobile';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { ARTICLES } from '../../config/articleConfig';

interface GoodToKnowArticlesProps {
    className?: string | undefined;
}

const GoodToKnowArticles: React.FC<GoodToKnowArticlesProps> = ({ className }) => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    // References
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    // States
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const updateScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        updateScrollButtons();
        const handleResize = () => updateScrollButtons();
        window.addEventListener('resize', handleResize);

        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener('scroll', updateScrollButtons);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener('scroll', updateScrollButtons);
            }
        };
    }, []);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -300 : 300,
                behavior: 'smooth',
            });
            updateScrollButtons();
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0));
        setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - (scrollContainerRef.current?.offsetLeft || 0);
        const walk = (x - startX) * 1.5;
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const handleMouseUpOrLeave = () => {
        setIsDragging(false);
    };

    return (
        <ArticlesContainer className={className}>
            <HeaderContainer>
                <div className="d-flex align-items-center" style={{ gap: 4, marginBottom: 16 }} onClick={isMobile ? () => {
                    alert("Coming soon...");
                } : undefined}>
                    <Title>Wissenswertes</Title>
                    <MaterialIcon className="d-md-none d-inline" icon="chevron_forward" size={24} color={colorPalette.blue500} />
                </div>
            </HeaderContainer>
            <div className="scroll-wrapper">
                {canScrollLeft && (
                    <ScrollButton className="scroll-left d-none d-md-inline" onClick={() => scroll('left')}>
                        <MaterialIcon icon="arrow_back" size={16} color={colorPalette.textMain} />
                    </ScrollButton>
                )}
                <div
                    className="updates-container"
                    ref={scrollContainerRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUpOrLeave}
                    onMouseLeave={handleMouseUpOrLeave}
                >
                    {ARTICLES.map((article) => (
                        <ArticleCard key={article.id}>
                            <ArticleImage src={article.image} alt={article.title} draggable={false} />
                            <ArticleContent>
                                <ArticleSubtitle>Knowledge Hub</ArticleSubtitle>
                                <ArticleTitleWrapper>
                                    <ArticleTitle>{article.title}</ArticleTitle>
                                    <ArticleAdditionalText>{article.description}</ArticleAdditionalText>
                                </ArticleTitleWrapper>
                                <div>
                                    <CTAButton variant="ghost" size="S" label="Weiterlesen" noPadding onClick={() => navigate(`/articles/${article.id}`)} />
                                </div>
                            </ArticleContent>
                        </ArticleCard>
                    ))}
                </div>
                {canScrollRight && (
                    <ScrollButton className="scroll-right d-none d-md-inline" onClick={() => scroll('right')}>
                        <MaterialIcon icon="arrow_forward" size={16} color={colorPalette.textMain} />
                    </ScrollButton>
                )}
            </div>
        </ArticlesContainer>
    );
};

export default GoodToKnowArticles;
