import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const HeaderContainer = styled.div`
  padding: 0px 16px;
  margin-top: 48px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const ArticlesContainer = styled.div`
  .scroll-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  .updates-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding-left: 16px;
    padding-right: 16px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const ArticleCard = styled.div`
  display: flex;
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  padding: 16px;
  border-radius: 8px;
  height: 160px;
  width: 600px;
  margin-right: 16px;
  overflow: hidden;

  // Optional: ensure it doesn't shrink below min-width
  flex-shrink: 0;

  @media (max-width: 767.98px) {
    padding: 8px;
    min-width: 300px;
    max-width: 350px;
  }
`;

export const ArticleImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
  max-width: 50%;
  border-radius: 4px;
`;

export const ArticleContent = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;

  @media (max-width: 767.98px) {
    padding-left: 8px;
  }
`;

export const ArticleTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const ArticleTitle = styled.h3`
  color: ${colorPalette.textMain};
  margin: 4px 0;
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXXSMain};
  }
`;

export const ArticleSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;

export const ArticleAdditionalText = styled.p`
  color: ${colorPalette.textDark};
  margin: 0;
  ${typeScale.bodyXXS}
`;

export const ScrollButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  color: ${colorPalette.primaryDark};
  transition: background-color 0.2s ease;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.8);

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  &.scroll-left {
    left: 4px;
  }

  &.scroll-right {
    right: 4px;
  }
`;
