import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const ModalContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  width: auto;
  z-index: 10000;

  /* Apply min-width only on larger screens */
  @media (min-width: 768px) {
    max-width: 600px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingSM}
`;

export const HeaderSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  overflow-y: auto;

  /* Apply min-width only on larger screens */
  @media (min-width: 768px) {
    max-height: 600px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 16px 24px;

  p {
    color: ${colorPalette.textDark};
    ${typeScale.bodyXXSMain};
  }
`;

export const SectionTitle = styled.h4`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.bodySM};
`;

export const SectionBigTitle = styled.h4`
  color: ${colorPalette.primaryDark};
  ${typeScale.headingXS};
`;

export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXSMain}
`;

export const ItemDescription = styled.h5`
  color: ${colorPalette.textMain};
  margin-top: 16px;
  margin-bottom: 8px;
  ${typeScale.bodyXS};
`;

export const CostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ItemCost = styled.span`
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyXSMain}
`;

export const MinCostText = styled.span`
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXSMain};
`;

export const ItemSumCost = styled.span`
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyXS}
`;

export const SumTotal = styled(ItemRow)`
  border-top: 1px solid ${colorPalette.blue200};
  padding-top: 8px;
  font-weight: bold;
  color: ${colorPalette.primaryDark};
`;
