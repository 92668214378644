import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Container,
    Title,
    Label,
    Value,
    LinkRow,
    InfoLink,
    Icon,
    CardContainer,
    Item,
} from './project-key-data.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { formatMoney } from '../../../../utils/helpers';

interface ProjectKeyDataProps {
    className?: string | undefined;
    registrationNumber: string;
    buildingArea: number;
    landArea: number;
    landPrice: number;
}

const ProjectKeyData: React.FC<ProjectKeyDataProps> = ({
    className,
    registrationNumber,
    buildingArea,
    landArea,
    landPrice,
}) => {
    const onClickAfa = () => {

    }

    return (
        <Container className={className}>
            <Title>Kerndaten</Title>
            <CardContainer>
                <Row>
                    <Col md={6}>
                        <Item>
                            <Label>
                                <MaterialIcon icon="event_available" size={20} color={colorPalette.blue600} />
                                <span className="text-label">Registrierungsnummer (DLD)</span>
                            </Label>
                            <Value>{registrationNumber.length === 0 ? "N/A" : registrationNumber}</Value>
                        </Item>
                    </Col>

                    <Col md={6}>
                        <Item>
                            <Label>
                                <MaterialIcon icon="event_available" size={20} color={colorPalette.blue600} />
                                <span className="text-label">Gesamtfläche Gebäude</span>
                            </Label>
                            <Value>{buildingArea} sqft.</Value>
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Item>
                            <Label>
                                <MaterialIcon icon="event_available" size={20} color={colorPalette.blue600} />
                                <span className="text-label">Gesamtfläche Baugrund</span>
                            </Label>
                            <Value>{landArea} sqft.</Value>
                        </Item>
                    </Col>

                    <Col md={6}>
                        <Item>
                            <Label>
                                <MaterialIcon icon="event_available" size={20} color={colorPalette.blue600} />
                                <span className="text-label">Kaufpreis Baugrund</span>
                            </Label>
                            <Value>AED {formatMoney(landPrice)}</Value>
                        </Item>
                    </Col>
                </Row>

                <LinkRow>
                    <InfoLink onClick={onClickAfa}>Wie berechnest du die AfA?</InfoLink>
                    <MaterialIcon icon="arrow_outward" size={20} color={colorPalette.blue500} />
                </LinkRow>
            </CardContainer>
        </Container>
    );
};

export default ProjectKeyData;
