import styled, { keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Main container for the page
export const Container = styled.div`
  padding-bottom: 32px;
`;

export const Header = styled.div`
  padding: 12px 16px 12px 16px;
  gap: 16px;
`;

export const HeaderTitle = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingL}
`;

export const HeaderSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
`;

export const HeaderSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}
`;

export const HeaderTagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

// Loading container for Lottie animation
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

// Spinner loader when fetching more units
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
