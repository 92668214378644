import styled, { keyframes } from 'styled-components';
import { colorPalette, typeScale } from '../../../../utils/theme';

export const HeaderContainer = styled.div`
  position: relative;
  padding: 16px 24px;
  background-color: ${colorPalette.white};
  border-bottom: 1px solid ${colorPalette.blue200};
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingXS}
`;

export const HelpContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HelpText = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMD}
`;

export const HelpLink = styled.button`
  background: none;
  border: none;
  color: ${colorPalette.blue500};
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodyMD}

  &:hover {
    cursor: pointer;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${colorPalette.blue100};
  overflow: hidden;
  position: relative;
`;

const loadingAnimation = keyframes`
  0% {
    left: -20%; /* Start off-screen */
  }
  100% {
    left: 100%; /* End off-screen */
  }
`;

export const LoadingIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background-color: ${colorPalette.blue500};
  animation: ${loadingAnimation} 1.5s ease-in-out infinite;
`;
