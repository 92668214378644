import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Container for the entire checkbox component
export const CheckboxContainer = styled.label<{ disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  position: relative;
`;

// Input element (visually hidden)
export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

// Custom checkbox element
export const CustomCheckbox = styled.div<{
  isChecked: boolean;
  indeterminate: boolean;
  disabled: boolean;
}>`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isChecked, indeterminate }) =>
    isChecked || indeterminate ? colorPalette.blue500 : colorPalette.white};
  color: ${({ isChecked, indeterminate }) =>
    isChecked || indeterminate ? colorPalette.white : colorPalette.blue500};
  border: 1px solid ${colorPalette.blue500};
  transition: background-color 0.3s ease, border-color 0.3s ease;
  ${typeScale.bodyMDMain};

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: ${({ isChecked, indeterminate, disabled }) =>
      !disabled && (isChecked || indeterminate)
        ? colorPalette.blue700
        : colorPalette.blue100};
  }
`;

// Label for the checkbox
export const CheckboxLabel = styled.span`
  ${typeScale.bodyMDMain};
  color: ${colorPalette.textMain};
`;
