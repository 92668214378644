import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 32px;
`;

export const Title = styled.h4`
  margin: 0;
  padding: 16px 12px;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingL}

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;
