import React from 'react';

interface PageContainerWrapperProps {
    id: string;
    fullPage?: boolean;
    children: React.ReactNode;
}

const PageContainerWrapper: React.FC<PageContainerWrapperProps> = ({ id, fullPage = false, children }) => {
    return (
        <div className={fullPage ? "page-container-full" : "page-container"} id={id}>
            {children}
        </div>
    );
};

export default PageContainerWrapper;
