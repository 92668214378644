import { API_URL } from "../utils/constants";

export interface IDashboard {
  portfolio_value: number;
  received_payments: number;
  total_payments: number;
  purchase_values_by_year: Record<number, number>;
}

export const fetchDashboardData = async (): Promise<IDashboard> => {
  const response = await fetch(`${API_URL}/dashboard/`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};
