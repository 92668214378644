import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const SwitchContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: ${colorPalette.blue500};
  }

  &:focus + .slider {
    box-shadow: 0 0 1px ${colorPalette.blue500};
  }

  &:checked + .slider:before {
    transform: translateX(20px);
  }
`;

export const Slider = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: ${colorPalette.blue300};
  border-radius: 34px;
  transition: background-color 0.2s;

  &::before {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: ${colorPalette.white};
    border-radius: 50%;
    transition: transform 0.2s;
  }
`;

export const SwitchLabel = styled.span`
  ${typeScale.bodyMD}
  color: ${colorPalette.textMain};
  user-select: none;
`;
