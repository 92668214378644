import React from 'react';
import {
    CardContainer,
    Title,
    Subtitle,
    ChecklistItem,
    ChecklistText,
    ChecklistIcon,
    ChecklistDescription,
    Checklist,
    ChecklistItemContent
} from './onboarding-checklist-card.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import useIsMobile from '../../../../hooks/useIsMobile';

interface OnboardingChecklistCardProps {
    hasAddress: boolean;
    hasDocumentsUploaded: boolean;
    hasBankaccount: boolean;
}

const OnboardingChecklistCard: React.FC<OnboardingChecklistCardProps> = ({ hasAddress, hasDocumentsUploaded, hasBankaccount }) => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    return (
        <CardContainer>
            <Title>Deine Onboarding Checkliste</Title>
            <Subtitle>
                Willkommen bei managed! Hier findest du eine Übersicht, was noch zu tun ist, um deinen Account zu verifizieren, und alle Funktionen freizuschalten:
            </Subtitle>
            <Checklist>
                <ChecklistItem onClick={hasAddress ? undefined : () => navigate("/profile/edit-address")} clickable={!hasAddress}>
                    <ChecklistItemContent>
                        <ChecklistIcon completed={hasAddress}>
                            <MaterialIcon icon="check" size={20} color={hasAddress ? colorPalette.successLight : colorPalette.blue600} />
                        </ChecklistIcon>
                        <div>
                            <ChecklistText>Addresse & Kontakt bestätigen</ChecklistText>
                            {!isMobile && (
                                <ChecklistDescription>Bestätige deine Kontaktdaten für die offizielle Kommunikation</ChecklistDescription>
                            )}
                        </div>
                    </ChecklistItemContent>
                    {!hasAddress && (
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    )}
                </ChecklistItem>
                <ChecklistItem onClick={hasDocumentsUploaded ? undefined : () => navigate("/profile/documents")} clickable={!hasDocumentsUploaded}>
                    <ChecklistItemContent>
                        <ChecklistIcon completed={hasDocumentsUploaded}>
                            <MaterialIcon icon="check" size={20} color={hasDocumentsUploaded ? colorPalette.successLight : colorPalette.blue600} />
                        </ChecklistIcon>
                        <div>
                            <ChecklistText>Dokumente hochladen</ChecklistText>
                            {!isMobile && (
                                <ChecklistDescription>Diese Dokumente dienen dazu, deinen Account zu verifizieren</ChecklistDescription>
                            )}
                        </div>
                    </ChecklistItemContent>
                    {!hasDocumentsUploaded && (
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    )}
                </ChecklistItem>
                <ChecklistItem onClick={hasBankaccount ? undefined : () => navigate("/profile/edit-bank-details")} clickable={!hasBankaccount}>
                    <ChecklistItemContent>
                        <ChecklistIcon completed={hasBankaccount}>
                            <MaterialIcon icon="check" size={20} color={hasBankaccount ? colorPalette.successLight : colorPalette.blue600} />
                        </ChecklistIcon>
                        <div>
                            <ChecklistText>Bankverbindung hinzufügen</ChecklistText>
                            {!isMobile && (
                                <ChecklistDescription>Auf diese Bankverbindung überweisen wir deine  Einnahmen</ChecklistDescription>
                            )}
                        </div>
                    </ChecklistItemContent>
                    {!hasBankaccount && (
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    )}
                </ChecklistItem>
            </Checklist>
        </CardContainer>
    );
};

export default OnboardingChecklistCard;
