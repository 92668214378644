import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../utils/theme";

export const HeaderContainer = styled.div`
  margin-top: 32px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const ArticleCard = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;

  // Optional: ensure it doesn't shrink below min-width
  flex-shrink: 0;
`;

export const ArticleImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
  max-width: 50%;
`;

export const ArticleContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;

  @media (max-width: 767.98px) {
    padding: 8px;
  }
`;

export const ArticleTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const ArticleTitle = styled.h3`
  color: ${colorPalette.textMain};
  margin: 4px 0;
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXXSMain};
  }
`;

export const ArticleSubtitle = styled.p`
  color: ${colorPalette.textDark};
  margin: 0;
  ${typeScale.bodyXS}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;

export const ArticleAdditionalText = styled.p`
  color: ${colorPalette.textDark};
  margin: 0;
  ${typeScale.bodyXXS}
`;
