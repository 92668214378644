import HandoverChecklistImage from "../assets/images/articles/handover-checklist.png";
import PropertyManagementImage from "../assets/images/articles/property-management.png";
import InheritancePlanningImage from "../assets/images/articles/inheritance-planning.png";

export interface Article {
  id: number;
  image: string;
  title: string;
  description: string;
}

export const ARTICLES: Article[] = [
  {
    id: 1,
    image: HandoverChecklistImage,
    title: "Handover-Checkliste",
    description: "Die Schritte für ein erfolgreiches Handover.",
  },
  {
    id: 2,
    image: PropertyManagementImage,
    title: "Mieterverwaltung",
    description: "Passives Einkommen durch verwaltete Immobilien.",
  },
  {
    id: 3,
    image: InheritancePlanningImage,
    title: "Nachlassplanung",
    description: "Sicheres Vererben für Immobilienbesitzer in den VAE.",
  },
];
