import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";

export interface IConstructionProgressItem {
  id: number;
  published_date: string;
  description: string;
  percentage: number;
  created_at: string;
  document: IDocument | null;
}

export const downloadConstructionProgressDocument = async (
  item_id: number
): Promise<Blob | null> => {
  const response = await fetch(
    `${API_URL}/construction-progress/${item_id}/download`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};
