import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useInvestorDetailStore from '../stores/investorDetailStore';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const location = useLocation();

    // Stores
    const { investor } = useInvestorDetailStore();

    if (!investor) {
        return <Navigate to="/login" replace />;
    }

    if (investor.onboarding_step < 4 && location.pathname !== "/onboarding") {
        return <Navigate to="/onboarding" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
