import React, { useCallback } from 'react';
import {
    SectionContainer,
    Container,
    Title,
    Subtitle,
    UnitItem,
    UnitDetails,
    ToggleWrapper,
    NoManagementOption,
    PropertyTitle,
    UnitTitle,
    CTAButtonContainer,
    UnitRow,
} from './unit-chooser-section.styles';
import Checkbox from '../../../../components/checkbox/checkbox';
import Switch from '../../../../components/switch-new/switch-new';
import CTAButton from '../../../../components/cta-button/cta-button';

interface Unit {
    id: string;
    name: string;
    project: string;
    selected: boolean;
    selfUse?: boolean;
}

interface UnitChooserSectionProps {
    noManagement: boolean;
    units: Unit[];
    onNoManagementToggle: (checked: boolean) => void;
    onUnitToggle: (updatedUnits: Unit[]) => void;
    onContinue: () => void;
}

const UnitChooserSection: React.FC<UnitChooserSectionProps> = ({
    noManagement,
    units,
    onNoManagementToggle,
    onUnitToggle,
    onContinue,
}) => {

    // Helper function to toggle the "selected" state
    const handleUnitSelectionToggle = useCallback((id: string, selected: boolean) => {
        const updatedUnits = units.map(unit =>
            unit.id === id ? { ...unit, selected } : unit
        );
        onUnitToggle(updatedUnits);
    }, [units, onUnitToggle]);

    // Helper function to toggle the "selfUse" state
    const handleSelfUseToggle = useCallback((id: string, selfUse: boolean) => {
        const updatedUnits = units.map(unit =>
            unit.id === id ? { ...unit, selfUse } : unit
        );
        onUnitToggle(updatedUnits);
    }, [units, onUnitToggle]);

    // Toggles no management and deselects all units
    const handleNoManagementToggle = useCallback((checked: boolean) => {
        onNoManagementToggle(checked);
        if (checked) {
            const updatedUnits = units.map(unit => ({
                ...unit,
                selected: false,
                selfUse: false,
            }));
            onUnitToggle(updatedUnits);
        }
    }, [units, onNoManagementToggle, onUnitToggle]);

    return (
        <SectionContainer>
            <Container>
                <Title>Wähle zunächst eine Unit aus</Title>
                <Subtitle>
                    Die folgenden Units aus deinem Portfolio sind zum Handover bereit. Wähle aus, für welche der Units du die Mietverwaltung in Anspruch nehmen möchtest.
                </Subtitle>

                {units.map(({ id, name, project, selected, selfUse }) => (
                    <UnitItem
                        key={id}
                        disabled={noManagement}
                        onClick={() => !noManagement && handleUnitSelectionToggle(id, !selected)}
                    >
                        <UnitRow>
                            <Checkbox
                                checked={selected}
                                onChange={(checked: boolean) => !noManagement && handleUnitSelectionToggle(id, checked)}
                                disabled={noManagement}
                            />
                            <UnitDetails>
                                <UnitTitle>{name}</UnitTitle>
                                <PropertyTitle>{project}</PropertyTitle>
                            </UnitDetails>
                        </UnitRow>
                        <ToggleWrapper>
                            <span>Ich nutze die Wohnung selbst</span>
                            <Switch
                                checked={selfUse!}
                                onChange={(checked: boolean) => !noManagement && handleSelfUseToggle(id, checked)}
                                disabled={noManagement}
                            />
                        </ToggleWrapper>
                    </UnitItem>
                ))}

                <NoManagementOption onClick={() => handleNoManagementToggle(!noManagement)}>
                    <Checkbox
                        checked={noManagement}
                        onChange={handleNoManagementToggle}
                    />
                    <span>Ich möchte keine Mietverwaltung</span>
                </NoManagementOption>

                <CTAButtonContainer>
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Weiter"
                        onClick={onContinue}
                        fullWidth
                    />
                </CTAButtonContainer>
            </Container>
        </SectionContainer>
    );
};

export default UnitChooserSection;
