import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  padding: 12px 16px;
`;

export const CardContainer = styled.div`
  border-radius: 8px;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
`;

export const Title = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 14px;
  border-bottom: 1px solid ${colorPalette.blue200};
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  gap: 8px;

  .text-label {
    color: ${colorPalette.textMain};
    ${typeScale.bodySMMain};
  }
`;

export const Value = styled.div`
  color: ${colorPalette.textMain};
  ${typeScale.bodyMD};
`;

export const LinkRow = styled.div`
  display: flex;
  padding: 16px;
  gap: 4px;
`;

export const InfoLink = styled.a`
  text-decoration: none;
  color: ${colorPalette.blue500};
  ${typeScale.bodySM}
`;

export const Icon = styled.span`
  margin-right: 8px;
  font-size: 1.2rem;
  color: #6b7280;
`;
