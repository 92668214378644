import { Col, Row } from 'react-bootstrap';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';
import useInvestorDetailStore from '../../../../stores/investorDetailStore';
import { ButtonContainer, CardContainer, ItemContainer, Label, StyledContainer, Subtitle, Title, Value } from './review-address.styles';
import { useEffect, useState } from 'react';
import EditContactDetailsModal from '../../../../modals/edit-contact-details-modal/edit-contact-details-modal';

interface ReviewAddressProps {
    isLoading: boolean;
    onClickNext: () => void;
}

const ReviewAddress: React.FC<ReviewAddressProps> = ({ isLoading, onClickNext }) => {
    const isMobile = useIsMobile();

    // Stores
    const investor = useInvestorDetailStore(state => state.investor);

    // States
    const [isEditContactModalVisible, setIsEditContactModalVisible] = useState(false);

    if (!investor) return null;

    return (
        <>
            <StyledContainer disableScroll={isEditContactModalVisible}>
                <Row className="justify-content-center" style={{ marginTop: 32 }}>
                    <Col xs={12} lg={8}>
                        <Title>Überprüfe deine Daten</Title>
                        <Subtitle>Bitte überprüfe, ob deine Daten, die du beim Kauf deiner Wohnung angegeben hast, noch korrekt sind. Dies ist notwendig, um gesetzliche Regularien in den VAE einzuhalten.</Subtitle>
                        <div style={{ marginTop: 12 }}>
                            <CTAButton
                                variant="ghost"
                                size={isMobile ? "M" : "L"}
                                label="Wie werden meine Daten verwendet?"
                                onClick={() => alert("Coming soon...")}
                                noPadding
                            />
                        </div>

                        <CardContainer>
                            <ItemContainer>
                                <Label>Vollständiger Name</Label>
                                <Value>{investor?.first_name} {investor?.last_name}</Value>
                            </ItemContainer>
                            <ItemContainer>
                                <Label>Geburtsdatum</Label>
                                <Value>{investor?.birth_date}</Value>
                            </ItemContainer>
                            <ItemContainer>
                                <Label>E-Mail</Label>
                                <Value>{investor?.email}</Value>
                            </ItemContainer>
                            <ItemContainer>
                                <Label>Telefon</Label>
                                <Value>{investor?.phone_number}</Value>
                            </ItemContainer>
                            <ItemContainer>
                                <Label>Adresse</Label>
                                <Value>{investor?.address.street_address1} {investor?.address.street_address2 ? `, ${investor.address.street_address2}` : ""}</Value>
                            </ItemContainer>
                            <ItemContainer>
                                <Label>Stadt</Label>
                                <Value>
                                    {investor.address.postal_code ? `${investor.address.postal_code}, ` : ""}
                                    {investor.address.city}
                                </Value>
                            </ItemContainer>
                            {investor?.address.state_province_region && (
                                <ItemContainer>
                                    <Label>Provinz</Label>
                                    <Value>{investor?.address.state_province_region} {investor?.address.country ? `` : ""}</Value>
                                </ItemContainer>
                            )}
                            <ItemContainer>
                                <Label>Land</Label>
                                <Value>{investor?.address.country ?? "-"}</Value>
                            </ItemContainer>
                        </CardContainer>

                        <ButtonContainer>
                            <CTAButton
                                variant="primary"
                                size={isMobile ? "M" : "L"}
                                label="Daten bestätigen"
                                onClick={onClickNext}
                                loading={isLoading}
                                fullWidth
                            />
                            {!isLoading && (
                                <CTAButton
                                    variant="secondary"
                                    size={isMobile ? "M" : "L"}
                                    label="Bearbeiten"
                                    onClick={() => setIsEditContactModalVisible(true)}
                                    fullWidth
                                />
                            )}
                        </ButtonContainer>
                    </Col>
                </Row>
            </StyledContainer>

            {/* EDIT CONTACT & ADDRESS MODAL */}
            {isEditContactModalVisible && (
                <EditContactDetailsModal
                    onClose={() => setIsEditContactModalVisible(false)}
                />
            )}
        </>
    );
};

export default ReviewAddress;
