import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    SelectContainer,
    Label,
    SelectWrapper,
    FormSelect,
    InputField,
    IconContainer,
    DropdownIconContainer,
    DropdownMenu,
    DropdownItem,
    SubtitleText,
    ErrorText
} from './custom-select-field.styles';
import { colorPalette } from '../../utils/theme';
import MaterialIcon from '../material-icon';

export interface CustomSelectFieldOptions {
    label: string;
    value: string;
}

interface CustomSelectFieldProps {
    className?: string;
    id: string;
    value?: string | undefined;
    onChange: (value: string) => void;
    options: CustomSelectFieldOptions[];
    required?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    placeholder?: string;
    subtitle?: string;
    onSearch?: (searchQuery: string, page: number) => void | undefined;
    isSearchable?: boolean;
    isEndReached?: boolean;
    showClearIcon?: boolean;
}

const CustomSelectField: React.FC<CustomSelectFieldProps> = ({
    className,
    id,
    value,
    onChange,
    options,
    required = false,
    disabled = false,
    errorMessage,
    placeholder = 'Search...',
    subtitle,
    onSearch,
    isSearchable = false,
    isEndReached = false,
    showClearIcon = false
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [page, setPage] = useState(1);
    const inputRef = useRef<HTMLInputElement>(null);
    const ignoreSearchEffect = useRef(false);

    useEffect(() => {
        if (onSearch && !ignoreSearchEffect.current) {
            onSearch(searchQuery, page);
        } else {
            ignoreSearchEffect.current = false;
        }
    }, [searchQuery, page, onSearch]);

    const handleOptionClick = (value: string, label: string) => {
        onChange(value);
        ignoreSearchEffect.current = true;
        setSearchQuery(label);
        setPage(1);
        setIsDropdownVisible(false);
    };

    const handleInputFocus = () => {
        setIsDropdownVisible(true);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsDropdownVisible(false);
        }, 100);
    };

    const handleScroll = useCallback(
        (e: React.UIEvent<HTMLUListElement>) => {
            const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
            if (bottom && !isEndReached) {
                setPage((prevPage) => prevPage + 1);
            }
        },
        [isEndReached]
    );

    const clearSelection = () => {
        onChange('');
        setSearchQuery('');
    };

    return (
        <SelectContainer className={className}>
            <SelectWrapper>
                <div className="form-floating">
                    {isSearchable ? (
                        <InputField
                            id={id}
                            type="text"
                            className={searchQuery.length > 0 ? "form-control" : undefined}
                            ref={inputRef}
                            value={searchQuery}
                            onChange={(e) => {
                                setPage(1);
                                setSearchQuery(e.target.value);
                            }}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            placeholder={placeholder}
                            disabled={disabled}
                            isError={!!errorMessage}
                        />
                    ) : (
                        <FormSelect
                            id={id}
                            className="form-select"
                            value={value ?? ''}
                            onChange={(e) => onChange(e.target.value)}
                            required={required}
                            disabled={disabled}
                            isError={!!errorMessage}
                        >
                            <option value="" disabled hidden>
                                {placeholder}
                            </option>
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </FormSelect>
                    )}
                    <Label htmlFor={id}>{placeholder} {required ? "*" : ""}</Label>
                </div>
                {showClearIcon && value && !disabled && (
                    <IconContainer>
                        <MaterialIcon
                            icon="close"
                            size={20}
                            color={colorPalette.textDark}
                            onClick={clearSelection}
                        />
                    </IconContainer>
                )}
                <DropdownIconContainer showClearIcon={showClearIcon && !disabled && value !== undefined && value.length > 0}>
                    <MaterialIcon
                        icon="keyboard_arrow_down"
                        size={24}
                        color={colorPalette.textDark}
                    />
                </DropdownIconContainer>
                {isDropdownVisible && isSearchable && (
                    <DropdownMenu onScroll={handleScroll}>
                        {options.map((option) => (
                            <DropdownItem
                                key={option.value}
                                onMouseDown={() => handleOptionClick(option.value, option.label)}
                            >
                                {option.label}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                )}
                {subtitle && !errorMessage && <SubtitleText>{subtitle}</SubtitleText>}
                {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            </SelectWrapper>
        </SelectContainer>
    );
};

export default CustomSelectField;
