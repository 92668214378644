import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const DocumentRow = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "showCategory",
})<{ showCategory: boolean }>`
  display: grid;
  grid-template-columns: ${({ showCategory }) =>
    showCategory ? "40px 2fr 1fr 1fr auto" : "40px 2fr 1fr auto"};
  align-items: center;
  padding: 24px 16px;
  border-bottom: 1px solid #f1f0ed;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  justify-content: ${({ showCategory }) =>
    !showCategory ? "space-between" : "initial"};
`;

export const DocumentRowMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  border-bottom: 1px solid #f1f0ed;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const DocumentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.blue50};
  border-radius: 4px;
  width: 32px;
  height: 32px;
`;

export const DocumentName = styled.p`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyMDMain}
`;

export const DocumentDate = styled.p`
  margin: 0;
  text-align: left;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
`;

export const DocumentCategory = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
  text-align: left;
`;

export const ActionIcon = styled.div`
  color: ${colorPalette.blue500};
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${colorPalette.blue700};
  }
`;
