import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 48px;
  padding: 0 16px;
`;

export const HeaderContainer = styled.div`
  margin-top: 48px;
`;

export const Title = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};
`;

export const CardContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${colorPalette.blue200};
  height: 100%;
`;

export const ImageContainer = styled.div`
  width: 50%;
  max-height: 370px; /* Constrain the image container to the card's height */
  display: flex;
  align-items: center;
`;

export const ProjectImage = styled.img`
  width: 100%;
  height: 100%; /* Maintain aspect ratio */
  object-fit: cover;
`;

export const InfoContainer = styled.div`
  width: 100%;
  padding: 16px 0 16px 24px;
`;

export const ProjectTitle = styled.h2`
  margin-bottom: 4px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};
`;

export const ProjectLocation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;

export const ProjectDetails = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const DetailLabel = styled.div`
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};
`;

export const DetailValue = styled.div`
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
  font-weight: 600;
`;

export const ViewDetailsLink = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${colorPalette.blue500};
  text-decoration: none;
  ${typeScale.bodySM};

  &:hover {
    text-decoration: underline;
  }
`;
