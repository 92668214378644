import React, { useMemo } from 'react';
import { ActionButton, Card, Container, InitialsAvatar, MessageSection, MessageText, Name, ProfileImage, ProfileSection, StatusBadge, Subtext } from './header-status.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { useNavigate } from 'react-router-dom';

interface HeaderStatusProps {
    name: string;
    avatar?: string | undefined;
    investorSince: string;
    status: 'active' | 'inactive';
    message?: string;
    buttonText?: string;
}

const HeaderStatus: React.FC<HeaderStatusProps> = ({ name, avatar, investorSince, status, message, buttonText }) => {
    const navigate = useNavigate();

    // Memos
    const initials = useMemo(() => {
        const names = name.split(' ');
        return names.slice(0, 2).map(name => name[0].toUpperCase()).join('');
    }, [name]);

    return (
        <Container>
            <Card status={status}>
                <ProfileSection>
                    <div className="d-flex align-items-center" style={{ gap: 16 }}>
                        {avatar ? (
                            <ProfileImage src={avatar} alt={`${name}'s profile avatar`} />
                        ) : (
                            <InitialsAvatar isActive={status === "active"}>{initials}</InitialsAvatar>
                        )}
                        <div>
                            <Name>{name}</Name>
                            <Subtext>Investor seit {new Date(investorSince).toLocaleDateString()}</Subtext>
                        </div>
                    </div>
                    <StatusBadge status={status}>{status === 'active' ? 'Aktiv' : 'Inaktiv'}</StatusBadge>
                </ProfileSection>
                <MessageSection>
                    {status === "active" ? (
                        <>
                            <MaterialIcon icon="check_circle" size={20} color={colorPalette.white} />
                            <MessageText>Alle Dokumente sind aktuell</MessageText>
                        </>
                    ) : (
                        <>
                            {message && (
                                <>
                                    <MaterialIcon icon="emergency_home" size={20} color={colorPalette.white} />
                                    <MessageText>{message}</MessageText>
                                </>
                            )}
                            {buttonText && (
                                <ActionButton className="d-none d-md-inline" onClick={() => navigate("/profile/documents")}>{buttonText}</ActionButton>
                            )}
                        </>
                    )}
                </MessageSection>
            </Card>
        </Container>
    );
};

export default HeaderStatus;
