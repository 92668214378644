import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";
import { Row } from "react-bootstrap";

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;

  @media (max-width: 767.98px) {
    padding: 16px 16px;
    border-bottom: 1px solid ${colorPalette.blue200};
  }
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const FormSection = styled(Row)`
  padding-left: 16px;
  padding-right: 16px;
`;

/* Card */
export const CardTitle = styled.h4`
  margin-top: 16px;
  margin-bottom: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}
`;
