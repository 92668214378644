import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS};

  .form-floating > .form-control:disabled ~ & {
    background-color: transparent;
  }

  .form-floating > .form-control:disabled ~ &::after {
    background-color: transparent;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Input = styled.input.withConfig({
  shouldForwardProp: (prop) => prop !== "isError",
})<{ isError: boolean }>`
  width: 100%;
  padding: 1rem 0.75rem;
  border: 1px solid
    ${({ isError }) =>
      isError ? colorPalette.errorDark : colorPalette.blue300};
  border-radius: 4px;
  transition: border-color 0.3s ease;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};

  &:focus {
    border-color: ${({ isError }) =>
      isError ? colorPalette.errorDark : colorPalette.blue400};
    outline: none;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: ${colorPalette.blue50};
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${colorPalette.textMain};
    ${typeScale.bodyMD};
  }
`;

export const TextArea = styled.textarea.withConfig({
  shouldForwardProp: (prop) => prop !== "isError",
})<{ isError: boolean }>`
  width: 100%;
  padding: 1rem 0.75rem;
  border: 1px solid
    ${({ isError }) =>
      isError ? colorPalette.errorDark : colorPalette.blue300};
  border-radius: 4px;
  resize: none;
  min-height: 150px;
  transition: border-color 0.3s ease;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMD};

  &:focus {
    border-color: ${({ isError }) =>
      isError ? colorPalette.errorDark : colorPalette.blue400};
    outline: none;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: ${colorPalette.blue50};
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${colorPalette.textMain};
    ${typeScale.bodyMDMain};
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colorPalette.blue50};

  svg {
    cursor: pointer;
    font-size: 18px;

    &:hover {
      color: ${colorPalette.primaryDark};
    }
  }
`;

export const ErrorText = styled.small`
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: 0;
  color: ${colorPalette.errorDark};
  ${typeScale.bodyXS};
`;

export const SubtitleText = styled.p`
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: 0;
  max-width: 100%;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;
