import React, { createContext, useContext, useRef } from 'react';

interface ContentAreaContextProps {
    contentAreaRef: React.RefObject<HTMLDivElement>;
}

const ContentAreaContext = createContext<ContentAreaContextProps | undefined>(undefined);

export const ContentAreaProvider: React.FC<{ children: React.ReactNode; contentAreaRef: React.RefObject<HTMLDivElement> }> = ({ children, contentAreaRef }) => {
    return (
        <ContentAreaContext.Provider value={{ contentAreaRef }}>
            {children}
        </ContentAreaContext.Provider>
    );
};

export const useContentArea = () => {
    const context = useContext(ContentAreaContext);
    if (!context) {
        throw new Error('useContentArea must be used within a ContentAreaProvider');
    }
    return context;
};
