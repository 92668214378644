import React from 'react';
import {
    SectionContainer,
    Title,
    Subtitle,
    ServiceGrid,
    ServiceCard,
    ServiceIcon,
    ServiceTitle,
    ServiceDescription,
    ServiceCardHeader,
} from './services-section.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';

interface Service {
    title: string;
    description: string;
}

const SERVICES = [
    {
        title: 'Unterstützung beim Handover',
        description: 'Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht.',
    },
    {
        title: 'Vermarktung und Vermietung',
        description: 'Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht.',
    },
    {
        title: 'Mietermanagement',
        description: 'Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht.',
    },
    {
        title: 'Verwaltung der Mieteinnahmen',
        description: 'Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht.',
    },
    {
        title: 'Inspektionen und Instandhaltung',
        description: 'Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht.',
    },
    {
        title: 'Vermieterportal mit Echtzeit-Reporting',
        description: 'Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht.',
    },
] as Service[];

const ServicesSection: React.FC = () => {
    return (
        <SectionContainer id="services">
            <Title>Diese Services können wir für dich übernehmen:</Title>
            <Subtitle>
                Die Mieterverwaltung mit managd ist simpel. Sobald du dich für ein Management-Paket entscheidest, kümmern wir uns um den Rest.
            </Subtitle>
            <ServiceGrid>
                {SERVICES.map((service, index) => (
                    <ServiceCard key={index} className="p-3">
                        <ServiceCardHeader>
                            <ServiceTitle>{service.title}</ServiceTitle>
                            <MaterialIcon icon="house" size={64} color={colorPalette.blue400} />
                        </ServiceCardHeader>
                        <ServiceDescription>{service.description}</ServiceDescription>
                    </ServiceCard>
                ))}
            </ServiceGrid>
        </SectionContainer>
    );
};

export default ServicesSection;
