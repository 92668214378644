import React, { useState } from 'react';
import {
    SectionContainer,
    Title,
    Subtitle,
    PackageCard,
    PackageTitle,
    PriceInfo,
    FeatureList,
    FeatureItem,
    Disclaimer,
    PackageHeader,
    PackageIcon,
    TitleContainer,
    PackageSubtitle,
    PackageFooter,
    PriceInfoDetail,
    FeatureItemTitle,
    ClickableLink,
    InfoText,
    TooltipIcon,
    PriceAmount,
    PriceText,
    IconWrapper,
    ViewMoreLink,
} from './package-selection-section.styles';
import { Col, Row } from 'react-bootstrap';
import LogoIconWhite from '../../../../assets/icons/logo-icon-white.png';
import LogoIconDark from '../../../../assets/icons/logo-icon-dark.png';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { formatMoney } from '../../../../utils/helpers';
import PricingModal from '../../../../modals/pricing-modal/pricing-modal';
import CTAButton from '../../../../components/cta-button/cta-button';

interface PackageProps {
    title: string;
    price: number;
    minFee: number;
    features: string[];
    onSelect: () => void;
    onClickInfo: () => void;
    isPremium: boolean;
}

const PackageCardComponent: React.FC<PackageProps> = ({
    title,
    price,
    minFee,
    features,
    onSelect,
    onClickInfo,
    isPremium,
}) => (
    <PackageCard>
        <PackageHeader premium={isPremium}>
            <TitleContainer>
                <PackageIcon src={isPremium ? LogoIconWhite : LogoIconDark} alt="logo" />
                <PackageTitle>{title}</PackageTitle>
            </TitleContainer>
            <PackageSubtitle premium={isPremium}>Die Premiumlösung für Investoren, die</PackageSubtitle>
            <PriceInfo>
                <PriceAmount>{price} %</PriceAmount> <PriceText>der Jahresmiete</PriceText>
                <TooltipIcon onClick={onClickInfo}>
                    <MaterialIcon icon="info" color={isPremium ? colorPalette.blue600 : colorPalette.blue200} />
                </TooltipIcon>
            </PriceInfo>
            <PriceInfoDetail premium={isPremium}>Mindestens AED {formatMoney(minFee)}</PriceInfoDetail>
            <PriceInfoDetail premium={isPremium}>
                zzgl. einmaliger <u>Handover Fee und Kautionen</u> • Jährliche Abrechnung
            </PriceInfoDetail>
        </PackageHeader>
        <FeatureList>
            {features.map((feature, index) => (
                <FeatureItem key={index}>
                    <div className="d-flex align-items-center gap-1">
                        <IconWrapper premium={isPremium}>
                            <MaterialIcon icon="check" size={20} color={colorPalette.white} />
                        </IconWrapper>
                        <FeatureItemTitle>{feature}</FeatureItemTitle>
                    </div>
                </FeatureItem>
            ))}
        </FeatureList>
        <ViewMoreLink href="#">
            Alle Leistungen ansehen
        </ViewMoreLink>
        <PackageFooter>
            <CTAButton
                variant={isPremium ? "primary" : "secondary"}
                size="L"
                onClick={onSelect}
                label={isPremium ? 'Premium Auswählen' : 'Basic Auswählen'}
                fullWidth
            />
        </PackageFooter>
    </PackageCard>
);

const PackageSelectionSection: React.FC = () => {
    // States
    const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);

    const handleSelectBasic = () => {
        alert('Basic Package selected');
    };

    const handleSelectPremium = () => {
        alert('Premium Package selected');
    };

    return (
        <>
            <SectionContainer>
                <Title>Wähle eines unserer Property Management Pakete:</Title>
                <Subtitle>
                    Die Mietverwaltung mit managd ist simpel. Sobald du dich für ein Management-Paket entscheidest, kümmern wir uns um den Rest.
                </Subtitle>
                <Row className="justify-content-center">
                    <Col sm={12} md={6} xxl={4}>
                        <PackageCardComponent
                            title="Basic"
                            price={5}
                            minFee={3500}
                            features={[
                                'Handover-Support',
                                'Vermarktung und Vermietung',
                                'Mietermanagement',
                                'Wartung und Instandhaltung',
                                'Vermieterportal',
                            ]}
                            onSelect={handleSelectBasic}
                            onClickInfo={() => setIsPricingModalVisible(true)}
                            isPremium={false}
                        />
                    </Col>
                    <Col sm={12} md={6} xxl={4}>
                        <PackageCardComponent
                            title="Premium"
                            price={7}
                            minFee={4000}
                            features={[
                                'Handover-Support',
                                'Vermarktung und Vermietung',
                                'Mietermanagement',
                                'Wartung und Instandhaltung',
                                'Vermieterportal',
                                'Cash-Handling',
                                'Service-Charge Management',
                            ]}
                            onSelect={handleSelectPremium}
                            onClickInfo={() => setIsPricingModalVisible(true)}
                            isPremium={true}
                        />
                    </Col>
                </Row>
                <InfoText>
                    Um mehr über Pakete für Investoren mit Erstwohnsitz in den VAE zu erfahren,{' '}
                    <ClickableLink href="#">kontaktiere uns direkt</ClickableLink>.
                </InfoText>
                <Disclaimer>Zu diesem Zeitpunkt wird dir noch nichts berechnet. Du kannst bequem per Rechnung bezahlen, sobald deine Anmeldung abgeschlossen ist.</Disclaimer>
            </SectionContainer>

            {isPricingModalVisible && (
                <PricingModal
                    type="property-management"
                    onClose={() => setIsPricingModalVisible(false)}
                />
            )}
        </>
    );
};

export default PackageSelectionSection;
