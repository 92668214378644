import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const HeaderContainer = styled.div`
  background-color: ${colorPalette.blue50};
  padding: 64px 180px;
  display: flex;
  gap: 10px;
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Title = styled.h1`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingL}
`;

export const Description = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain}
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BuildingImage = styled.img`
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  object-fit: cover;
`;

export const CTAButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  min-width: 200px;
`;
