import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain}
  }
`;

export const ProgressContainer = styled.div`
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const ProgressBar = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
`;

export const ProgressSegment = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "filled" && prop !== "partial",
})<{ filled: boolean; partial: number }>`
  flex: 1;
  height: 8px;
  background-color: ${({ filled }) =>
    filled ? colorPalette.blue400 : colorPalette.blue100};
  background: ${({ filled, partial }) =>
    filled
      ? colorPalette.blue400
      : partial > 0
      ? `linear-gradient(to right, ${colorPalette.primaryDark} ${
          partial * 100
        }%, ${colorPalette.blue100} ${partial * 100}%)`
      : colorPalette.blue100};
  border-radius: 4px;

  @media (max-width: 767.98px) {
    height: 5px;
  }
`;

export const ProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ProgressPercentage = styled.p`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyXLMain}
`;

export const ProgressStatus = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  ${typeScale.bodyXLMain}

  .current-amount {
    color: ${colorPalette.primaryDark};
  }

  .divider {
    color: ${colorPalette.primaryDark};
  }

  .total-amount {
    color: ${colorPalette.textDark};
  }

  @media (max-width: 767.98px) {
    ${typeScale.bodySM}
  }
`;

export const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  border-top: 1px solid ${colorPalette.blue100};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const DetailLabel = styled.p`
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
  margin: 0;
`;

export const DetailValue = styled.p`
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain}
  margin: 0;
`;

export const LinkIcon = styled.div`
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
`;
