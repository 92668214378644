import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const FAQSectionContainer = styled.section`
  width: 100%;
  margin-top: 24px;

  @media (max-width: 767.98px) {
    margin-top: 16px;
  }
`;

export const FAQHeader = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  border-radius: 8px;
  background-color: ${colorPalette.blue50};
  border: 1px solid ${colorPalette.blue200};
  border-bottom-left-radius: ${({ expanded }) => (expanded ? "0" : "8px")};
  border-bottom-right-radius: ${({ expanded }) => (expanded ? "0" : "8px")};
`;

export const HeaderContent = styled.div`
  width: 100%;

  h2 {
    margin-bottom: 4px;
    color: ${colorPalette.textMain};
    ${typeScale.headingMain};

    @media (max-width: 767.98px) {
      ${typeScale.headingXSMain};
    }
  }

  p {
    margin: 0;
    color: ${colorPalette.textDark};
    ${typeScale.bodySM};

    @media (max-width: 767.98px) {
      ${typeScale.bodyXS};
    }
  }
`;

export const HeaderIconWrapper = styled.div`
  margin-bottom: 16px;

  @media (max-width: 767.98px) {
    margin-bottom: 8px;
  }
`;

export const CollapseIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${colorPalette.blue200};
`;

export const FAQList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  @media (max-width: 767.98px) {
    padding-bottom: 0px;
  }
`;

export const FAQItem = styled.div`
  background-color: ${colorPalette.white};
  border-left: 1px solid ${colorPalette.blue100};
  border-right: 1px solid ${colorPalette.blue100};
  padding: 24px 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  @media (max-width: 767.98px) {
    padding: 16px;
  }

  &:hover {
    background-color: ${colorPalette.blue50};
  }

  &:first-child {
    border-top: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &:last-child {
    border-bottom: 1px solid ${colorPalette.blue100};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:not(:first-child) {
    border-top: none;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colorPalette.blue100};
  }
`;

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colorPalette.textMain};
  ${typeScale.bodyLGMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodySMMain};
  }
`;

export const IconWrapper = styled.span`
  font-size: 18px;
  color: ${colorPalette.blue500};
`;

export const Answer = styled.p`
  margin-top: 12px;
  margin-bottom: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;
