import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

// Container for the entire card + banner
export const Container = styled.div`
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 100%;
  min-height: 200px;

  &:hover {
    transform: scale(1.01);
  }
`;

// Container for the entire card
export const UnitCardContainer = styled.div`
  display: flex;
  background-color: ${colorPalette.white};
  overflow: hidden;
  position: relative;
  height: 100%;

  @media (max-width: 767.98px) {
    display: block;
  }
`;

// Image container for the unit image
export const ImageContainer = styled.div`
  width: 320px;
  height: 100%;
  margin-right: 16px;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 767.98px) {
    width: 100%;
    height: auto;
  }
`;

// Content of the unit card
export const UnitContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  height: 100%;

  @media (max-width: 767.98px) {
    padding-right: 0;
  }
`;

// Header for the title, tags, and subtitle
export const UnitHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;

  @media (max-width: 767.98px) {
    margin-top: 0;
    padding: 16px;
  }
`;

// New container for title and tags (they share one row)
export const TitleTagsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  @media (max-width: 767.98px) {
    margin-bottom: 4px;
  }
`;

// Title of the unit
export const UnitTitle = styled.h3`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

// Tags container for unit status and package
export const UnitTagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

// Subtitle container with location icon
export const SubtitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

// Subtitle for the property name
export const UnitSubtitle = styled.h4`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS}
  }
`;

// Details section
export const UnitDetails = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767.98px) {
    margin-top: 0;
  }
`;

// Layout for the statistics
export const UnitStatistics = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "showBottomBorder",
})<{ showBottomBorder: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 8px;
  border-width: 0px 0px
  ${({ showBottomBorder }) => (showBottomBorder ? "1px" : "0")} 0px;
  border-style: solid;
  border-color: ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    margin-top: 0;
    padding: 0px 16px 16px;
  }
`;

// Individual statistic item
export const UnitStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodyXS}
  color: ${colorPalette.textMain};
`;

// Label for the statistics
export const UnitStatisticLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain}
`;

// Value of the statistic
export const UnitNumber = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.bodyLGMain}
  }
`;

// Estimated rent section styling
export const EstimatedAnnualRentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EstimatedAnnualRent = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.bodyLGMain}
  }
`;

export const EstimatedRoiPercentage = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.bodyLGMain}
  }
`;

// Tooltip icon styling
export const TooltipIcon = styled.span`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.white};

  &:hover {
    color: #ededed;
  }
`;

// Layout for the unit status
export const UnitStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 4px;

  @media (max-width: 767.98px) {
    padding: 12px 16px;
  }
`;

// Individual unit status item
export const UnitStatusItem = styled.div`
  gap: 4px;
`;

// Label for the unit status item
export const UnitStatusLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS}
  }
`;

/* CONSTRUCTION STYLES */
export const ProgressContainer = styled.div`
  width: 100%;
  padding: 16px 4px;

  @media (max-width: 767.98px) {
    padding: 12px 16px;
  }
`;

export const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ProgressTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProgressLabel = styled.span`
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodySMMain};
  }
`;

export const ProgressDescription = styled.span`
  color: ${colorPalette.textMain};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    ${typeScale.bodySM};
  }
`;

export const ProgressPercentage = styled.span`
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingXXSMain};
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colorPalette.blue100};
  border-radius: 4px;
`;

export const ProgressBar = styled.div`
  height: 100%;
  background-color: ${colorPalette.successMedium};
  border-radius: 4px;
`;
