// KnowledgeHubPage.tsx
import NewsUpdates from "../../components/news-updates/news-updates";
import Articles from "./components/articles/articles";
import FAQCategories from "./components/faq-categories/faq-categories";
import { StyledContainer, Title } from "./knowledge-hub.styles";

const KnowledgeHubPage = () => {
    return (
        <StyledContainer>
            <Title>Knowledge Hub</Title>

            <Articles />

            <FAQCategories />

            <NewsUpdates />
        </StyledContainer>
    );
};

export default KnowledgeHubPage;
