import React from 'react';
import {
    SidebarModalBackdrop,
    SidebarModal,
    SidebarModalHeadline,
    SidebarModalDivider,
    SidebarModalScrollableContent,
    SidebarModalSubtitle,
    HeadlineContainer,
    SidebarModalHeader
} from '../sidemodal.styles';
import { ILease } from '../../../services/leaseService';
import MaterialIcon from '../../../components/material-icon';
import { colorPalette } from '../../../utils/theme';
import LeaseCard from './components/lease-card';

interface ContractHistorySidebarModalProps {
    unit_number: string;
    property_name: string;
    bedroom: number;
    square_feet: number;
    leases: ILease[];
    isVisible: boolean;
    onClose: () => void;
}

const ContractHistorySidebarModal: React.FC<ContractHistorySidebarModalProps> = ({
    leases,
    unit_number,
    property_name,
    bedroom,
    square_feet,
    isVisible,
    onClose
}) => {
    return (
        <SidebarModalBackdrop visible={isVisible} onClick={onClose}>
            <SidebarModal visible={isVisible}>
                <SidebarModalHeader>
                    <HeadlineContainer>
                        <SidebarModalHeadline>Vorherige Mietverhältnisse</SidebarModalHeadline>
                        <SidebarModalSubtitle>{unit_number} • {property_name} • {bedroom} Bedroom • {square_feet} Sqft.</SidebarModalSubtitle>
                    </HeadlineContainer>
                    <MaterialIcon icon="close" size={24} color={colorPalette.blue500} onClick={onClose} />
                </SidebarModalHeader>
                <SidebarModalDivider />
                <SidebarModalScrollableContent>
                    <div className="row">
                        {leases.map((lease, index) => {
                            // Calculate rent change percentage based on the previous lease
                            const previousLease = leases[index - 1];
                            let rentChangePercentage = undefined;

                            if (index > 0 && previousLease) {
                                const previousRent = previousLease.total_rent_amount;
                                const currentRent = lease.total_rent_amount;

                                if (previousRent > 0) {
                                    rentChangePercentage = ((currentRent - previousRent) / previousRent) * 100;
                                }
                            }

                            return (
                                <div key={index} className="col-12">
                                    <LeaseCard
                                        lease_id={lease.lease_id}
                                        lease_document={lease.documents.length > 0 ? lease.documents[0] : undefined}
                                        name={`${lease.tenant.first_name} ${lease.tenant.last_name}`}
                                        startDate={lease.start_date}
                                        endDate={lease.end_date}
                                        rentAmount={lease.total_rent_amount}
                                        rentChangePercentage={rentChangePercentage}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </SidebarModalScrollableContent>
            </SidebarModal>
        </SidebarModalBackdrop>
    );
};

export default ContractHistorySidebarModal;
