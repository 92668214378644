import React, { useMemo } from 'react';
import { HeaderContainer, WelcomeMessage, HeaderTitle, Headline } from './header.styles';
import PortfolioChartCard from '../portfolio-chart-card/portfolio-chart-card';
import { Col, Row } from 'react-bootstrap';
import RentalPaymentsCard from '../rental-payments-card/rental-payments-card';
//import HandoverBanner from '../handover-banner/handover-banner';
import useInvestorDetailStore from '../../../../stores/investorDetailStore';
import MobileRentalPaymentsCard from '../mobile-rental-payments-card/mobile-rental-payments-card';
import MobileUnitsRentedCard from '../mobile-units-rented-card/mobile-units-rented-card';
import UnitsRentedStatisticCard from '../units-rented-statistic-card/units-rented-statistic-card';
import { IDashboard } from '../../../../services/dashboardService';
import useIsMobile from '../../../../hooks/useIsMobile';
import OnboardingChecklistCard from '../onboarding-checklist-card/onboarding-checklist-card';
import { DocumentType } from '../../../../services/documentService';
import EmptyRentalStatsCard from '../empty-rental-stats-card/empty-rental-stats-card';

interface HeaderProps {
    dashboard: IDashboard;
    totalRentableUnits: number;
    totalRentedUnits: number;
    grossYield: number;
}

const Header: React.FC<HeaderProps> = ({ dashboard, totalRentableUnits, totalRentedUnits, grossYield }) => {
    const isMobile = useIsMobile();

    // Stores
    const investor = useInvestorDetailStore(state => state.investor);

    let years: number[] = [];
    let volumeData: number[] = [];

    if (dashboard) {
        years = Object.keys(dashboard.purchase_values_by_year).map(Number);

        const firstYear = Math.min(...years);
        const startYear = firstYear - 1;

        const endYear = new Date().getFullYear();
        years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

        volumeData = years.map(year => dashboard.purchase_values_by_year[year] || 0);
        volumeData[years.indexOf(firstYear)] = dashboard.purchase_values_by_year[firstYear] || 0;
    }

    // Memos
    const hasAddress = useMemo(() => {
        if (!investor) return false;
        return investor.address.country !== null || investor.address.country !== undefined;
    }, [investor]);

    const hasMandatoryDocumentsUploaded = useMemo(() => {
        if (!investor) return false;
        return investor.documents.filter(document => document.document_type === DocumentType.PASSPORT || document.document_type === DocumentType.PROOF_OF_ADDRESS).length > 1
    }, [investor]);

    const hasBankaccount = useMemo(() => {
        if (!investor) return false;
        return investor.bank_details.length > 0;
    }, [investor]);

    return (
        <HeaderContainer>
            <WelcomeMessage>Hi, {investor?.first_name}</WelcomeMessage>
            <HeaderTitle>Willkommen zurück.</HeaderTitle>
            {/* 
            TODO: Handle Visibility
            <HandoverBanner />
            */}
            {!isMobile && (
                <Headline>Portfolioübersicht</Headline>
            )}
            <Row className={`d-flex align-items-stretch gy-3 ${isMobile ?? "gx-2"}`} style={{ marginTop: 0 }}>
                <Col sm={12} md={6} className="d-flex align-items-stretch">
                    <PortfolioChartCard
                        portfolioVolume={dashboard.portfolio_value}
                        grossYield={grossYield}
                        years={years}
                        volumeData={volumeData}
                    />
                </Col>

                {(investor && (!hasAddress || !hasBankaccount || !hasMandatoryDocumentsUploaded)) ? (
                    <Col sm={12} md={6} className="d-flex align-items-stretch">
                        <OnboardingChecklistCard
                            hasAddress={hasAddress}
                            hasDocumentsUploaded={hasMandatoryDocumentsUploaded}
                            hasBankaccount={hasBankaccount}
                        />
                    </Col>
                ) : (
                    <>
                        {totalRentedUnits === 0 ? (
                            <Col sm={12} md={6} className="d-flex align-items-stretch">
                                <EmptyRentalStatsCard />
                            </Col>
                        ) : (
                            <>
                                {isMobile ? (
                                    <>
                                        <Col xs={6} className="d-flex align-items-stretch">
                                            <MobileUnitsRentedCard
                                                totalUnits={totalRentableUnits}
                                                unitsRented={totalRentedUnits}
                                            />
                                        </Col>
                                        <Col xs={6} className="d-flex align-items-stretch">
                                            <MobileRentalPaymentsCard
                                                totalPayments={dashboard.total_payments}
                                                receivedPayments={dashboard.received_payments}
                                            />
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col sm={6} md={3} className="d-flex align-items-stretch">
                                            <UnitsRentedStatisticCard
                                                totalUnits={totalRentableUnits}
                                                unitsRented={totalRentedUnits}
                                            />
                                        </Col>
                                        <Col sm={6} md={3} className="d-flex align-items-stretch">
                                            <RentalPaymentsCard
                                                totalPayments={dashboard.total_payments}
                                                receivedPayments={dashboard.received_payments}
                                            />
                                        </Col>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Row>
        </HeaderContainer>
    );
};

export default Header;
