import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  height: inherit;
  position: relative;
  padding-top: 16px;
  background: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  text-align: center;
  color: ${colorPalette.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const Title = styled.h2`
  margin: 0;
  width: 60%;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    width: 100%;
    text-align: start;
    margin-bottom: 8px;
    ${typeScale.headingXSMain}
  }
`;

export const Description = styled.p`
  margin: 0;
  z-index: 1;
  opacity: 0.8;
  width: 70%;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS}

  @media (max-width: 767.98px) {
    width: 100%;
    text-align: start;
  }
`;

export const List = styled.div`
  width: 100%;
  border-top: 1px solid ${colorPalette.blue200};
  padding: 16px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;

  &:last-child {
    padding-bottom: 0;
  }

  @media (max-width: 767.98px) {
    padding: 12px 0;
    gap: 24px;
  }
`;

export const CheckIconContainer = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.blue300};
  flex-shrink: 0;

  @media (max-width: 767.98px) {
    background-color: ${colorPalette.blue200};
  }
`;

export const ListItemText = styled.p`
  margin: 0;
  text-align: start;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}

  @media (max-width: 767.98px) {
    margin-bottom: 4px;
  }
`;

export const ListItemDescription = styled.p`
  margin: 0;
  text-align: start;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  z-index: 1;
`;
