import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IDashboard } from "../services/dashboardService";

export const DASHBOARD_STORAGE_NAME = "dashboard-storage";

interface DashboardState {
  dashboard: IDashboard | null;
  isLoading: boolean;
  isInitialLoaded: boolean;
  setDashboard: (dashboard: IDashboard) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsInitalLoaded: (isInitialLoaded: boolean) => void;
  reset: () => void;
}

const useDashboardStore = create<DashboardState>()(
  devtools(
    persist(
      (set) => ({
        dashboard: null,
        isLoading: true,
        isInitialLoaded: false,
        setDashboard: (dashboard: IDashboard) => set({ dashboard }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setIsInitalLoaded: (isInitialLoaded: boolean) =>
          set({ isInitialLoaded }),
        reset: () => {
          set({
            dashboard: null,
            isLoading: true,
            isInitialLoaded: false,
          });
        },
      }),
      {
        name: DASHBOARD_STORAGE_NAME,
        getStorage: () => sessionStorage,
      }
    )
  )
);

export default useDashboardStore;
