import { ILease } from "../services/leaseService";

export const getCurrentFormattedDate = (): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  return new Date().toLocaleDateString("de-DE", options);
};

export const getFormattedDate = (datetime: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  return new Date(datetime).toLocaleDateString("de-DE", options);
};

export const formatDateTimeToDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("de-DE", { month: "long" });
  const year = date.getFullYear();

  return `${day}. ${month} ${year}`;
};

export const isYouTubeUrl = (url: string): boolean => {
  return (
    url.match(/(youtu.be\/|youtube.com\/(watch\?(.*&)?v=|(embed|v)\/))/) != null
  );
};

export const getYouTubeId = (url: string): string | null => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

export const formatMoney = (
  amount: number,
  showDecimals: boolean = true
): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: showDecimals ? 2 : 0,
    maximumFractionDigits: showDecimals ? 2 : 0,
  })
    .format(amount)
    .replace("$", "");
};

export const getActiveLease = (leases: ILease[]): ILease | null => {
  const currentDate = new Date();
  const activeLease = leases.find(
    (lease) =>
      new Date(lease.start_date) <= currentDate &&
      new Date(lease.end_date) >= currentDate
  );
  return activeLease ?? null;
};

export const getCompletedLeases = (leases: ILease[]): ILease[] => {
  const currentDate = new Date();
  return leases.filter((lease) => new Date(lease.end_date) < currentDate);
};
