import React, { useCallback } from 'react';
import {
    SectionListContainer,
    SectionItem,
    SectionText,
    ContentSpacing,
    SectionErrorText,
    SectionWarningText,
} from './settings-list.styles';
import { useNavigate } from 'react-router-dom';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import useInvestorDetailStore from '../../../../stores/investorDetailStore';

interface SettingsListProps {
    addressStored: boolean;
    documentsUploaded: boolean;
    documentExpired: boolean;
    bankingDetailsStored: boolean;
}

const SettingsList: React.FC<SettingsListProps> = ({ addressStored, documentsUploaded, documentExpired, bankingDetailsStored }) => {
    const navigate = useNavigate();

    // Stores
    const investor = useInvestorDetailStore(state => state.investor);

    const onClickBankDetails = useCallback(() => {
        if (investor && investor.bank_details.length > 0) {
            navigate("/profile/bank-details");
        } else {
            navigate("/profile/edit-bank-details");
        }
    }, [investor]);

    return (
        <SectionListContainer>
            <SectionItem onClick={() => navigate("/profile/address-details")}>
                <ContentSpacing>
                    <MaterialIcon icon="home" size={24} color={colorPalette.textMain} />
                    <SectionText>Adresse & Kontakt</SectionText>
                </ContentSpacing>
                <ContentSpacing>
                    {addressStored && (
                        <MaterialIcon icon="check_circle" size={30} color={colorPalette.successMedium} />
                    )}
                    <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                </ContentSpacing>
            </SectionItem>

            <SectionItem onClick={() => navigate("/profile/documents")}>
                <ContentSpacing>
                    <MaterialIcon icon="contact_page" size={24} color={colorPalette.textMain} />
                    <div>
                        <SectionText>Dokumente</SectionText>
                        {documentExpired ? (
                            <SectionErrorText>Bitte aktuallisiere deine Dokumente</SectionErrorText>
                        ) : !documentsUploaded && (
                            <SectionErrorText>Bite lade alle benötigten Dokumente hoch</SectionErrorText>
                        )}
                    </div>
                </ContentSpacing>
                <ContentSpacing>
                    <MaterialIcon
                        icon={documentExpired ? "emergency_home" : documentsUploaded ? "check_circle" : "emergency_home"}
                        size={30}
                        color={documentExpired || !documentsUploaded ? colorPalette.errorDark : colorPalette.successMedium}
                    />
                    <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                </ContentSpacing>
            </SectionItem>

            <SectionItem onClick={onClickBankDetails}>
                <ContentSpacing>
                    <MaterialIcon icon="account_balance" size={24} color={colorPalette.textMain} />
                    <div>
                        <SectionText>Bankverbindung</SectionText>
                        {!bankingDetailsStored && (
                            <SectionWarningText>Bitte füge eine Bankverbindung hinzu</SectionWarningText>
                        )}
                    </div>
                </ContentSpacing>
                <ContentSpacing>
                    {bankingDetailsStored && (
                        <MaterialIcon
                            icon="check_circle"
                            size={30}
                            color={colorPalette.successMedium}
                        />
                    )}
                    <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                </ContentSpacing>
            </SectionItem>

            <SectionItem onClick={() => navigate("/profile/settings")}>
                <ContentSpacing>
                    <MaterialIcon icon="manage_accounts" size={24} color={colorPalette.textMain} />
                    <SectionText>Accounteinstellungen</SectionText>
                </ContentSpacing>
                <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
            </SectionItem>
        </SectionListContainer>
    );
};

export default SettingsList;
