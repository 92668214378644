import React from 'react';
import {
    Container,
    IconWrapper,
    MessageWrapper,
    Title,
    Subtitle,
    Card,
    Headline
} from './empty-card.styles';

interface EmptyCardProps {
    headline: string;
    image: string;
    title: string;
    subtitle: string;
}

const EmptyCard: React.FC<EmptyCardProps> = ({ headline, image, title, subtitle }) => {
    return (
        <Container>
            <Headline>{headline}</Headline>
            <Card>
                <IconWrapper>
                    <img src={image} alt="Folder icon" />
                </IconWrapper>
                <MessageWrapper>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </MessageWrapper>
            </Card>
        </Container>
    );
};

export default EmptyCard;
