import { ProgressBarContainer, Progress } from './onboarding-page.styles';
import useInvestorDetailStore from '../../stores/investorDetailStore';
import UpdatePassword from './components/update-password/update-password';
import LetsGo from './components/lets-go/lets-go';
import ReviewAddress from './components/review-address/review-address';
import { useCallback, useEffect, useState } from 'react';
import ReviewDocuments from './components/review-documents/review-documents';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { updateOnboardingStep } from '../../services/investorService';
import useAlertStore from '../../stores/alertStore';

const OnboardingPage = () => {
    const navigate = useCustomNavigate();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const { investor, setInvestor } = useInvestorDetailStore();

    // States
    const [isLoading, setIsLoading] = useState(false);

    const totalSteps = 4;
    const progressPercentage = ((investor?.onboarding_step ?? 0) / totalSteps) * 100;

    useEffect(() => {
        if (investor && investor.onboarding_step === 4) {
            navigate("/", { replace: true, clearHistory: true });
        }
    }, [investor]);

    const onUpdateOnboardingStep = useCallback(async () => {
        if (investor) {
            try {
                setIsLoading(true);
                await updateOnboardingStep(investor.investor_id, investor.onboarding_step + 1);
            } catch (error) {
                console.log('error while updatung onboarding step:', error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
                setInvestor({ ...investor, onboarding_step: investor.onboarding_step + 1 });
            }
        }
    }, [investor]);

    if (!investor) return null;

    return (
        <>
            <ProgressBarContainer>
                <Progress width={progressPercentage} />
            </ProgressBarContainer>
            {investor.onboarding_step === 0 ? (
                <LetsGo onClickNext={onUpdateOnboardingStep} isLoading={isLoading} />
            ) : investor.onboarding_step === 1 ? (
                <UpdatePassword onClickNext={onUpdateOnboardingStep} isLoading={isLoading} />
            ) : investor.onboarding_step === 2 ? (
                <ReviewAddress onClickNext={onUpdateOnboardingStep} isLoading={isLoading} />
            ) : investor.onboarding_step === 3 ? (
                <ReviewDocuments onClickNext={onUpdateOnboardingStep} isLoading={isLoading} />
            ) : null}
        </>
    );
};

export default OnboardingPage;
