import { useEffect, useState } from "react";
import CustomInputField from '../../components/custom-input-field/custom-input-field';
import { login } from '../../services/authService';
import useInvestorDetailStore from '../../stores/investorDetailStore';
import {
    LeftPanel,
    RightPanel,
    FooterContainer,
    FooterText,
    LoginForm,
    Title,
    RightTitle,
    RightSubitle,
    LogoContainer
} from './login-page.styles';
import LogoMulticolor from "../../assets/images/logo/logo-multicolor.svg";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import CTAButton from "../../components/cta-button/cta-button";

const LoginPage = () => {
    const navigate = useCustomNavigate();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');

    // Stores
    const { investor, setInvestor } = useInvestorDetailStore();

    useEffect(() => {
        if (investor) {
            navigate("/", { replace: true, clearHistory: true });
        }
    }, [investor]);

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        let valid = true;
        setEmailError('');
        setPasswordError('');

        // Basic email validation
        if (!email) {
            setEmailError('E-Mail darf nicht leer sein.');
            valid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError('Bitte gib eine gültige E-Mail Adresse ein.');
            valid = false;
        }

        // Check if the password is not empty
        if (!password) {
            setPasswordError('Passwort darf nicht leer sein.');
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const investorData = await login(email, password);
                setInvestor(investorData);
            } catch (error) {
                setLoginError('Login fehlgeschlagen.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="page-container-full">
            <div className="row">
                <div className="col-md-6 d-none d-md-flex flex-column">
                    <LeftPanel>
                        <div style={{ marginBottom: 100, width: '100%' }}>
                            <Title>Einfach in Dubai vermieten <span>mit managd.</span></Title>
                        </div>
                    </LeftPanel>
                </div>
                <div className="col-md-6 d-flex flex-column">
                    <RightPanel>
                        <LogoContainer>
                            <img src={LogoMulticolor} width={228} alt="Managd Logo" draggable={false} />
                        </LogoContainer>
                        <RightTitle>Willkommen bei Managd!</RightTitle>
                        <RightSubitle>Melde dich an.</RightSubitle>
                        <LoginForm onSubmit={handleLogin}>
                            <div className="mb-3">
                                <CustomInputField
                                    id="email"
                                    placeholder="E-Mail"
                                    type="email"
                                    value={email}
                                    errorMessage={emailError}
                                    onClear={() => setEmail("")}
                                    onChange={(value: string) => {
                                        setEmail(value);
                                        setEmailError("");
                                    }}
                                />
                            </div>
                            <div>
                                <CustomInputField
                                    id="password"
                                    placeholder="Passwort"
                                    type="password"
                                    value={password}
                                    errorMessage={passwordError}
                                    onClear={() => setPassword("")}
                                    onChange={(value: string) => {
                                        setPassword(value);
                                        setPasswordError("");
                                    }}
                                />
                            </div>
                            {loginError && (
                                <p className="ms-2 mb-4 text-danger">{loginError}</p>
                            )}
                            <div style={{ marginTop: 24 }}>
                                <CTAButton
                                    variant="primary"
                                    size="L"
                                    label="Anmelden"
                                    loading={isLoading}
                                    onClick={() => handleLogin}
                                    fullWidth
                                />
                            </div>
                            {!isLoading && (
                                <div style={{ marginTop: 24 }}>
                                    <CTAButton
                                        variant="ghost"
                                        size="L"
                                        label="Passwort vergessen?"
                                        onClick={() => navigate("/reset-password")}
                                        fullWidth
                                        noPadding
                                    />
                                </div>
                            )}
                        </LoginForm>
                        <FooterContainer>
                            <FooterText>&copy; 2025 Managd. Alle Rechte vorbehalten.</FooterText>
                        </FooterContainer>
                    </RightPanel>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
