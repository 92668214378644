import { styled } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${colorPalette.blue50};
  position: relative;
`;

export const Progress = styled.div<{ width: number }>`
  height: 100%;
  background-color: ${colorPalette.blue500};
  width: ${({ width }) => width}%;
  transition: width 0.3s ease;
`;
