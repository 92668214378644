import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
    width: 100%;
    padding: 16px;
    background: #FFFFFFA6;
    backdrop-filter: blur(100px);
    border: 1px solid ${colorPalette.blue200};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;

    @media (max-width: 767.98px) {
        box-shadow: 0px 0px 20px 0px #00000040;
    }
`;

export const Image = styled.img`
    width: 175px;
    margin: 0 auto 16px auto;
    
    @media (max-width: 767.98px) {
        width: 70px;
        margin: 0;
    }
`;

export const Title = styled.h2`
    width: 100%;
    margin-bottom: 16px;
    text-align: start;
    color: ${colorPalette.textMain};
    ${typeScale.headingXSMain}

    @media (max-width: 767.98px) {
        margin-bottom: 8px;
        ${typeScale.headingXXSMain}
    }
`;

export const Description = styled.p`
    margin: 0;
    color: ${colorPalette.textDark};
    ${typeScale.bodySMMain};
    max-width: 60%;

    @media (max-width: 767.98px) {
        max-width: 100%;
        ${typeScale.bodyXS};
        text-align: start;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: center;
    margin-top: auto;

    @media (max-width: 767.98px) {
        flex-direction: column;
        gap: 8px;
    }
`;
