// DocumentCard.tsx
import React, { useCallback, useMemo, useState } from "react";
import {
    DocumentCardContainer,
    DocumentIcon,
    DocumentInfo,
    DocumentTitle,
    DocumentSubtitle,
    DocumentAction,
    SpinnerContainer,
} from "./document-card.styles";
import CTAButton from "../../../../../../components/cta-button/cta-button";
import MaterialIcon from "../../../../../../components/material-icon";
import { DocumentType, downloadDocument, formatDocumentType, IDocument } from "../../../../../../services/documentService";
import useAlertStore from "../../../../../../stores/alertStore";
import { colorPalette } from "../../../../../../utils/theme";

interface DocumentCardProps {
    investor_id: number,
    documentType: DocumentType;
    subtitle: string;
    investor_document?: IDocument;
    isUploading: boolean;
    required?: boolean;
    onUpload?: () => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({
    investor_id,
    documentType,
    subtitle,
    investor_document,
    isUploading,
    required = false,
    onUpload,
}) => {
    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isDownloading, setIsDownloading] = useState(false);

    // Functions
    const onDownloadInvestorDocument = useCallback(async (investorDocument: IDocument) => {
        if (investor_id) {
            try {
                setIsDownloading(true);
                const blob: Blob | null = await downloadDocument(investorDocument.document_id);
                if (blob) {
                    const blobUrl = window.URL.createObjectURL(blob);

                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = investorDocument.file_name;
                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            } catch (error) {
                console.log("error while downloading investor document: ", error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsDownloading(false);
            }
        }
    }, [investor_id]);

    const isDocumentExpired = useMemo(() => {
        return investor_document?.valid_until ? new Date(investor_document.valid_until) < new Date() : false;
    }, [investor_document?.valid_until]);

    return (
        <DocumentCardContainer enableHover={investor_document !== undefined} onClick={investor_document ? () => onDownloadInvestorDocument(investor_document) : undefined}>
            <DocumentIcon>
                <MaterialIcon
                    icon={isDocumentExpired ? "scan_delete" : investor_document !== undefined ? "task" : "draft"}
                    size={24}
                    color={isDocumentExpired ? colorPalette.errorDark : investor_document !== undefined ? colorPalette.successMedium : colorPalette.textDark}
                />
            </DocumentIcon>
            <DocumentInfo>
                {isDownloading ? (
                    <SpinnerContainer
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                    />
                ) : (
                    <DocumentTitle>{`${formatDocumentType(documentType)} ${required ? "" : "(Optional)"}`}</DocumentTitle>
                )}
                {
                    !investor_document ? (
                        <DocumentSubtitle expired={false}>{subtitle}</DocumentSubtitle>
                    ) : investor_document && investor_document.valid_until !== null ? (
                        isDocumentExpired ? (
                            <DocumentSubtitle expired={true}>Abgelaufen am {new Date(investor_document.valid_until).toLocaleDateString()}</DocumentSubtitle>
                        ) : (
                            <DocumentSubtitle expired={false}>Gültig bis {new Date(investor_document.valid_until).toLocaleDateString()}</DocumentSubtitle>
                        )
                    ) : null
                }
            </DocumentInfo>
            <DocumentAction>
                {investor_document !== undefined ? (
                    <>
                        <p className="d-none d-md-inline">{investor_document.file_name}</p>
                        <div className="d-flex">
                            <MaterialIcon className="d-md-none" icon="upload_file" size={24} color={colorPalette.blue500} />
                            <MaterialIcon icon="chevron_right" size={24} color="textDark" />
                        </div>
                    </>
                ) : (
                    <>
                        <CTAButton
                            className="d-none d-md-inline"
                            variant="tertiary"
                            size="S"
                            label="Dokument hochladen"
                            onClick={onUpload}
                            loading={isUploading}
                        />
                        <CTAButton
                            className="d-md-none"
                            variant="tertiary"
                            size="XS"
                            label="Hochladen"
                            onClick={onUpload}
                            loading={isUploading}
                        />
                    </>
                )}
            </DocumentAction>
        </DocumentCardContainer >
    );
};

export default DocumentCard;
