// ProfileBankDetailsPage.tsx
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";
import useInvestorDetailStore from "../../../../stores/investorDetailStore";
import { StyledContainer, HeaderContainer, Title, HeaderLeftContent, CardContainer, ItemContainer, Label, Value, CardTitle, Footer, FormSection, CardSubtitle } from "./bank-details-page.styles";
import CTAButton from "../../../../components/cta-button/cta-button";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import { usePWA } from "../../../../context/PWAContext";

const ProfileBankDetailsPage = () => {
    const navigate = useNavigate();
    const isPWA = usePWA();
    const isMobile = useIsMobile();

    // Stores
    const investor = useInvestorDetailStore(state => state.investor);

    // Memo
    const primaryBankDetails = useMemo(() => {
        if (investor && investor.bank_details.length > 0) {
            return investor?.bank_details[0];
        }
        return null;
    }, [investor]);

    return (
        <StyledContainer>
            {investor && (
                <>
                    <Row>
                        <Col xs={12} lg={8}>
                            <HeaderContainer>
                                <HeaderLeftContent>
                                    {!isMobile && (
                                        <MaterialIcon icon="arrow_back" size={24} color={colorPalette.textMain} onClick={() => navigate(-1)} />
                                    )}
                                    <Title>Bankverbindung</Title>
                                </HeaderLeftContent>
                                <CTAButton
                                    className="d-none d-md-block"
                                    variant="primary"
                                    size="S"
                                    label="Daten bearbeiten"
                                    onClick={() => navigate("/profile/edit-bank-details")}
                                />
                            </HeaderContainer>

                            <FormSection className="gy-3">
                                <Col xs={12}>
                                    <CardTitle>Primäres Bankkonto</CardTitle>
                                    <CardSubtitle>An dieses Konto überweisen wir deine Mieteinnahmen der Wohnungen, die durch managd verwaltet werden. Deine Daten werden sicher und verschlüsselt gespeichert.</CardSubtitle>
                                    <CardContainer>
                                        <ItemContainer>
                                            <Label>Kontoinhaber</Label>
                                            <Value>{primaryBankDetails?.account_holder_name ?? "-"}</Value>
                                        </ItemContainer>
                                        <ItemContainer>
                                            <Label>IBAN</Label>
                                            <Value>{primaryBankDetails?.iban ?? "-"}</Value>
                                        </ItemContainer>
                                        <ItemContainer>
                                            <Label>SWIFT/BIC</Label>
                                            <Value>{primaryBankDetails?.swift ?? "-"}</Value>
                                        </ItemContainer>
                                        <ItemContainer>
                                            <Label>Bank</Label>
                                            <Value>{primaryBankDetails?.bank_name ?? "-"}</Value>
                                        </ItemContainer>
                                        {primaryBankDetails?.branch_number && (
                                            <ItemContainer>
                                                <Label>Filialnummer</Label>
                                                <Value>{primaryBankDetails.branch_number}</Value>
                                            </ItemContainer>
                                        )}
                                        {primaryBankDetails?.account_number && (
                                            <ItemContainer>
                                                <Label>Kontonummer</Label>
                                                <Value>{primaryBankDetails.account_number}</Value>
                                            </ItemContainer>
                                        )}
                                        <ItemContainer>
                                            <Label>Währung</Label>
                                            <Value>{primaryBankDetails?.currency ?? "-"}</Value>
                                        </ItemContainer>
                                    </CardContainer>
                                </Col>

                                {isPWA && (
                                    <Col xs={12}>
                                        <CTAButton
                                            variant="primary"
                                            size="L"
                                            label="Daten bearbeiten"
                                            onClick={() => navigate("/profile/edit-bank-details")}
                                            fullWidth
                                        />
                                    </Col>
                                )}
                            </FormSection>
                        </Col>
                    </Row>


                    {!isPWA && (
                        <Footer>
                            <CTAButton
                                variant="primary"
                                size="L"
                                label="Daten bearbeiten"
                                onClick={() => navigate("/profile/edit-bank-details")}
                                fullWidth
                            />
                        </Footer>
                    )}
                </>
            )}
        </StyledContainer>
    );
};

export default ProfileBankDetailsPage;
