import React, { useMemo } from 'react';
import {
    CardContainer,
    CardHeader,
    CardTitle,
    CardSubtitle,
    CircularProgressWrapper,
    CircularContentWrapper,
    CircularIconWrapper,
    CircularAmountWrapper,
    CardFooter,
} from './rental-payments-card.styles';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';

interface RentalPaymentsCardProps {
    className?: string | undefined;
    totalPayments: number;
    receivedPayments: number;
}

const RentalPaymentsCard: React.FC<RentalPaymentsCardProps> = ({ className, totalPayments, receivedPayments }) => {
    const navigate = useCustomNavigate();

    // Memo
    const percentage = useMemo(() => {
        return (receivedPayments / totalPayments) * 100;
    }, [totalPayments, receivedPayments]);

    return (
        <CardContainer className={className} onClick={() => navigate("/financials", { replace: true, clearHistory: true })}>
            <CardHeader>
                <CardTitle>Mietauszahlungen</CardTitle>
                <CardSubtitle>2024</CardSubtitle>
            </CardHeader>

            <CircularProgressWrapper>
                <CircularProgressbar
                    value={percentage}
                    circleRatio={0.7}
                    styles={buildStyles({
                        textColor: 'transparent',
                        pathColor: colorPalette.successMedium,
                        trailColor: colorPalette.white,
                        rotation: 0.65,
                    })}
                    strokeWidth={5}
                />
                <CircularContentWrapper>
                    <CircularIconWrapper>
                        <MaterialIcon icon="checkbook" size={40} color={colorPalette.successMedium} />
                    </CircularIconWrapper>
                    <CircularAmountWrapper>
                        {receivedPayments}/{totalPayments}
                    </CircularAmountWrapper>
                </CircularContentWrapper>
            </CircularProgressWrapper>

            <CardFooter>Mietzahlungen (2024) erhalten</CardFooter>
        </CardContainer>
    );
};

export default RentalPaymentsCard;
