import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingSMMain}
`;

export const RentSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Amount = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
`;

export const GrossReturn = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}
`;

export const Label = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}
`;

export const Transactions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  border-top: 1px solid ${colorPalette.blue200};
`;

export const TransactionAmount = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;

export const TransactionTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
`;
