// ProfileDocumentDetailsPage.tsx
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";
import useInvestorDetailStore from "../../../../stores/investorDetailStore";
import {
    StyledContainer,
    HeaderContainer,
    Title,
    CardContainer,
    CardTitle,
    FormSection,
} from "./document-details-page.styles";
import DocumentCard from "./components/document-card/document-card";
import DocumentUploadModal, { DocumentUploadDialog } from "../../../../modals/document-upload-modal/document-upload-modal";
import { useCallback, useState } from "react";
import useAlertStore from "../../../../stores/alertStore";
import { DocumentType, formatDocumentType } from "../../../../services/documentService";
import { uploadInvestorDocuments } from "../../../../services/investorService";
import useIsMobile from "../../../../hooks/useIsMobile";

const ProfileDocumentDetailsPage = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const investor = useInvestorDetailStore(state => state.investor);
    const setInvestor = useInvestorDetailStore(state => state.setInvestor);

    // States
    const [isUploading, setIsUploading] = useState<DocumentType | null>(null);
    const [isUploadDocumentModalVisible, setIsUploadDocumentModalVisible] = useState<DocumentUploadDialog | null>(null);

    const onHandleUpdate = useCallback(async (file: File, expiryDate: Date | null) => {
        const document = isUploadDocumentModalVisible;
        if (investor && document) {
            try {
                setIsUploadDocumentModalVisible(null);
                setIsUploading(document.documentType);

                // Upload new passport documents
                if (document.documentType === DocumentType.PASSPORT) {
                    const newDocuments = await uploadInvestorDocuments(DocumentType.PASSPORT, [file], expiryDate ? [expiryDate.toDateString()] : []);

                    const investorDocuments = investor.documents;
                    investorDocuments.push(...newDocuments);
                    setInvestor({ ...investor, documents: investorDocuments });
                }

                // Upload new emirates-id file
                if (document.documentType === DocumentType.EMIRATES_ID) {
                    const newDocuments = await uploadInvestorDocuments(DocumentType.EMIRATES_ID, [file], expiryDate ? [expiryDate.toDateString()] : []);

                    const investorDocuments = investor.documents;
                    investorDocuments.push(...newDocuments);
                    setInvestor({ ...investor, documents: investorDocuments });
                }

                // Upload new proof of address file
                if (document.documentType === DocumentType.PROOF_OF_ADDRESS) {
                    const newDocuments = await uploadInvestorDocuments(DocumentType.PROOF_OF_ADDRESS, [file]);

                    const investorDocuments = investor.documents;
                    investorDocuments.push(...newDocuments);
                    setInvestor({ ...investor, documents: investorDocuments });
                }

                showAlert("success", `${formatDocumentType(document.documentType)} erfolgreich hochgeladen!`);
            } catch (error) {
                console.log(`Error while upploading investor document (${formatDocumentType(document.documentType)}):`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUploading(null);
            }
        }
    }, [investor, isUploadDocumentModalVisible]);

    return (
        <StyledContainer>
            {investor && (
                <Row>
                    <Col xs={12} lg={8}>
                        <HeaderContainer>
                            {!isMobile && (
                                <MaterialIcon icon="arrow_back" size={24} color={colorPalette.textMain} onClick={() => navigate(-1)} />
                            )}
                            <Title>Dokumente</Title>
                        </HeaderContainer>

                        <FormSection className="gy-3">
                            <Col xs={12}>
                                <CardTitle>Ausweisdokumente</CardTitle>
                                <CardContainer>
                                    {/* Reisepass Document Card */}
                                    <DocumentCard
                                        investor_id={investor.investor_id}
                                        documentType={DocumentType.PASSPORT}
                                        subtitle="Lade deinen Reisepass hoch."
                                        investor_document={investor.documents.find(document => document.document_type === DocumentType.PASSPORT)}
                                        onUpload={() => setIsUploadDocumentModalVisible({ documentType: DocumentType.PASSPORT, enableDocumentExpiry: true })}
                                        isUploading={isUploading === DocumentType.PASSPORT}
                                        required
                                    />

                                    {/* Emirates ID Document Card */}
                                    <DocumentCard
                                        investor_id={investor.investor_id}
                                        documentType={DocumentType.EMIRATES_ID}
                                        subtitle="Lade deine Emirates ID hoch, falls du ein VAE Visum besitzt"
                                        investor_document={investor.documents.find(document => document.document_type === DocumentType.EMIRATES_ID)}
                                        onUpload={() => setIsUploadDocumentModalVisible({ documentType: DocumentType.EMIRATES_ID, enableDocumentExpiry: true })}
                                        isUploading={isUploading === DocumentType.EMIRATES_ID}
                                    />
                                </CardContainer>
                            </Col>

                            <Col xs={12}>
                                <CardTitle>Weitere Dokumente</CardTitle>
                                <CardContainer>
                                    {/* Wohnsitznachweis Document Card */}
                                    <DocumentCard
                                        investor_id={investor.investor_id}
                                        documentType={DocumentType.PROOF_OF_ADDRESS}
                                        subtitle="Lade eine aktuelle Verbrauchsrechnung (Strom, Telefon) hoch."
                                        investor_document={investor.documents.find(document => document.document_type === DocumentType.PROOF_OF_ADDRESS)}
                                        onUpload={() => setIsUploadDocumentModalVisible({ documentType: DocumentType.PROOF_OF_ADDRESS, enableDocumentExpiry: false })}
                                        isUploading={isUploading === DocumentType.PROOF_OF_ADDRESS}
                                        required
                                    />

                                    {/* Firmenlizenz Document Card */}
                                    {/*<DocumentCard
                                title="Firmenlizenz (Optional)"
                                subtitle="Lade deine Firmenlizenz hoch, falls deine Immobilien in einer Gesellschaft liegen."
                                uploaded={false}
                                enableHover={false}
                                onUpload={() => {}}
                            />*/}
                                </CardContainer>
                            </Col>
                        </FormSection>
                    </Col>
                </Row>
            )}

            {isUploadDocumentModalVisible && (
                <DocumentUploadModal
                    documentType={isUploadDocumentModalVisible.documentType}
                    onClickUpload={onHandleUpdate}
                    onClose={() => setIsUploadDocumentModalVisible(null)}
                    enableDocumentExpiry={isUploadDocumentModalVisible.enableDocumentExpiry}
                />
            )}
        </StyledContainer>
    );
};

export default ProfileDocumentDetailsPage;
