import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  padding: 12px 16px;
`;

export const Title = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}
`;

export const CardContainer = styled.div`
  border-radius: 8px;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;

  /* Scale effect on hover */
  &:hover {
    transform: scale(1.01);
  }
`;

export const UnitTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingSMMain};
`;

export const UnitDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export const UnitDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UnitDetailLabel = styled.div`
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
`;
