import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { formatMoney } from '../../../../utils/helpers';
import { colorPalette, fonts } from '../../../../utils/theme';
import {
    ChartContainer,
    PortfolioVolume,
    PortfolioSubtitle,
    ChartHeader,
    ROIContainer,
    ROILabel,
    ROISubLabel,
    ChartContent,
} from './portfolio-chart-card.styles';
import { useContentArea } from '../../../../context/ContentAreaContext';

interface PortfolioChartCardProps {
    portfolioVolume: number;
    grossYield: number;
    years: number[];
    volumeData: number[];
}

const PortfolioChartCard: React.FC<PortfolioChartCardProps> = ({ portfolioVolume, grossYield, years, volumeData }) => {
    const { contentAreaRef } = useContentArea();

    // References
    const chartRef = useRef<any>(null);

    // States
    const [backgroundColor, setBackgroundColor] = useState<string | CanvasGradient>(colorPalette.blue400);
    const [lineBorderColor, setLineBorderColor] = useState<string | CanvasGradient>("");

    useEffect(() => {
        if (chartRef && chartRef.current) {
            const chart = chartRef.current;
            const canvas = chart.canvas.getContext('2d');

            const { chartArea } = chart;
            if (!chartArea) return;

            const { top, bottom } = chartArea;

            // Create gradient for the line border
            const lineGradient = canvas.createLinearGradient(0, 0, canvas.canvas.width, 0);
            lineGradient.addColorStop(0, '#0F1C4D');
            lineGradient.addColorStop(0.68, '#276EF1');
            setLineBorderColor(lineGradient);

            // Create gradient for the background fill with reversed colors
            const fillGradient = canvas.createLinearGradient(0, top, 0, bottom);
            fillGradient.addColorStop(0, 'rgba(39, 110, 241, 0.25)');
            fillGradient.addColorStop(0.95, 'rgba(39, 110, 241, 0)');
            setBackgroundColor(fillGradient);
        }

        const handleScroll = () => {
            const tooltipEl = document.getElementById('chartjs-tooltip');
            if (tooltipEl) {
                tooltipEl.style.opacity = '0';
            }
        };

        const scrollContainer = contentAreaRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [chartRef, contentAreaRef]);

    const data = {
        labels: years,
        datasets: [
            {
                label: 'Portfolio Value',
                data: volumeData,
                fill: true,
                backgroundColor: backgroundColor,
                borderColor: lineBorderColor,
                borderWidth: 3,
                pointRadius: 1,
                pointHitRadius: 40,
                pointHoverRadius: 10,
                pointBorderColor: "transparent",
                borderCapStyle: 'round' as CanvasLineCap,
                borderJoinStyle: 'round' as CanvasLineJoin,
                tension: 0.4,
            },
        ],
    };

    const options = {
        type: "line",
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    color: "rgba(235,241,247,0.5)",
                },
                ticks: {
                    font: {
                        fontSize: "0.625rem",
                        fontWeight: 400,
                        lineHeight: 1.2,
                        family: fonts.primary,
                        color: colorPalette.textDark,
                    },
                    color: colorPalette.textDark,
                    padding: 10,
                }
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    display: true,
                    stepSize: 1_000_000,
                    font: {
                        fontSize: "0.625rem",
                        fontWeight: 400,
                        lineHeight: 1.2,
                        family: fonts.primary,
                        color: colorPalette.textDark,
                    },
                    color: colorPalette.textDark,
                    callback: function (value: any) {
                        return value === 0 ? '' : value / 1_000_000 + 'M';
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false, // Disable the default tooltip
                external: function (context: any) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.style.opacity = '0';
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.background = 'rgba(15, 28, 77, 0.9)';
                        tooltipEl.style.borderRadius = '4px';
                        tooltipEl.style.padding = '8px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.fontFamily = fonts.primary;
                        tooltipEl.style.backdropFilter = 'blur(20px)';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        const rawValue = tooltipModel.dataPoints[0].raw as number;
                        const formattedValue = `AED ${formatMoney(rawValue, false)}`;
                        const year = tooltipModel.title[0] || '';

                        tooltipEl.innerHTML = `
                            <div style="font-size: 14px; font-weight: bold; color: ${colorPalette.blue50};">
                                ${formattedValue}
                            </div>
                            <div style="font-size: 10px; font-weight: 500; color: ${colorPalette.textDark};">
                                ${year}
                            </div>
                        `;
                    }

                    // Position the tooltip
                    const position = context.chart.canvas.getBoundingClientRect();
                    const tooltipWidth = tooltipEl.offsetWidth;
                    const tooltipHeight = tooltipEl.offsetHeight;

                    let left = position.left + window.pageXOffset + tooltipModel.caretX;
                    let top = position.top + window.pageYOffset + tooltipModel.caretY;

                    // Adjust if the tooltip is going beyond the right edge of the viewport
                    if (left + tooltipWidth > window.innerWidth) {
                        left -= tooltipWidth;
                    }

                    // Adjust if the tooltip is going beyond the left edge of the viewport
                    if (left < 0) {
                        left = 0;
                    }

                    // Adjust if the tooltip is going beyond the bottom edge of the viewport
                    if (top + tooltipHeight > window.innerHeight) {
                        top -= tooltipHeight;
                    }

                    // Apply adjusted position
                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.left = `${left}px`;
                    tooltipEl.style.top = `${top}px`;
                }
            }
        }
    };

    return (
        <ChartContainer>
            <ChartHeader>
                <div>
                    <PortfolioVolume>AED {formatMoney(portfolioVolume, false)}</PortfolioVolume>
                    <PortfolioSubtitle>Portfoliovolumen (Kaufpreis)</PortfolioSubtitle>
                </div>
                <ROIContainer>
                    {grossYield > 0 && (
                        <div>
                            <ROISubLabel>{grossYield.toFixed(2)}%</ROISubLabel>
                            <ROILabel>ROI (Brutto)</ROILabel>
                        </div>
                    )}
                </ROIContainer>
            </ChartHeader>
            <ChartContent>
                <Line ref={chartRef} data={data} options={options} />
            </ChartContent>
        </ChartContainer>
    );
};

export default PortfolioChartCard;
