import React from 'react';
import {
    CardContainer,
    Header,
    HeaderTitle,
    Amount,
    Details,
    DueDate,
    Label,
    StatusSection,
    StatusLabel,
    StatusDescription,
    ProgressBar,
    ProgressSegment,
    ProgressSegmentLine,
} from './next-rent-payment-card.styles';
import { colorPalette } from '../../../../utils/theme';
import { formatMoney } from '../../../../utils/helpers';
import { ILeasePayment } from '../../../../services/leaseService';
import CTAButton from '../../../../components/cta-button/cta-button';

interface NextRentPaymentCardProps {
    payment: ILeasePayment;
    onViewAll: () => void;
}

const NextRentPaymentCard: React.FC<NextRentPaymentCardProps> = ({
    payment,
    onViewAll,
}) => {
    const segments = 4;
    const progressPercentage = 75;
    const filledSegments = Math.floor((progressPercentage / 100) * segments);

    const getSegmentWidth = (index: number) => {
        if (index === 0 || index === 1) return "10%";
        if (index === 2) return "80%";
        return "0%";
    };

    return (
        <CardContainer>
            <Header>
                <HeaderTitle>Nächste Mietauszahlung</HeaderTitle>
                <CTAButton
                    variant="ghost"
                    size="S"
                    label="Details"
                    icon="arrow_outward"
                    onClick={onViewAll}
                    color={colorPalette.white}
                    noPadding
                />
            </Header>
            <div className="d-flex align-items-center justify-content-between" style={{ paddingLeft: 24, paddingRight: 24 }}>
                <Amount>AED {formatMoney(payment.amount)}</Amount>
                <DueDate>{new Date(payment.due_date).toLocaleDateString()}</DueDate>
            </div>
            <Details>
                <Label>Betrag</Label>
                <Label>Auszahlungsdatum</Label>
            </Details>
            <ProgressBar>
                {Array.from({ length: segments }).map((_, index) => (
                    <React.Fragment key={index}>
                        <ProgressSegment filled={index < filledSegments} />
                        {index < segments - 1 && (
                            <ProgressSegmentLine
                                width={getSegmentWidth(index)}
                                filled={index < filledSegments - 1 || (index === filledSegments - 1 && progressPercentage % (100 / segments) > 0)}
                            />
                        )}
                    </React.Fragment>
                ))}
            </ProgressBar>
            <StatusSection>
                <StatusLabel>Status: Scheck gutgeschrieben</StatusLabel>
                <StatusDescription>Die Miete wurde unserem Konto gutgeschrieben.</StatusDescription>
            </StatusSection>
        </CardContainer>
    );
};

export default NextRentPaymentCard;
