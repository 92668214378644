import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";
import { Row } from "react-bootstrap";

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;

  @media (max-width: 767.98px) {
    padding: 16px 16px;
    border-bottom: 1px solid ${colorPalette.blue200};
  }
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const FormSection = styled(Row)`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;

  @media (max-width: 767.98px) {
    padding-bottom: 100px;
  }
`;

/* Card */
export const CardTitle = styled.h4`
  margin-top: 16px;
  margin-bottom: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}
`;

export const CardContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 14px;
  border-bottom: 1px solid ${colorPalette.blue100};

  &:last-child {
    border-bottom: none;
  }
`;

export const Label = styled.span`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
`;

export const Value = styled.span`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};
`;

export const Info = styled.h4`
  margin-top: 8px;
  margin-bottom: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}
`;

export const Footer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isPWA",
})<{ isPWA: boolean }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${colorPalette.white};
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${colorPalette.blue200};
  box-shadow: 0px 0px 10px 0px #00000040;

  /* Adjust bottom padding only when it's a PWA on mobile (iPhone) */
  @media (max-width: 767.98px) {
    ${({ isPWA }) =>
      isPWA &&
      `
      padding-bottom: 24px; /* Increase this value as necessary */
    `}
  }

  /* Hide on larger screens */
  @media (min-width: 768px) {
    display: none;
  }
`;
