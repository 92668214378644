import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 48px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

// Container for the entire banner
export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  background-color: ${colorPalette.blue200};
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  gap: 16px;
  padding: 16px 16px 16px 32px;
  width: 100%;

  @media (max-width: 767.98px) {
    flex-direction: column;
    margin-bottom: 0;
    padding: 16px;
    gap: 8px;
  }
`;

export const BannerMainWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const BannerImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 120px;
    height: auto;
  }

  @media (max-width: 767.98px) {
    img {
      width: 46px;
    }
  }
`;

export const BannerContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 767.98px) {
    margin-bottom: 8px;
  }
`;

export const BannerText = styled.h3`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXXSMain}
  }
`;

export const BannerSubText = styled.p`
  margin: 4px 0 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;

export const BannerButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 767.98px) {
    align-self: stretch; /* Make the button take full width */
    margin-top: auto; /* Push the button to the bottom on mobile */
  }
`;
