import '../login-page/login-page.styles.ts';

import { useCallback, useState } from "react";
import CustomInputField from '../../components/custom-input-field/custom-input-field';
import SixDigitCodeInput from './components/six-digit-code';
import { checkForgotPasswordCode, forgotPassword, resetPassword } from '../../services/authService';
import useCustomNavigate from "../../hooks/useCustomNavigate";
import CTAButton from '../../components/cta-button/cta-button';
import { ButtonContainer, ContainerCard, IllustrationImage, StyledContainer, Subtitle, Title } from './reset-password-page.styles';
import FlyingCloudIllustration from '../../assets/images/flying-cloud.svg';
import FlyingPaperIllustration from '../../assets/images/flying-paper.svg';
import KeysIllustration from '../../assets/images/keys.svg';
import ShieldIllustration from '../../assets/images/shield.svg';
import useIsMobile from '../../hooks/useIsMobile';
import useAlertStore from '../../stores/alertStore';

const ResetPasswordPage = () => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [sentCode, setSentCode] = useState<boolean>(false);
    const [isValidCode, setIsValidCode] = useState<boolean>(false);
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [passwordReseted, setPasswordReseted] = useState<boolean>(false);

    const handleForgotPassword = useCallback(async () => {
        let valid = true;
        setEmailError("");
        setResetPasswordError("");

        // Basic email validation
        if (!email) {
            setEmailError('E-Mail darf nicht leer sein.');
            valid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError('Bitte gib eine gültige E-Mail Adresse ein.');
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const sentEmail = await forgotPassword(email);
                setSentCode(sentEmail);
            } catch (error) {
                console.log('Error while send forgot password email:', error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [email]);

    const handleCheckCode = useCallback(async () => {
        let valid = true;
        setResetPasswordError('');

        // Basic email validation
        if (!verificationCode || verificationCode.length !== 6) {
            setResetPasswordError('Code darf nicht leer sein.');
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const isValid = await checkForgotPasswordCode(verificationCode);
                if (isValid) {
                    setIsValidCode(isValid);
                } else {
                    showAlert("error", "Der angegebene Code ist ungültig.");
                }
            } catch (error) {
                console.log('Error while checking forgot password code:', error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [verificationCode]);

    const handleResetPassword = useCallback(async () => {
        let valid = true;
        setPasswordError("");
        setResetPasswordError("");

        // Basic password validation
        if (!password) {
            setPasswordError('Passwort darf nicht leer sein.');
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const passwordReset = await resetPassword(verificationCode, password);
                setPasswordReseted(passwordReset);
            } catch (error) {
                console.log('Error while change password:', error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [password]);

    return (
        <StyledContainer>
            <ContainerCard>
                {sentCode ? (
                    isValidCode ? (
                        passwordReseted ? (
                            <>
                                <IllustrationImage src={ShieldIllustration} width={77} />
                                <Title>Passwort erfolgreich geändert.</Title>
                                <Subtitle>Du kannst dich mit deinem neuen Passwort einloggen.</Subtitle>
                                <ButtonContainer>
                                    <div style={{ marginTop: 24 }}>
                                        <CTAButton
                                            variant="primary"
                                            size={isMobile ? "M" : "L"}
                                            label="Zurück zur Anmeldung"
                                            loading={isLoading}
                                            onClick={() => navigate("/login", { replace: true, clearHistory: true })}
                                            fullWidth
                                        />
                                    </div>
                                </ButtonContainer>
                            </>
                        ) : (
                            <>
                                <IllustrationImage src={KeysIllustration} width={77} />
                                <Title>Fast geschafft!</Title>
                                <Subtitle>Gib ein neues Passwort ein.</Subtitle>
                                <ButtonContainer>
                                    <CustomInputField
                                        id="new-password"
                                        placeholder="Dein neues Passwort"
                                        type="password"
                                        value={password}
                                        errorMessage={passwordError}
                                        onClear={() => setPassword("")}
                                        onChange={(value: string) => {
                                            setPassword(value);
                                            setPasswordError("");
                                        }}
                                    />
                                    <div style={{ marginTop: 24 }}>
                                        <CTAButton
                                            variant="primary"
                                            size={isMobile ? "M" : "L"}
                                            label="Passwort zurücksetzen"
                                            loading={isLoading}
                                            onClick={handleResetPassword}
                                            fullWidth
                                        />
                                    </div>
                                </ButtonContainer>
                            </>
                        )
                    ) : (
                        <>
                            <IllustrationImage src={FlyingPaperIllustration} width={130} />
                            <Title>Wir haben dir einen Code geschickt</Title>
                            <Subtitle>Bitte gib den 6-stelligen code ein, den wir dir per E-Mail zugeschickt haben.</Subtitle>
                            <ButtonContainer>
                                <SixDigitCodeInput onChange={setVerificationCode} />
                                <div style={{ marginTop: 24 }}>
                                    <CTAButton
                                        variant="primary"
                                        size={isMobile ? "M" : "L"}
                                        label="Passwort zurücksetzen"
                                        loading={isLoading}
                                        onClick={handleCheckCode}
                                        fullWidth
                                    />
                                </div>
                            </ButtonContainer>
                        </>
                    )
                ) : (
                    <>
                        <IllustrationImage src={FlyingCloudIllustration} width={260} />
                        <Title>Passwort vergessen? Kein Problem!</Title>
                        <Subtitle>Gib einfach deine registrierte E-Mail Adresse ein, und wir senden dir instruktionen, dein Passwort zurückzusetzen.</Subtitle>
                        <ButtonContainer>
                            <CustomInputField
                                id="email"
                                placeholder="Deine E-Mail Adresse"
                                type="email"
                                value={email}
                                errorMessage={emailError}
                                onClear={() => setEmail("")}
                                onChange={(value: string) => {
                                    setEmail(value);
                                    setEmailError("");
                                }}
                            />
                            {resetPasswordError && (
                                <p className="ms-2 mb-4 text-danger">{resetPasswordError}</p>
                            )}
                            <div style={{ marginTop: 24 }}>
                                <CTAButton
                                    variant="primary"
                                    size={isMobile ? "M" : "L"}
                                    label="Passwort zurücksetzen"
                                    loading={isLoading}
                                    onClick={handleForgotPassword}
                                    fullWidth
                                />
                            </div>
                        </ButtonContainer>
                    </>
                )}
            </ContainerCard>
        </StyledContainer>
    );
};

export default ResetPasswordPage;
