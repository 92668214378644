import React from 'react';
import {
    ModalBody,
    SectionTitle,
    ItemRow,
    ItemDescription,
    ItemCost,
    SumTotal,
    Header,
    HeaderSubtitle,
    HeaderTitle,
    ModalContainer,
    Overlay,
    CloseButton,
    ContentWrapper,
    ItemSumCost,
    SectionBigTitle,
    CostWrapper,
    MinCostText,
} from './pricing-modal.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';

interface PricingModalProps {
    type: "property-management" | "handover";
    onClose: () => void;
}

const PricingModal: React.FC<PricingModalProps> = ({ type, onClose }) => {
    return (
        <Overlay onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <Header>
                    <div>
                        <HeaderTitle>Kostenübersicht</HeaderTitle>
                        <HeaderSubtitle>
                            {type === "property-management" ? "Premium-Paket" : "Handover- Support für Eigennutzer"}
                        </HeaderSubtitle>
                    </div>
                    <CloseButton onClick={onClose}>
                        <MaterialIcon icon="close" size={24} color={colorPalette.blue500} />
                    </CloseButton>
                </Header>
                <ModalBody>
                    <ContentWrapper>
                        <SectionTitle>Kosten vor Handover (Einmalig bei Vertragsabschluss)</SectionTitle>
                        <ItemDescription>Gebühren</ItemDescription>
                        <p>
                            Administrative Gebühren um dich beim Handover deiner Wohnung zu unterstützen,
                            dich im gesetzlichen Rahmen gegenüber Mietern zu vertreten und deine Mieteinnahmen zu verwalten.
                        </p>
                        <ItemRow>
                            <span>Handover Fee</span>
                            <ItemCost>AED 1.000</ItemCost>
                        </ItemRow>
                        {type === "property-management" && (
                            <ItemRow>
                                <span>POA & Legal Fees</span>
                                <ItemCost>AED 5.000</ItemCost>
                            </ItemRow>
                        )}
                        <SumTotal>
                            <span>Summe Gebühren (Einmalig)</span>
                            <ItemSumCost>
                                {type === "property-management" ? "AED 6.000" : "AED 1.000"}
                            </ItemSumCost>
                        </SumTotal>
                        {type === "property-management" && (
                            <>
                                <SectionTitle style={{ marginTop: 24 }}>Kaution Mietermanagement</SectionTitle>
                                <p>
                                    Die Kaution für das Mietermanagement wird für das schnelle Eingreifen in Notfällen einbehalten
                                    (z.B. Wasserschaden), um schnell Handeln zu können und weitere Schäden zu verhindern.
                                </p>
                                <ItemRow>
                                    <span>Security Deposit</span>
                                    <ItemCost>AED 2.000</ItemCost>
                                </ItemRow>
                            </>
                        )}
                        <SectionTitle style={{ marginTop: 24 }}>Kautionen Versorgungsunternehmen</SectionTitle>
                        <p>
                            Die Kautionen werden direkt an den jeweiligen Versorger weitergeleitet, um die Wohnung dort zu registrieren.
                            Das ist notwendig, damit die Wohnung mit Strom, Wasser & Klimaanlage versorgt wird.
                        </p>
                        <ItemRow>
                            <span>DEWA (Strom & Wasser) Deposit</span>
                            <ItemCost>AED 2.000</ItemCost>
                        </ItemRow>
                        <ItemRow>
                            <span>Empower (Klimaanlage) Deposit</span>
                            <ItemCost>AED 2.130</ItemCost>
                        </ItemRow>
                        <SumTotal>
                            <span>Summe Kautionen (Einmalig)</span>
                            <ItemSumCost>
                                {type === "property-management" ? "AED 6.130" : "AED 4.130"}
                            </ItemSumCost>
                        </SumTotal>
                        {type === "property-management" && (
                            <>
                                <SectionBigTitle style={{ marginTop: 24 }}>Kosten Mietermanagement (jährlich)</SectionBigTitle>
                                <ItemRow>
                                    <span>Gebühr Mietermanagement (Premium)</span>
                                    <CostWrapper>
                                        <ItemCost>7% der Jahresmiete</ItemCost>
                                        <MinCostText>Min. AED 4.000</MinCostText>
                                    </CostWrapper>
                                </ItemRow>
                            </>
                        )}
                    </ContentWrapper>
                </ModalBody>
            </ModalContainer>
        </Overlay>
    );
};

export default PricingModal;
