import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const SidebarContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isVisible",
})<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: ${colorPalette.blue100};
  z-index: 1045;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 992px) {
    left: auto;
    right: 0;
    width: 100%;
    background-color: ${colorPalette.white};
    transform: ${({ isVisible }) =>
      isVisible ? "translateX(0)" : "translateX(100%)"};
  }

  @media (min-width: 992px) {
    transform: translateX(0);
  }
`;

export const SidebarHeader = styled.div`
  height: 70px;
  line-height: 70px;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colorPalette.blue200};
  }
`;

export const NavItem = styled.li.withConfig({
  shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isDeactivated",
})<{ isActive: boolean; isDeactivated: boolean }>`
  list-style-type: none;
  background-color: ${({ isActive }) =>
    isActive ? colorPalette.blue200 : "transparent"};

  button {
    color: ${({ isDeactivated }) =>
      isDeactivated ? colorPalette.blue800 : colorPalette.blue800} !important;
    ${typeScale.bodyMD}
    display: flex;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    padding: 24px 16px;
    gap: 12px;
    opacity: ${({ isDeactivated }) => (isDeactivated ? "0.3" : "1")};
    cursor: ${({ isDeactivated }) => (isDeactivated ? "default" : "pointer")};
  }

  &:hover {
    opacity: ${({ isActive, isDeactivated }) =>
      !isActive && !isDeactivated ? "0.7" : "1"};
  }
`;

export const LogoImage = styled.img`
  width: 125px;
  cursor: pointer;
`;
