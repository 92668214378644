import React, { useCallback, useEffect, useState } from 'react';
import {
    SidebarModalBackdrop,
    SidebarModal,
    SidebarModalHeadline,
    SidebarModalDivider,
    SidebarModalScrollableContent,
    HeadlineContainer,
    SidebarModalHeader
} from '../sidemodal.styles';
import MaterialIcon from '../../../components/material-icon';
import { colorPalette } from '../../../utils/theme';
import { IDocument } from '../../../services/documentService';
import CTAButton from '../../../components/cta-button/cta-button';
import { ContentHeaderTitle, ContentHeaderSubtitle, CategoryHeadline, ContractDetailItem, ContractDetailLabel, ContractDetailValue, ContentHeader, ContractDetails, Info } from './purchase-contract-sidemodal.styles';
import { formatMoney } from '../../../utils/helpers';
import { downloadUnitDocument } from '../../../services/unitService';
import useAlertStore from '../../../stores/alertStore';
import { usePWA } from '../../../context/PWAContext';

interface PurchaseContractSidebarModalProps {
    purchaseDocument?: IDocument;
    isVisible: boolean;
    onClose: () => void;
}

const PurchaseContractSidebarModal: React.FC<PurchaseContractSidebarModalProps> = ({
    purchaseDocument,
    isVisible,
    onClose
}) => {
    // Stores
    const isPWA = usePWA();
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isDownloading, setIsDownloading] = useState(false);

    // Prevent scrolling down
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        const preventDefault = (e: TouchEvent) => {
            e.preventDefault();
        };

        document.addEventListener('touchmove', preventDefault, { passive: false });

        return () => {
            document.body.style.overflow = 'unset';
            document.removeEventListener('touchmove', preventDefault);
        };
    }, []);

    // Functions
    const onClickDownload = useCallback(async () => {
        if (!purchaseDocument || !purchaseDocument.unit) return;

        try {
            setIsDownloading(true);
            let blob: Blob | null = await downloadUnitDocument(purchaseDocument.unit.unit_id, purchaseDocument.document_id);
            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);

                const anchor = document.createElement('a');
                anchor.href = blobUrl;
                anchor.download = purchaseDocument.file_name;
                document.body.appendChild(anchor);
                anchor.click();

                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(anchor);
            }
        } catch (error) {
            console.log("error while downloading purchase contract document: ", error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsDownloading(false);
        }
    }, [purchaseDocument, showAlert]);

    return (
        <SidebarModalBackdrop visible={isVisible} onClick={onClose}>
            <SidebarModal visible={isVisible} isPWA={isPWA}>
                <SidebarModalHeader>
                    <HeadlineContainer>
                        <SidebarModalHeadline>Kaufvertrag</SidebarModalHeadline>
                    </HeadlineContainer>
                    <MaterialIcon icon="close" size={24} color={colorPalette.blue500} onClick={onClose} />
                </SidebarModalHeader>
                <SidebarModalDivider />
                <SidebarModalScrollableContent withDefaultPadding={false}>
                    <ContentHeader>
                        <MaterialIcon icon="description" size={40} color={colorPalette.textMain} />
                        <ContentHeaderTitle>Kaufvertrag {purchaseDocument?.unit?.unit_number} • {purchaseDocument?.unit?.property.property_name}</ContentHeaderTitle>
                        <ContentHeaderSubtitle color={colorPalette.textMain}>Unterschrieben</ContentHeaderSubtitle>
                        <CTAButton
                            variant="ghost"
                            size="M"
                            icon="file_save"
                            label="Vertrag herunterladen"
                            onClick={onClickDownload}
                            loading={isDownloading}
                            noPadding
                        />
                    </ContentHeader>

                    <CategoryHeadline>Vertragsdetails</CategoryHeadline>

                    {/* CONTRACT DETAIL ITEMS */}
                    <ContractDetails>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="event_available" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Datum</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>{new Date(purchaseDocument?.created_at ?? "").toLocaleDateString()}</ContractDetailValue>
                        </ContractDetailItem>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="shoppingmode" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Kaufpreis</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>AED {formatMoney(purchaseDocument?.unit_owner?.purchase_price ?? 0, false)}</ContractDetailValue>
                        </ContractDetailItem>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="handyman" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Bauträger</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>Metrical Developments</ContractDetailValue>
                        </ContractDetailItem>
                    </ContractDetails>
                    <Info>Die Vertragspartei des Kaufvertrages ist der Bauträger. Bei Fragen zum Kaufvertrag wende dich bitte direkt an Metrical Developments.</Info>
                </SidebarModalScrollableContent>
            </SidebarModal>
        </SidebarModalBackdrop>
    );
};

export default PurchaseContractSidebarModal;
