import styled from "styled-components";
import { colorPalette } from "../../../../../../utils/theme";

export const ProgressContainer = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ProgressTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProgressLabel = styled.span`
  font-weight: bold;
  color: ${colorPalette.textMain};
`;

export const ProgressDescription = styled.span`
  color: ${colorPalette.textMain};
`;

export const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Step = styled.div<{ completed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepIcon = styled.div<{
  completed: boolean;
  actionRequired: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  /* Outer Glow (innermost part of the gradient) */
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${({ completed, actionRequired }) =>
      completed
        ? "rgba(142, 203, 189, 0.1)"
        : actionRequired
        ? "rgba(144, 100, 30, 0.1)"
        : "rgba(23, 34, 77, 0.1)"};
    z-index: 0;
    filter: blur(4px);
  }

  /* Middle Circle */
  &::after {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: ${({ completed, actionRequired }) =>
      completed
        ? "rgba(142, 203, 189, 0.3)"
        : actionRequired
        ? "rgba(144, 100, 30, 0.3)"
        : "rgba(23, 34, 77, 0.3)"};
    z-index: 1;
  }

  /* Inner Circle */
  background: ${({ completed, actionRequired }) =>
    completed
      ? colorPalette.successMedium
      : actionRequired
      ? colorPalette.warningDark
      : colorPalette.blue800};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 2;

  /* Breathing Animation */
  ${({ actionRequired }) =>
    actionRequired &&
    `
    animation: breathing 3s ease-in-out infinite;
  `}

  @keyframes breathing {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
`;

export const StepLine = styled.div<{
  completed: boolean;
  actionRequired: boolean;
  first?: boolean;
  last?: boolean;
}>`
  width: ${({ first, last }) => (first || last ? "100px" : "130px")};
  height: 4px;
  background-color: ${({ completed, actionRequired }) =>
    completed
      ? colorPalette.successMedium
      : actionRequired
      ? colorPalette.warningDark
      : colorPalette.blue300};
  margin: 0 8px;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressPercentage = styled.span`
  margin-left: 16px;
  font-size: 20px;
  font-weight: bold;
  color: ${colorPalette.primaryDark};
`;
