// FinancialsPage.tsx

const FinancialsPage = () => {
    return (
        <div style={{ padding: "12px 16px" }}>
            <h4>Finanzen in Arbeit...</h4>
        </div>
    );
};

export default FinancialsPage;
