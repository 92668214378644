import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const DocumentsContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const HeaderContainer = styled.div`
  padding: 0px 16px;
  margin-top: 48px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Title = styled.h2`
  color: ${colorPalette.primaryDark};
  margin-bottom: 16px;
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.white};
  border: 1px solid #f1f0ed;
  border-radius: 8px;
`;
