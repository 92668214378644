import React from 'react';
import {
    DocumentsContainer,
    DocumentList,
    Title,
    HeaderContainer,
} from './document-section.styles';
import DocumentCard from '../document-card/document-card';
import { IDocument } from '../../services/documentService';
import useIsMobile from '../../hooks/useIsMobile';

interface DocumentSectionProps {
    documents: IDocument[];
    showCategory?: boolean;
    onDownload?: (document: IDocument) => void;
}

const DocumentSection: React.FC<DocumentSectionProps> = ({ documents, showCategory, onDownload }) => {
    const isMobile = useIsMobile();

    return (
        <div>
            <HeaderContainer>
                <Title>{isMobile ? "Dokumente" : "Unit-Dokumente"}</Title>
            </HeaderContainer>
            <DocumentsContainer>
                <DocumentList>
                    {documents.map((document) => (
                        <DocumentCard
                            key={document.document_id}
                            document={document}
                            showCategory={showCategory}
                            onDownload={onDownload}
                        />
                    ))}
                </DocumentList>
            </DocumentsContainer>
        </div>
    );
};

export default DocumentSection;
