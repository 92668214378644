import React, { useState } from 'react';
import {
    CardContainer,
    Header,
    ProgressBar,
    ProgressSegment,
    ProgressStatus,
    ProgressPercentage,
    ProgressDetails,
    DetailRow,
    DetailLabel,
    DetailValue,
    ProgressContainer,
} from './payment-plan-card.styles';
import PaymentPlanModal from '../../modals/payment-plan-modal/payment-plan-modal';
import { formatMoney } from '../../utils/helpers';
import { IPaymentPlanItem } from '../../services/propertyService';
import useIsMobile from '../../hooks/useIsMobile';
import CTAButton from '../cta-button/cta-button';

interface PaymentPlanCardProps {
    paymentPlanItems: IPaymentPlanItem[];
    property_name: string;
    purchase_price?: number;
}

const PaymentPlanCard: React.FC<PaymentPlanCardProps> = ({
    paymentPlanItems,
    purchase_price,
    property_name
}) => {
    const isMobile = useIsMobile();

    const segments = paymentPlanItems.length;
    const currentDate = new Date();

    // Calculate total progress percentage
    const totalPaidPercentage = paymentPlanItems.reduce((acc, item) => {
        const dueDate = new Date(item.due_date);
        return dueDate <= currentDate ? acc + (item.percentage || 0) : acc;
    }, 0);

    // Ensure progress is 100% if all items are already due
    const isAllDue = paymentPlanItems.every(item => new Date(item.due_date) <= currentDate);
    const progress = isAllDue ? 100 : Math.min(totalPaidPercentage, 100);

    const filledSegments = Math.floor(progress / (100 / segments));
    const partialFill = isAllDue ? 0 : ((progress % (100 / segments)) / (100 / segments));

    // Find the next due payment item if any payments are still due
    const nextPaymentItem = paymentPlanItems.find(item => new Date(item.due_date) > currentDate);
    const currentDuePaymentItem = nextPaymentItem || paymentPlanItems[segments - 1];

    // Set next payment details only if there's a future payment due; otherwise set to null
    const nextPaymentAmount = nextPaymentItem
        ? currentDuePaymentItem.fixed_amount || (currentDuePaymentItem.percentage ? (currentDuePaymentItem.percentage / 100) * purchase_price! : 0)
        : null;

    const nextPaymentDate = nextPaymentItem ? new Date(currentDuePaymentItem.due_date).toLocaleDateString() : null;

    // States
    const [isPaymentPlanModalVisible, setIsPaymentPlanModalVisible] = useState(false);

    return (
        <>
            <CardContainer>
                <Header>
                    Payment-Plan
                    <CTAButton
                        variant="ghost"
                        size="S"
                        label={isMobile ? "Details" : "Payment-Plan ansehen"}
                        icon="arrow_outward"
                        onClick={() => setIsPaymentPlanModalVisible(true)} noPadding
                    />
                </Header>
                <ProgressContainer>
                    <ProgressBar>
                        {Array.from({ length: segments }).map((_, index) => (
                            <ProgressSegment
                                key={index}
                                filled={index < filledSegments}
                                partial={index === filledSegments ? partialFill : 0}
                            />
                        ))}
                    </ProgressBar>
                    <ProgressDetails>
                        <ProgressPercentage>{progress}%</ProgressPercentage>
                        {purchase_price ? (
                            <ProgressStatus>
                                <span className="current-amount">
                                    AED {formatMoney((progress * purchase_price) / 100, false)}
                                </span>
                                <span className="divider"> / </span>
                                <span className="total-amount">
                                    AED {formatMoney(purchase_price, false)}
                                </span>
                            </ProgressStatus>
                        ) : (
                            <ProgressStatus>
                                <span className="current-amount">
                                    {currentDuePaymentItem.description}
                                </span>
                            </ProgressStatus>
                        )}
                    </ProgressDetails>
                </ProgressContainer>
                {!isMobile && (
                    <>
                        {purchase_price && (
                            <DetailRow>
                                <DetailLabel>Next Payment</DetailLabel>
                                <DetailValue>{nextPaymentAmount !== null ? `AED ${formatMoney(nextPaymentAmount)}` : "Keine weiteren Payments 🎉"}</DetailValue>
                            </DetailRow>
                        )}
                        <DetailRow>
                            <DetailLabel>Nächste Zahlungsfälligkeit</DetailLabel>
                            <DetailValue>{nextPaymentDate ?? "N/A"}</DetailValue>
                        </DetailRow>
                    </>
                )}
            </CardContainer>

            {isPaymentPlanModalVisible && (
                <PaymentPlanModal
                    completionDate="September 2027"
                    property_name={property_name}
                    paymentPlanItems={paymentPlanItems.sort((b, a) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime())}
                    onClose={() => setIsPaymentPlanModalVisible(false)}
                />
            )}
        </>
    );
};

export default PaymentPlanCard;
