import Lottie from "lottie-react";
import NotFoundAnim from "../../assets/anims/handy-line-cloud-in-lotus-pose-meditating.json";
import Logo from "../../assets/images/logo/logo-multicolor.svg";
import { Container, ContentWrapper, ErrorText, Subtitle, Title } from "./error-page.styles";
import CTAButton from "../../components/cta-button/cta-button";
import Footer from "../../components/footer/footer";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import useIsMobile from "../../hooks/useIsMobile";
import useInvestorDetailStore from "../../stores/investorDetailStore";

export default function ErrorPage() {
    // Hooks
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    // Stores
    const { investor } = useInvestorDetailStore();

    return (
        <Container>
            <img src={Logo} alt="Managd Logo" width={200} style={{ marginTop: 24 }} />
            <ContentWrapper>
                <Lottie animationData={NotFoundAnim} loop={true} style={{ height: "500px", marginBottom: "-70px", marginTop: "-120px" }} />
                <Title>Hoppla, da ist etwas schiefgelaufen.</Title>
                <Subtitle>Die Seite die du suchst ist nicht verfügbar.</Subtitle>
                <CTAButton
                    variant="primary"
                    size="L"
                    label={investor ? "Zum Dashboard" : "Zur Startseite"}
                    onClick={() => navigate("/", { replace: true, clearHistory: true })}
                />
                <ErrorText>Fehler: 404</ErrorText>
            </ContentWrapper>
            {!isMobile && (
                <Footer theme="dark" />
            )}
        </Container>
    );
}
