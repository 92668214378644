import { styled } from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const StyledContainer = styled.div`
  width: 100%;
  height: calc(100 * var(--vh) - 70px); /* Full viewport height */
  overflow-y: auto; /* Enable Y-axis scrolling */
  overflow-x: hidden;

  /* Optional: Customize scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorPalette.blue200};
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const ButtonContainer = styled.div`
  margin: 24px auto 0;
  width: 30%;
  gap: 16px;
  justify-content: center;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const Title = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 768px) {
    ${typeScale.headingSMMain};
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
`;

export const InfoText = styled.p`
  margin-top: 32px;
  margin-bottom: 0;
  text-align: center;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};

  @media (max-width: 768px) {
    ${typeScale.bodyXXS};
    text-align: center;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
`;
