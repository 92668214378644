import React from 'react';
import {
    MainImage,
    GalleryContainer,
    SmallImage
} from './project-pictures.styles';
import { Col, Row } from 'react-bootstrap';

interface ProjectPicturesProps {
    images: string[];
    onClickPicture: () => void;
}

const ProjectPictures: React.FC<ProjectPicturesProps> = ({ images, onClickPicture }) => {
    return (
        <GalleryContainer>
            <Row className="gy-3">
                <Col sm={12} md={6} className="h-100">
                    <MainImage src={images[0]} alt="Property Image 1" onClick={onClickPicture} />
                </Col>

                <Col sm={12} md={6} className="h-100">
                    <Row className="gy-3">
                        {images.slice(1, 4).map((image, index) => (
                            <Col sm={6} key={index} className="h-100">
                                <SmallImage src={image} alt={`Property Image ${index + 2}`} />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </GalleryContainer>
    );
};

export default ProjectPictures;
