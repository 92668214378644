import React, { useCallback } from 'react';
import {
    FooterContainer,
    FooterLink,
    FooterLinksWrapper,
    FooterCopyright,
    FooterLogo,
    FooterContent
} from './footer.styles';
import LogoDark from '../../assets/images/logo/logo-dark.svg';
import LogoLight from '../../assets/images/logo/logo-light.svg';
import LogoMulticolor from '../../assets/images/logo/logo-multicolor.svg';

interface FooterProps {
    theme?: 'dark' | 'light' | 'white';
}

const Footer: React.FC<FooterProps> = ({ theme = 'white' }) => {
    const getLogo = useCallback(() => {
        if (theme === "dark") return LogoLight;
        else if (theme === "light") return LogoDark;
        return LogoMulticolor;
    }, [theme]);

    return (
        <FooterContainer themeVariant={theme}>
            <FooterContent themeVariant={theme}>
                <FooterLogo src={getLogo()} alt="Managd Logo" />
                <FooterLinksWrapper>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>About</FooterLink>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Services</FooterLink>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Contact</FooterLink>
                    <FooterLink href="/faq" target="_blank" rel="noopener noreferrer" themeVariant={theme}>FAQ</FooterLink>
                    <FooterLink href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" themeVariant={theme}>AGB's</FooterLink>
                    <FooterLink href="/privacy-policy" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Datenschutzerklärung</FooterLink>
                </FooterLinksWrapper>
            </FooterContent>
            <FooterCopyright themeVariant={theme}>
                © 2025 - Managd Property Management LLC
            </FooterCopyright>
        </FooterContainer>
    );
};

export default Footer;
