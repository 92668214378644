import React from 'react';
import {
    CardContainer,
    Title,
    Description,
    ButtonContainer,
    List,
    ListItem,
    ListItemText,
    ListItemDescription,
    CheckIconContainer
} from './pm-upselling-card.styles';
import CTAButton from '../../../../components/cta-button/cta-button';
import { colorPalette } from '../../../../utils/theme';
import HouseKeys from '../../../../assets/images/house_keys.png';
import MaterialIcon from '../../../../components/material-icon';
import useIsMobile from '../../../../hooks/useIsMobile';

const PMUpSellingCard: React.FC = () => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <CardContainer>
                <div className="d-flex align-items-center" style={{ gap: 16, padding: "0px 16px" }}>
                    <img src={HouseKeys} width={67} height={52} alt="House keys" />
                    <div>
                        <Title>Hands-Off Property Management</Title>
                        <Description>
                            Entscheide dich, deine Unit von managd verwalten zu lassen .
                        </Description>
                    </div>
                </div>
                <List>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.blue500} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Verwaltung von Mietverträgen</ListItemText>
                            <ListItemDescription>
                                Wir kümmern uns um alles - von Verträgen und Verlängerungserinnerungen bis zur Unterstützung beim Ein- und Auszug.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.blue500} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Tägliche Vertretung vor Ort</ListItemText>
                            <ListItemDescription>
                                Wir kümmern uns um alle Wünsche und Probleme der Mieter und lösen sie.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.blue500} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Werbung auf Vermietungsplattformen</ListItemText>
                            <ListItemDescription>
                                Wir entsenden ein Inspektionsteam, holen Angebote von vertrauenswürdigen Anbietern ein und überwachen deren Arbeit.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                </List>
                <ButtonContainer>
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Erfahre mehr"
                        onClick={() => {
                            alert("Coming soon...");
                        }}
                        fullWidth={isMobile}
                    />
                </ButtonContainer>
            </CardContainer>
        );
    } else {
        return (
            <CardContainer>
                <img src={HouseKeys} width={170} alt="House Keys" />
                <Title>Hands-Off Property Management</Title>
                <Description>
                    Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht.
                </Description>
                <List>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.primaryDark} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Verwaltung von Mietverträgen</ListItemText>
                            <ListItemDescription>
                                Wir kümmern uns um alles - von Verträgen und Verlängerungserinnerungen bis zur Unterstützung beim Ein- und Auszug.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.primaryDark} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Tägliche Vertretung vor Ort</ListItemText>
                            <ListItemDescription>
                                Wir kümmern uns um alle Wünsche und Probleme der Mieter und lösen sie.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.primaryDark} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Werbung auf Vermietungsplattformen</ListItemText>
                            <ListItemDescription>
                                Wir entsenden ein Inspektionsteam, holen Angebote von vertrauenswürdigen Anbietern ein und überwachen deren Arbeit.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                </List>
                <ButtonContainer>
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Paket Auswählen"
                        onClick={() => {
                            alert("Coming soon...");
                        }}
                        fullWidth={isMobile}
                    />
                    <CTAButton
                        variant="secondary"
                        size="L"
                        label="Weitere Informationen"
                        onClick={() => {
                            alert("Coming soon...");
                        }}
                        fullWidth={isMobile}
                    />
                </ButtonContainer>
            </CardContainer>
        );
    }
};

export default PMUpSellingCard;
