import React from 'react';
import {
    Container,
    Card,
    Content,
    Headline,
    CardItem,
    LeftContent,
    Unit,
    Status,
    Date,
} from './mobile-document-card.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { DocumentType, formatDocumentType, IDocument } from '../../../../services/documentService';

export interface IDocumentTableItem {
    document: IDocument;
    unit: string;
    date: string;
    status: 'active' | 'inactive' | 'signed' | 'waiting_for_signature';
}

interface MobileDocumentCardProps {
    className?: string | undefined;
    headline: string;
    documents: IDocumentTableItem[];
    onClickDocument: (document: IDocument) => void;
}

const MobileDocumentCard: React.FC<MobileDocumentCardProps> = ({ className, headline, documents, onClickDocument }) => {
    // Functions
    const getStatusColor = (status: 'active' | 'inactive' | 'signed' | 'waiting_for_signature') => {
        if (status === "active") return colorPalette.successMedium;
        else if (status === "inactive") return colorPalette.blue300;
        else if (status === "waiting_for_signature") return colorPalette.warningMedium;
        else if (status === "signed") return colorPalette.textMain;
        return colorPalette.textDark;
    }

    const getStatusName = (status: 'active' | 'inactive' | 'signed' | 'waiting_for_signature') => {
        if (status === "active") return "Aktiv";
        else if (status === "inactive") return "Ausgelaufen";
        else if (status === "waiting_for_signature") return "Warten auf Unterschrift";
        else if (status === "signed") return "Unterschrieben";
        return "Unbekannt";
    }

    return (
        <Container className={className}>
            <Headline>{headline}</Headline>
            <Card>
                {documents.map((doc, index) => (
                    <CardItem key={index} onClick={() => onClickDocument(doc.document)}>
                        <LeftContent>
                            {doc.document.document_type === DocumentType.PROPERTY_MANAGEMENT_CONTRACT ? (
                                <MaterialIcon icon="task" size={24} color={getStatusColor(doc.status)} />
                            ) : doc.document.document_type === DocumentType.TENANCY_CONTRACT ? (
                                <MaterialIcon icon="contact_page" size={24} color={getStatusColor(doc.status)} />
                            ) : (
                                <MaterialIcon icon="description" size={24} color={getStatusColor(doc.status)} />
                            )}
                            <Content>
                                <Date>{doc.date}</Date>
                                <Unit>{doc.unit}</Unit>
                                <Status color={getStatusColor(doc.status)}>
                                    {formatDocumentType(doc.document.document_type ?? null)} • <span>{getStatusName(doc.status)}</span>
                                </Status>
                            </Content>
                        </LeftContent>
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </CardItem>
                ))}
            </Card>
        </Container>
    );
};

export default MobileDocumentCard;
