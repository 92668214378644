import React, { useEffect, useState } from 'react';
import {
    FAQSectionContainer,
    FAQHeader,
    FAQList,
    FAQItem,
    Question,
    Answer,
    IconWrapper,
    CollapseIcon,
    HeaderContent,
    HeaderIconWrapper,
} from './faqs.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';
import DOMPurify from 'dompurify';
import { FAQCategoryItem } from '../faq-categories/faq-categories';

export interface FAQItem {
    id: number;
    question: string;
    answer: string;
}

interface FAQSProps {
    faqCategory: FAQCategoryItem;
    faqs: FAQItem[];
    showButton?: boolean;
    onClickButton?: () => void;
    isExpanded?: boolean; // New prop to control initial expand/collapse state
}

const FAQS: React.FC<FAQSProps> = ({ faqCategory, faqs, showButton = false, onClickButton, isExpanded = false }) => {
    const isMobile = useIsMobile();

    // States
    const [expandedFAQ, setExpandedFAQ] = useState<number | null>(null);
    const [isCollapsed, setIsCollapsed] = useState(!isExpanded); // Initialize with `!isExpanded`

    // Effect to update collapse state when `isExpanded` prop changes
    useEffect(() => {
        setIsCollapsed(!isExpanded);
    }, [isExpanded]);

    // Functions
    const toggleFAQ = (id: number) => {
        setExpandedFAQ(expandedFAQ === id ? null : id);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <FAQSectionContainer>
            <FAQHeader expanded={!isCollapsed} onClick={toggleCollapse}>
                <HeaderContent>
                    <HeaderIconWrapper>
                        <MaterialIcon icon={faqCategory.icon} size={36} color={colorPalette.blue400} />
                    </HeaderIconWrapper>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <h2>{faqCategory.title}</h2>
                            <p>{faqCategory.description}</p>
                        </div>
                        <CollapseIcon>
                            <MaterialIcon
                                icon={isCollapsed ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                                size={24}
                                color={colorPalette.blue500}
                            />
                        </CollapseIcon>
                    </div>
                </HeaderContent>
            </FAQHeader>
            {!isCollapsed && (
                <FAQList>
                    {faqs.map((faq) => (
                        <FAQItem key={faq.id} onClick={() => toggleFAQ(faq.id)}>
                            <Question>
                                {faq.question}
                                <IconWrapper>
                                    <MaterialIcon
                                        icon={expandedFAQ === faq.id ? "remove" : "add"}
                                        size={24}
                                        color={colorPalette.blue500}
                                    />
                                </IconWrapper>
                            </Question>
                            {expandedFAQ === faq.id && (
                                <Answer dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }} />
                            )}
                        </FAQItem>
                    ))}
                </FAQList>
            )}
            {showButton && onClickButton && (
                <CTAButton
                    variant="ghost"
                    size="S"
                    label={isMobile ? "Alle Fragen" : "Alle Fragen anzeigen"}
                    icon="arrow_outward"
                    onClick={onClickButton}
                    noPadding
                />
            )}
        </FAQSectionContainer>
    );
};

export default FAQS;
