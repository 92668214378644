import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

const PWAContext = createContext<boolean>(false);

interface PWAProviderProps {
    children: ReactNode;
}

export const PWAProvider: React.FC<PWAProviderProps> = ({ children }) => {
    const [isPWA, setIsPWA] = useState<boolean>(false);

    useEffect(() => {
        const checkIfPWA = window.matchMedia('(display-mode: standalone)').matches;
        setIsPWA(checkIfPWA);
    }, []);

    return (
        <PWAContext.Provider value={isPWA}>
            {children}
        </PWAContext.Provider>
    );
};

export const usePWA = () => {
    return useContext(PWAContext);
};
