import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const SectionContainer = styled.div`
  background-color: ${colorPalette.white};
  padding: 64px 180px;
  text-align: center;
  border-bottom: 1px solid ${colorPalette.blue200};
`;

export const Title = styled.h2`
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  color: ${colorPalette.textDark};
  ${typeScale.bodyMDMain}
  margin-bottom: 32px;
`;

export const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ServiceCard = styled.div`
  background-color: ${colorPalette.blue200};
  border-radius: 8px;
  text-align: left;
  padding: 16px;
  gap: 16px;
  border: 1px solid var(--Blue-200, #ebf1f7);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }
`;

export const ServiceIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const ServiceCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const ServiceTitle = styled.h3`
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}
  margin-bottom: 8px;
`;

export const ServiceDescription = styled.p`
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
  opacity: 0.8;
`;
