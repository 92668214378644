// ProfileEditAddresDetailsPage.tsx
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";
import useInvestorDetailStore from "../../../../stores/investorDetailStore";
import CTAButton from "../../../../components/cta-button/cta-button";
import { useNavigate } from "react-router-dom";
import CustomInputField from "../../../../components/custom-input-field/custom-input-field";
import {
    StyledContainer,
    HeaderContainer,
    Title,
    HeaderLeftContent,
    FormSection,
    CategoryTitle,
    ButtonCol
} from "./edit-address-details-page.styles";
import CustomSelectField, { CustomSelectFieldOptions } from "../../../../components/custom-select-field/custom-select-field";
import { IAddress, updateAddress } from "../../../../services/addressService";
import useAlertStore from "../../../../stores/alertStore";
import { updateInvestor } from "../../../../services/investorService";
import { allCountries } from 'country-region-data';

import countries from 'i18n-iso-countries';
import deLocale from 'i18n-iso-countries/langs/de.json';
import useIsMobile from "../../../../hooks/useIsMobile";
countries.registerLocale(deLocale);

const ProfileEditAddresDetailsPage = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const investor = useInvestorDetailStore(state => state.investor);
    const setInvestor = useInvestorDetailStore(state => state.setInvestor);

    // States
    const [isLoading, setIsLoading] = useState(false);

    // Name
    const [firstname, setFirstname] = useState(investor?.first_name ?? "");
    const [lastname, setLastname] = useState(investor?.last_name ?? "");
    const [birthdate, setBirthdate] = useState(investor?.birth_date ?? "");

    // Contact
    const [email, setEmail] = useState(investor?.email ?? "");
    const [phoneNumber, setPhoneNumber] = useState(investor?.phone_number ?? "");

    // Address
    const [countryOptions, setCountryOptions] = useState<CustomSelectFieldOptions[]>([]);
    const [stateOptions, setStateOptions] = useState<CustomSelectFieldOptions[]>([]);

    const [country, setCountry] = useState(investor?.address.country ?? "");
    const [state, setState] = useState(investor?.address.state_province_region ?? "");
    const [city, setCity] = useState(investor?.address.city ?? "");
    const [postalCode, setPostalCode] = useState(investor?.address.postal_code ?? "");
    const [street1, setStreet1] = useState(investor?.address.street_address1 ?? "");
    const [street2, setStreet2] = useState(investor?.address.street_address2 ?? "");

    // Error States
    const [emailError, setEmailError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [countryError, setCountryError] = useState("");

    const [initialValues, setInitialValues] = useState({
        email: "",
        phoneNumber: "",
        country: "DE",
        state: "",
        street1: "",
        street2: "",
        city: "",
        postalCode: ""
    });

    // Effects
    useEffect(() => {
        const countryList = Object.entries(countries.getNames("de")).map(([code, name]) => ({
            label: name,
            value: code
        }));
        setCountryOptions(countryList);

        if (investor) {
            setFirstname(investor.first_name);
            setLastname(investor.last_name);
            setBirthdate(investor.birth_date ?? "");

            setEmail(investor.email);
            setPhoneNumber(investor.phone_number ?? "");

            const country = countryList.find(country => country.label === investor.address.country);
            setCountry(country ? country.value : "DE");

            setState(investor.address.state_province_region ?? "");
            setStreet1(investor.address.street_address1 ?? "");
            setStreet2(investor.address.street_address2 ?? "");
            setPostalCode(investor.address.postal_code ?? "");
            setCity(investor.address.city ?? "");

            setInitialValues({
                email: investor.email,
                phoneNumber: investor.phone_number ?? "",
                country: country ? country.label : "DE",
                state: investor.address.state_province_region ?? "",
                street1: investor.address.street_address1 ?? "",
                street2: investor.address.street_address2 ?? "",
                city: investor.address.city ?? "",
                postalCode: investor.address.postal_code ?? ""
            });
        }
    }, [investor]);

    useEffect(() => {
        const states = allCountries.find(
            (c) => c[1] === country
        )?.[2].map(region => ({
            label: region[0],
            value: region[0]
        })) ?? [];
        setStateOptions(states);

        if (state.length === 0) {
            setState(states.length > 0 ? states[0].label : "");
        }
    }, [state, country]);

    // Memos
    const hasContactChanges = useMemo(() => {
        return (
            email !== initialValues.email ||
            phoneNumber !== initialValues.phoneNumber
        );
    }, [email, phoneNumber, initialValues]);

    const hasAddressChanges = useMemo(() => {
        const countryItem = countryOptions.find(item => item.value === country);
        const countryLabel = countryItem ? countryItem.label : "Deutschland";

        return (
            countryLabel !== initialValues.country ||
            state !== initialValues.state ||
            street1 !== initialValues.street1 ||
            street2 !== initialValues.street2 ||
            postalCode !== initialValues.postalCode ||
            city !== initialValues.city
        );
    }, [countryOptions, country, state, street1, street2, postalCode, city, initialValues]);

    // Functions
    const onClickSave = useCallback(async () => {
        if (!investor) return;

        let isValid = true;

        // Check if the email is not empty
        if (!email.trim()) {
            setEmailError("E-Mail muss angegeben sein");
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email.trim())) {
            setEmailError("E-Mail ist ungültig");
            isValid = false;
        }

        // Check if the phone number is not empty
        if (!phoneNumber?.trim()) {
            setPhoneNumberError("Handynummer muss angegeben sein");
            isValid = false;
        }

        // Check if the country is not empty
        if (!country.trim()) {
            setCountryError("Land muss angegeben sein");
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);

                const updatedInvestor = { ...investor };

                if (hasContactChanges) {
                    await updateInvestor(email, phoneNumber);
                    updatedInvestor.email = email;
                    updatedInvestor.phone_number = phoneNumber;
                }

                if (hasAddressChanges) {
                    const updatedAddress = await updateAddress({
                        address_id: investor.address.address_id,
                        country: countryOptions.find(item => item.value === country)?.label ?? "Deutschland",
                        state_province_region: state,
                        street_address1: street1,
                        street_address2: street2,
                        postal_code: postalCode,
                        city
                    } as IAddress);

                    if (updatedAddress) {
                        updatedInvestor.address = updatedAddress;
                    }
                }

                setInvestor(updatedInvestor);
                showAlert("success", "Kontakt & Addresse erfolgreich gespeichert!");
            } catch (error) {
                console.log("error while creating/updating contact & address:", error);

                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [hasContactChanges, hasAddressChanges, investor, email, phoneNumber, country, state, street1, street2, postalCode, city]);

    return (
        <StyledContainer>
            {investor && (
                <Row>
                    <Col xs={12} lg={10} xl={8}>
                        <HeaderContainer>
                            <HeaderLeftContent>
                                {!isMobile && (
                                    <MaterialIcon icon="arrow_back" size={24} color={colorPalette.textMain} onClick={() => navigate(-1)} />
                                )}
                                <Title>Persönliche Daten ändern</Title>
                            </HeaderLeftContent>
                        </HeaderContainer>

                        <FormSection className="gy-3">
                            {/* NAME SECTION */}
                            <Col xs={12}>
                                <CategoryTitle>Name</CategoryTitle>
                            </Col>
                            <Col xs={12} xl={6}>
                                <CustomInputField
                                    id="investor-firstname"
                                    type="text"
                                    value={firstname}
                                    placeholder="Vorname"
                                    onChange={setFirstname}
                                    onClear={() => setFirstname("")}
                                    disabled
                                />
                            </Col>
                            <Col xs={12} xl={6}>
                                <CustomInputField
                                    id="investor-lastname"
                                    type="text"
                                    value={lastname}
                                    placeholder="Nachname"
                                    onChange={setLastname}
                                    onClear={() => setLastname("")}
                                    disabled
                                />
                            </Col>
                            <Col xs={12} xl={6}>
                                <CustomInputField
                                    id="investor-birthdate"
                                    type="text"
                                    value={birthdate}
                                    placeholder="Geburtsdatum"
                                    subtitle="Wenn du deine persönlichen Daten ändern möchtest, kontaktiere uns."
                                    onChange={setBirthdate}
                                    onClear={() => setBirthdate("")}
                                    disabled
                                />
                            </Col>

                            {/* CONTACT SECTION */}
                            <Col xs={12}>
                                <CategoryTitle>Kontakt</CategoryTitle>
                            </Col>
                            <Col xs={12} xl={6}>
                                <CustomInputField
                                    id="investor-email"
                                    type="email"
                                    value={email}
                                    placeholder="E-Mail"
                                    errorMessage={emailError}
                                    onChange={(value) => {
                                        setEmail(value);
                                        if (value.trim().length > 0) {
                                            setEmailError("");
                                        } else {
                                            setEmailError("E-Mail muss angegeben sein");
                                        }
                                    }}
                                    onClear={() => {
                                        setEmail("");
                                        setEmailError("");
                                    }}
                                    required
                                />
                            </Col>
                            <Col xs={12} xl={6}>
                                <CustomInputField
                                    id="investor-phone-number"
                                    type="text"
                                    value={phoneNumber}
                                    placeholder="Handynummer"
                                    errorMessage={phoneNumberError}
                                    onChange={(value) => {
                                        setPhoneNumber(value);
                                        if (value.trim().length > 0) {
                                            setPhoneNumberError("");
                                        } else {
                                            setPhoneNumberError("Handynummer muss angegeben sein");
                                        }
                                    }}
                                    onClear={() => {
                                        setPhoneNumber("");
                                        setPhoneNumberError("");
                                    }}
                                    required
                                />
                            </Col>

                            {/* ADDRESS SECTION */}
                            <Col xs={12}>
                                <CategoryTitle>Anschrift</CategoryTitle>
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomSelectField
                                    id="investor-address-country"
                                    options={countryOptions}
                                    value={country}
                                    placeholder="Wohnsitz/Land"
                                    errorMessage={countryError}
                                    onChange={(value) => {
                                        setCountry(value);
                                        if (value.trim().length > 0) {
                                            setCountryError("");
                                        } else {
                                            setCountryError("Land muss angegeben sein");
                                        }
                                    }}
                                    required
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomSelectField
                                    id="investor-address-state"
                                    options={stateOptions}
                                    value={state}
                                    placeholder="Bundesland/Provinz"
                                    onChange={setState}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-address-street1"
                                    type="text"
                                    value={street1}
                                    placeholder="Straße & Hausnummer"
                                    onChange={setStreet1}
                                    onClear={() => setStreet1("")}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-address-street2"
                                    type="text"
                                    value={street2}
                                    placeholder="Büro/Appartment"
                                    onChange={setStreet2}
                                    onClear={() => setStreet2("")}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-address-postalcode"
                                    type="text"
                                    value={postalCode}
                                    placeholder="Postleitzahl"
                                    onChange={setPostalCode}
                                    onClear={() => setPostalCode("")}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-address-city"
                                    type="text"
                                    value={city}
                                    placeholder="Stadt"
                                    onChange={setCity}
                                    onClear={() => setCity("")}
                                />
                            </Col>

                            {/* BUTTONS */}
                            <ButtonCol xs={12} sm={6}>
                                <CTAButton
                                    variant="primary"
                                    size="L"
                                    label="Änderungen speichern"
                                    onClick={onClickSave}
                                    loading={isLoading}
                                    disabled={(!hasContactChanges && !hasAddressChanges) || isLoading}
                                    fullWidth
                                />
                            </ButtonCol>
                            <ButtonCol xs={12} sm={6}>
                                <CTAButton
                                    variant="secondary"
                                    size="L"
                                    label="Abbrechen"
                                    onClick={() => navigate(-1)}
                                    fullWidth
                                />
                            </ButtonCol>
                        </FormSection>
                    </Col>
                </Row>
            )}
        </StyledContainer>
    );
};

export default ProfileEditAddresDetailsPage;
