import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container, Header, HeaderTitle, MobileUnitCard, MobileUnitCardContent, UnitRentalContainer, UnitStatistic, UnitStatisticLabel, UnitStatistics, UnitTagsContainer, UnitTitle } from './rented-units-section.styles';
import useIsMobile from '../../../../hooks/useIsMobile';
import { IUnit } from '../../../../services/unitService';
import LazyLoadImage from '../../../../components/lazy-load-image/lazy-load-image';
import { SPACES_ENDPOINT } from '../../../../utils/constants';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import Tag from '../../../../components/tag/tag';
import { formatMoney, getActiveLease } from '../../../../utils/helpers';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import UnitCard from './components/unit-card/unit-card';
import CTAButton from '../../../../components/cta-button/cta-button';
import MyUnitsCard from '../../../my-units-page/components/unit-card/unit-card';

interface RentedUnitsSectionProps {
    units: IUnit[];
}

const RentedUnitsSection: React.FC<RentedUnitsSectionProps> = ({ units }) => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    const onClickUnit = (unitId: number) => {
        navigate(`/my-units/${unitId}`);
    };

    const onClickUnits = () => {
        navigate(`/my-units`);
    };

    return (
        <Container>
            <Header>
                <div className="d-flex align-items-center" style={{ gap: 4 }} onClick={isMobile ? () => {
                    navigate("/my-units");
                } : undefined}>
                    <HeaderTitle>Einheiten in der Vermietung</HeaderTitle>
                    <MaterialIcon className="d-md-none d-inline" icon="chevron_forward" size={24} color={colorPalette.blue500} />
                </div>
                {!isMobile && (
                    <CTAButton
                        variant="ghost"
                        size="S"
                        label="Alle Einheiten"
                        icon="arrow_outward"
                        onClick={onClickUnits}
                        noPadding
                    />
                )}
            </Header>
            <Row className={isMobile ? "gy-2" : "gy-3"}>
                {units.map((unitItem) => (
                    isMobile ? (
                        <Col xs={12} md={6} xxl={4} key={unitItem.unit_id}>
                            <MobileUnitCard onClick={() => onClickUnit(unitItem.unit_id)}>
                                <LazyLoadImage
                                    src={unitItem.pictures.length > 0 ? `${SPACES_ENDPOINT}${unitItem.pictures[0].picture_path}` : ""}
                                    alt={unitItem.unit_number}
                                    width={"40%"}
                                    maxHeight={150}
                                />
                                <MobileUnitCardContent>
                                    <UnitTitle>{unitItem.unit_number} • {unitItem.property.property_name}</UnitTitle>
                                    <UnitStatistics>
                                        <UnitStatistic>
                                            <MaterialIcon icon="bed" size={16} color={colorPalette.blue300} />
                                            <UnitStatisticLabel>{unitItem.bedroom} Bedroom</UnitStatisticLabel>
                                        </UnitStatistic>
                                        <UnitStatistic>
                                            <MaterialIcon icon="straighten" size={16} color={colorPalette.blue300} />
                                            <UnitStatisticLabel>{unitItem.square_feet} Sqft</UnitStatisticLabel>
                                        </UnitStatistic>
                                    </UnitStatistics>
                                    <UnitTagsContainer>
                                        <Tag label="Vermietet" type="unitStatus" variant="rented" size="S" />
                                    </UnitTagsContainer>
                                    <UnitRentalContainer>
                                        <p>AED {formatMoney(getActiveLease(unitItem.leases)?.annual_rent_amount ?? 0, false)}</p>
                                        <span>/Jahr</span>
                                    </UnitRentalContainer>
                                </MobileUnitCardContent>
                            </MobileUnitCard>
                        </Col>
                    ) : (
                        <>
                            {units.length === 1 ? (
                                <Col xs={12} key={unitItem.unit_id}>
                                    <MyUnitsCard
                                        imageSrc={unitItem.pictures.length > 0 ? unitItem.pictures[0].picture_path : ""}
                                        propertyName={unitItem.property.property_name}
                                        title={unitItem.unit_number}
                                        onClick={() => onClickUnit(unitItem.unit_id)}
                                        bedroom={unitItem.bedroom}
                                        bathroom={unitItem.bathroom}
                                        square_feet={unitItem.square_feet}
                                        purchasePrice={unitItem.owner?.purchase_price ?? 0}
                                        expectedAnnualRent={unitItem.minimum_annual_rent}
                                        rent={getActiveLease(unitItem.leases)?.annual_rent_amount ?? undefined}
                                        acquisitionDate={unitItem.owner?.created_at ?? ""}
                                        purpose_type={unitItem.purpose_type}
                                        constructionProgressItem={unitItem.property.construction_progress_items.length > 0 ? unitItem.property.construction_progress_items[0] : undefined}
                                        pmPackage={undefined}
                                    />
                                </Col>
                            ) : (
                                <Col xs={12} md={6} xxl={4} key={unitItem.unit_id}>
                                    <UnitCard
                                        imageSrc={unitItem.pictures.length > 0 ? unitItem.pictures[0].picture_path : ""}
                                        propertyName={unitItem.property.property_name}
                                        title={unitItem.unit_number}
                                        onClick={() => onClickUnit(unitItem.unit_id)}
                                        bedroom={unitItem.bedroom}
                                        square_feet={unitItem.square_feet}
                                        purchasePrice={unitItem.owner?.purchase_price ?? 0}
                                        rent={getActiveLease(unitItem.leases)?.annual_rent_amount ?? undefined}
                                        pmPackage={undefined}
                                    />
                                </Col>
                            )}
                        </>
                    )
                ))}
            </Row>
        </Container>
    );
};

export default RentedUnitsSection;
