import HouseKeysIllustration from '../../../../assets/images/house_keys.png';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';
import useInvestorDetailStore from '../../../../stores/investorDetailStore';
import { ButtonContainer, ContainerCard, IllustrationImage, InfoText, StyledContainer, Subtitle, Title } from './lets-go.styles';

interface LetsGoProps {
    isLoading: boolean;
    onClickNext: () => void;
}

const LetsGo: React.FC<LetsGoProps> = ({ isLoading, onClickNext }) => {
    const isMobile = useIsMobile();

    // Stores
    const investor = useInvestorDetailStore(state => state.investor);

    if (!investor) return null;

    return (
        <StyledContainer>
            <ContainerCard>
                <IllustrationImage src={HouseKeysIllustration} width={200} />
                <Title>Wilkommen bei Managd!</Title>
                <Subtitle>Um deinen Account zu aktivieren, benötigen wir noch ein paar Informationen von dir. Es dauert nicht mehr als ein paar Minuten. Versprochen!</Subtitle>
                <ButtonContainer>
                    <CTAButton
                        variant="primary"
                        size={isMobile ? "M" : "L"}
                        label="Los gehts!"
                        onClick={onClickNext}
                        loading={isLoading}
                        fullWidth
                    />
                </ButtonContainer>
                <InfoText>
                    Indem du fortfährst stimmst du unseren
                    <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer"> AGB's </a>
                    und der
                    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"> Datenschutzerklärung </a>
                    zu.
                </InfoText>
            </ContainerCard>
        </StyledContainer>
    );
};

export default LetsGo;
