import React, { useRef, useState, useEffect } from 'react';
import {
    PropertyUpdatesContainer,
    UpdateCard,
    UpdateContent,
    UpdateTitle,
    UpdateDate,
    ScrollButton,
    Title,
    Subtitle,
    HeaderContainer,
    PlayButton
} from './property-updates.styles';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';
import Construction1 from '../../assets/images/property-updates/construction-update-1.png';
import Construction2 from '../../assets/images/property-updates/construction-update-2.png';
import Construction3 from '../../assets/images/property-updates/construction-update-3.png';
import Construction4 from '../../assets/images/property-updates/construction-update-4.png';
import useIsMobile from '../../hooks/useIsMobile';
import YouTubeModal from '../../modals/youtube-modal/youtube-modal';
import LazyLoadImage from '../lazy-load-image/lazy-load-image';

interface PropertyUpdate {
    id: number;
    image: string;
    title: string;
    date: string;
    category: string;
    project_name: string;
    youtubeVideoId: string;
}

const mockData: PropertyUpdate[] = [
    {
        id: 1,
        image: Construction1,
        title: 'Bauupdate im Februar - Der Grundstein ist gelegt',
        date: '22.09.2024',
        category: 'Bauupdates',
        project_name: 'The Ivy',
        youtubeVideoId: 'l8_qBH1amAs',
    },
    {
        id: 2,
        image: Construction2,
        title: 'Fortschritt im März - Die Struktur nimmt Form an',
        date: '22.03.2024',
        category: 'Bauupdates',
        project_name: 'The Vyne',
        youtubeVideoId: 'l8_qBH1amAs',
    },
    {
        id: 3,
        image: Construction3,
        title: 'Bauupdate im April - Der Rohbau ist abgeschlossen',
        date: '22.04.2024',
        category: 'Bauupdates',
        project_name: 'The Vyne',
        youtubeVideoId: 'l8_qBH1amAs',
    },
    {
        id: 4,
        image: Construction4,
        title: 'Bauupdate im Mai - Fassadenarbeiten beginnen',
        date: '22.05.2024',
        category: 'Bauupdates',
        project_name: 'The Ivy',
        youtubeVideoId: 'l8_qBH1amAs',
    },
];

interface PropertyUpdatesProps {
    className?: string | undefined;
    propertyId?: number;
    propertyName?: string;
}

const PropertyUpdates: React.FC<PropertyUpdatesProps> = ({ className, propertyId, propertyName }) => {
    const isMobile = useIsMobile();

    // References
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    // States
    const [isYouTubeModalOpen, setIsYouTubeModalOpen] = useState(false);
    const [youtubeUrl, setYoutubeUrl] = useState("");
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    // Functions
    const openYouTubeVideo = (videoId: string) => {
        const url = `https://www.youtube.com/watch?v=${videoId}`;
        setYoutubeUrl(videoId);

        if (isMobile && window.matchMedia('(display-mode: standalone)').matches) {
            // If on mobile PWA, try to open YouTube app
            window.open(url, '_blank');
        } else {
            // On desktop, open the modal
            setIsYouTubeModalOpen(true);
        }
    };

    const handleCloseYoutubeModal = () => {
        setIsYouTubeModalOpen(false);
        setYoutubeUrl("");
    };

    const updateScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    // Effects
    useEffect(() => {
        updateScrollButtons();
        const handleResize = () => updateScrollButtons();
        window.addEventListener('resize', handleResize);

        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener('scroll', updateScrollButtons);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener('scroll', updateScrollButtons);
            }
        };
    }, []);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -300 : 300,
                behavior: 'smooth',
            });
            updateScrollButtons();
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0));
        setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - (scrollContainerRef.current?.offsetLeft || 0);
        const walk = (x - startX) * 1.5; // Adjust scroll sensitivity here
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const handleMouseUpOrLeave = () => {
        setIsDragging(false);
    };

    return (
        <>
            <PropertyUpdatesContainer className={className}>
                <HeaderContainer>
                    <div className="d-flex align-items-center" style={{ gap: 4 }} onClick={isMobile ? () => {
                        alert("Coming soon...");
                    } : undefined}>
                        <Title>Bau-Updates</Title>
                        <MaterialIcon className="d-md-none d-inline" icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </div>
                    <Subtitle>{propertyName ? propertyName : "Alle Projekte"}</Subtitle>
                </HeaderContainer>
                <div className="scroll-wrapper">
                    {canScrollLeft && (
                        <ScrollButton className="left d-none d-md-inline" onClick={() => scroll('left')}>
                            <MaterialIcon icon="arrow_back" size={16} color={colorPalette.textMain} />
                        </ScrollButton>
                    )}
                    <div
                        className="updates-container"
                        ref={scrollContainerRef}
                        onScroll={updateScrollButtons}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave}
                    >
                        {mockData.map((update) => (
                            <UpdateCard key={update.id}>
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <LazyLoadImage
                                        src={update.image}
                                        alt={update.title}
                                        width="100%"
                                        height={220}
                                        borderRadius="8px"
                                    />
                                    <PlayButton onClick={() => openYouTubeVideo(update.youtubeVideoId)}>
                                        <MaterialIcon icon="play_arrow" size={30} color={colorPalette.blue400} />
                                    </PlayButton>
                                </div>
                                <UpdateContent>
                                    <UpdateDate>{update.date} • {update.project_name}</UpdateDate>
                                    <UpdateTitle>{update.title}</UpdateTitle>
                                </UpdateContent>
                            </UpdateCard>
                        ))}
                    </div>
                    {canScrollRight && (
                        <ScrollButton className="right d-none d-md-inline" onClick={() => scroll('right')}>
                            <MaterialIcon icon="arrow_forward" size={16} color={colorPalette.textMain} />
                        </ScrollButton>
                    )}
                </div>
            </PropertyUpdatesContainer>

            {/* YouTube Modal for Desktop */}
            {!isMobile && (
                <YouTubeModal
                    isOpen={isYouTubeModalOpen}
                    url={youtubeUrl}
                    onClose={handleCloseYoutubeModal}
                />
            )}
        </>
    );
};

export default PropertyUpdates;
