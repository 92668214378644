import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const SectionListContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  border: 1px solid ${colorPalette.blue100};
  border-radius: 8px;
  overflow: hidden;
`;

export const SectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 8px 32px 16px;
  border-bottom: 1px solid ${colorPalette.blue200};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${colorPalette.blue50};
  }

  @media (max-width: 767.98px) {
    padding: 24px 10px;
  }
`;

export const ContentSpacing = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SectionText = styled.p`
  flex-grow: 1;
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyLGMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodyMDMain};
  }
`;

export const SectionErrorText = styled.p`
  flex-grow: 1;
  margin: 0;
  color: ${colorPalette.errorDark};
  ${typeScale.bodySM};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;

export const SectionWarningText = styled.p`
  flex-grow: 1;
  margin: 0;
  color: ${colorPalette.warningDark};
  ${typeScale.bodySM};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;
