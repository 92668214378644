import React, { RefObject, useCallback, useEffect, useState } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';
import { ScrollToTopButton } from './scroll-up-button.styles';

interface ScrollUpButtonProps {
    className?: string;
    scrollableRef: RefObject<HTMLDivElement>;
}

const ScrollUpButton: React.FC<ScrollUpButtonProps> = ({ className, scrollableRef }) => {
    const isMobile = useIsMobile();

    // States
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const scrollContainer = scrollableRef.current;
        if (scrollContainer) {
            const handleScroll = () => {
                const scrollTop = scrollContainer.scrollTop;
                if (scrollTop > 300) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            };

            scrollContainer.addEventListener('scroll', handleScroll);

            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollableRef]);

    const scrollToTop = useCallback(() => {
        scrollableRef?.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [scrollableRef]);

    if (isMobile) return null;

    return (
        <ScrollToTopButton isVisible={isVisible} className={className} onClick={scrollToTop}>
            <MaterialIcon icon="keyboard_arrow_up" size={24} color={colorPalette.white} />
        </ScrollToTopButton>
    );
};

export default ScrollUpButton;
