import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Container for the entire page
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  background-color: white;
`;

// Wrapper for the content to center it vertically
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
`;

// Title styling
export const Title = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};
`;

// Subtitle styling
export const Subtitle = styled.p`
  margin-bottom: 36px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};
`;

// Error text styling
export const ErrorText = styled.p`
  margin-top: 24px;
  margin-bottom: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;
