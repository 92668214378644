// youtube-modal.tsx
import React from 'react';
import { ModalOverlay, ModalContent, ResponsiveIframeContainer } from './youtube-modal.styles';

interface YouTubeModalProps {
    isOpen: boolean;
    url: string;
    onClose: () => void;
}

const YouTubeModal: React.FC<YouTubeModalProps> = ({ isOpen, url, onClose }) => {
    if (!isOpen) return null;

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ResponsiveIframeContainer>
                    <iframe
                        src={`https://www.youtube.com/embed/${url}?autoplay=1`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </ResponsiveIframeContainer>
            </ModalContent>
        </ModalOverlay>
    );
};

export default YouTubeModal;
