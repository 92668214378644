import React, { useEffect, useRef, useState } from 'react';
import {
    ArticleCard,
    ArticleImage,
    ArticleContent,
    ArticleTitle,
    ArticleSubtitle,
    Title,
    ArticleAdditionalText,
    ArticleTitleWrapper,
    HeaderContainer,
} from './more-articles.styles';
import CTAButton from '../../../../../components/cta-button/cta-button';
import MaterialIcon from '../../../../../components/material-icon';
import { ARTICLES } from '../../../../../config/articleConfig';
import useCustomNavigate from '../../../../../hooks/useCustomNavigate';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { colorPalette } from '../../../../../utils/theme';
import { Col, Row } from 'react-bootstrap';

interface MoreArticlesProps {
    className?: string | undefined;
    currentVisibleArticleId: number;
}

const MoreArticles: React.FC<MoreArticlesProps> = ({ className, currentVisibleArticleId }) => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    return (
        <div className={className}>
            <HeaderContainer>
                <div className="d-flex align-items-center" style={{ gap: 4, marginBottom: 16 }} onClick={isMobile ? () => {
                    alert("Coming soon...");
                } : undefined}>
                    <Title>Weitere Beiträge</Title>
                    <MaterialIcon className="d-md-none d-inline" icon="chevron_forward" size={24} color={colorPalette.blue500} />
                </div>
            </HeaderContainer>
            <Row className="gy-3 gx-3">
                {ARTICLES.filter(article => article.id !== currentVisibleArticleId).map((article) => (
                    <Col xs={12} xl={6}>
                        <ArticleCard key={article.id}>
                            <ArticleImage src={article.image} alt={article.title} draggable={false} />
                            <ArticleContent>
                                <ArticleSubtitle>Knowledge Hub</ArticleSubtitle>
                                <ArticleTitleWrapper>
                                    <ArticleTitle>{article.title}</ArticleTitle>
                                    <ArticleAdditionalText>{article.description}</ArticleAdditionalText>
                                </ArticleTitleWrapper>
                                <div>
                                    <CTAButton variant="ghost" size="S" label="Weiterlesen" noPadding onClick={() => navigate(`/articles/${article.id}`)} />
                                </div>
                            </ArticleContent>
                        </ArticleCard>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default MoreArticles;
