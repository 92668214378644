import './date-select.css';
import 'react-datepicker/dist/react-datepicker.css';

import React, { CSSProperties, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { BsXCircle } from 'react-icons/bs';

interface DateSelectProps {
    className?: string;
    id: string;
    label: string;
    placeholder?: string;
    date: Date | null;
    onChange: (date: Date | null) => void;
    onClear?: () => void;
    required?: boolean;
    disabled?: boolean;
    inputStyle?: CSSProperties;
    errorMessage?: string;
    labelOnTop?: boolean;
    showTimeSelect?: boolean;
    selectMonthYearOnly?: boolean;
}

const DateSelect: React.FC<DateSelectProps> = ({
    className,
    id,
    label,
    placeholder = "",
    date,
    onChange,
    onClear,
    required = false,
    disabled = false,
    errorMessage,
    labelOnTop = false,
    showTimeSelect = false,
    selectMonthYearOnly = false
}) => {
    const labelClasses = labelOnTop ? 'sidebar-select-label-on-top' : 'col-sm-5 col-form-label';
    const selectWrapperClasses = labelOnTop ? 'sidebar-select-wrapper-on-top' : 'col-sm-7';

    const dateFormat = selectMonthYearOnly ? "MM/yyyy" : showTimeSelect ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy";

    return (
        <div className={`sidebar-select-field ${labelOnTop ? '' : 'row'} ${className}`}>
            <label htmlFor={id} className={labelClasses}>
                {label}
                {required && <span className="text-red ms-1">*</span>}
            </label>
            <div className={selectWrapperClasses}>
                <DatePicker
                    id={id}
                    selected={date}
                    onChange={(date: Date | null) => onChange(date)}
                    disabled={disabled}
                    className="form-control datepicker-input"
                    placeholderText={placeholder}
                    dateFormat={dateFormat}
                    required={required}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    showTimeSelect={showTimeSelect && !selectMonthYearOnly}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    showMonthYearPicker={selectMonthYearOnly}
                    popperClassName="custom-datepicker-popper"
                    onFocus={e => e.target.blur()}
                />
                <div className="icon-container" style={{ top: "70%" }}>
                    {date && onClear && !disabled && (
                        <BsXCircle className="icon" onClick={onClear} />
                    )}
                </div>
            </div>
            {errorMessage && <div className="ms-2 error-message">{errorMessage}</div>}
        </div>
    );
};

export default DateSelect;
