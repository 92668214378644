import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import { fetchUnits } from "../../services/unitService";
import useUnitStore from "../../stores/unitStore";
import UnitCard from "./components/unit-card/unit-card";
import { getActiveLease } from "../../utils/helpers";
import HouseAnim from "../../assets/anims/house-anim.json";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import Filter from "../../components/filter/filter";

// Import styled components
import {
    StyledContainer,
    Title,
    LoadingContainer,
    NoUnitsContainer,
    NoUnitsMessage,
    Row,
    Col,
    LoaderContainer,
    HeaderContainer,
    FilterContainer,
    HeaderTitleContainer,
    UnitAmount,
} from './my-units-page.styles';
import GoodToKnowArticles from "../../components/good-to-know-articles/good-to-know-articles";

const MyUnitsPage = () => {
    const navigate = useNavigate();

    // State to manage selected project IDs
    const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

    const {
        units,
        currentPage,
        isLoading,
        isInitialLoaded,
        moreDataAvailable,
        setUnits,
        setCurrentPage,
        setIsLoading,
        setIsInitialLoaded,
        setMoreDataAvailable
    } = useUnitStore();

    // Function to handle project selection
    const handleProjectSelect = (projectId: number) => {
        setSelectedProjects((prevSelected) =>
            prevSelected.includes(projectId)
                ? prevSelected.filter((id) => id !== projectId)
                : [...prevSelected, projectId]
        );
    };

    const loadUnits = useCallback(async () => {
        setIsLoading(true);
        try {
            // Fetch units based on selected projects
            const fetchedUnits = await fetchUnits(undefined, currentPage, 20);
            setUnits(currentPage === 1 ? fetchedUnits : [...units, ...fetchedUnits]);
            setMoreDataAvailable(fetchedUnits.length === 20);
            setIsInitialLoaded(true);
        } catch (error) {
            console.error('Error while fetching units:', error);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage]);

    useEffect(() => {
        console.log('selectedProjects updated:', selectedProjects);
    }, [selectedProjects]);

    useEffect(() => {
        loadUnits();
    }, [loadUnits]);

    // Infinite scroll fetch function
    const fetchMoreData = () => {
        if (moreDataAvailable && !isLoading) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onClickUnit = (unitId: number) => {
        navigate(`/my-units/${unitId}`);
    };

    const filteredUnits = useMemo(() => {
        if (selectedProjects.length > 0) {
            return units.filter(unit => selectedProjects.includes(unit.property.property_id))
        }
        return units;
    }, [units, selectedProjects]);

    return (
        <StyledContainer>
            <HeaderContainer>
                <HeaderTitleContainer>
                    <Title>Meine Units</Title>
                    <UnitAmount>{units.length}</UnitAmount>
                </HeaderTitleContainer>

                <FilterContainer>
                    <Filter
                        label="Status"
                        hasDropdown
                        items={[
                            { label: "Im Bau", value: "in-progress" },
                            { label: "Handover", value: "handover" },
                            { label: "In Vermietung", value: "rented" },
                        ]}
                        onSelect={(value) => console.log('Selected:', value)}
                    />

                    {/* Project Filters */}
                    <Filter
                        label="Haven Residences"
                        isSelected={selectedProjects.includes(1)}
                        onSelect={() => handleProjectSelect(1)}
                    />
                    <Filter
                        label="The Ivy"
                        isSelected={selectedProjects.includes(2)}
                        onSelect={() => handleProjectSelect(2)}
                    />
                    <Filter
                        label="The Vybe"
                        isSelected={selectedProjects.includes(3)}
                        onSelect={() => handleProjectSelect(3)}
                    />
                    <Filter
                        label="Haven Gardens"
                        isSelected={selectedProjects.includes(4)}
                        onSelect={() => handleProjectSelect(4)}
                    />
                    <Filter
                        label="The Vyne"
                        isSelected={selectedProjects.includes(5)}
                        onSelect={() => handleProjectSelect(5)}
                    />
                </FilterContainer>
            </HeaderContainer>

            {isLoading && !isInitialLoaded ? (
                <LoadingContainer>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </LoadingContainer>
            ) : units.length === 0 ? (
                <NoUnitsContainer>
                    <Lottie className="mt-4" animationData={HouseAnim} loop={false} style={{ height: 400 }} />
                    <NoUnitsMessage>Derzeit sind keine Immobilien in deinem Besitz aufgeführt.</NoUnitsMessage>
                </NoUnitsContainer>
            ) : (
                <>
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden", paddingLeft: 16, paddingRight: 16 }}
                        dataLength={units.length}
                        next={fetchMoreData}
                        hasMore={moreDataAvailable}
                        loader={
                            <LoaderContainer>
                                <div className="spinner-border text-primary" role="status" />
                            </LoaderContainer>
                        }
                        scrollableTarget="content-area"
                    >
                        <Row>
                            {filteredUnits.map((unit) => (
                                <Col key={unit.unit_id}>
                                    <UnitCard
                                        imageSrc={unit.pictures.length > 0 ? unit.pictures[0].picture_path : ""}
                                        propertyName={unit.property.property_name}
                                        title={unit.unit_number}
                                        onClick={() => onClickUnit(unit.unit_id)}
                                        bedroom={unit.bedroom}
                                        bathroom={unit.bathroom}
                                        square_feet={unit.square_feet}
                                        purchasePrice={unit.owner?.purchase_price ?? 0}
                                        expectedAnnualRent={unit.minimum_annual_rent}
                                        rent={getActiveLease(unit.leases)?.annual_rent_amount ?? undefined}
                                        acquisitionDate={unit.owner?.created_at ?? ""}
                                        purpose_type={unit.purpose_type}
                                        constructionProgressItem={unit.property.construction_progress_items.length > 0 ? unit.property.construction_progress_items[0] : undefined}
                                        pmPackage={undefined}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </InfiniteScroll>

                    <GoodToKnowArticles className="mt-3" />
                </>
            )}
        </StyledContainer>
    );
};

export default MyUnitsPage;
