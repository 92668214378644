import React from 'react';
import { ButtonGroup, Container, Description, Title, Image } from './empty-rental-stats-card.styles';
import CTAButton from '../../../../components/cta-button/cta-button';
import QuestionCoinImage from '../../../../assets/images/question-coin.png';
import useIsMobile from '../../../../hooks/useIsMobile';

const EmptyRentalStatsCard: React.FC = () => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <Container>
                <div className="d-flex align-items-center justify-content-center" style={{ marginBottom: 16, gap: 16 }}>
                    <Image src={QuestionCoinImage} alt="Rental Question Illustration" />
                    <div>
                        <Title>Vermietungsstatistik</Title>
                        <Description>Für Einheiten, die von managd verwaltet werden, erscheinen die Statistiken hier.</Description>
                    </div>
                </div>
                <ButtonGroup>
                    <CTAButton variant="primary" size="S" label="Verwaltungspakete ansehen" onClick={() => { }} />
                </ButtonGroup>
            </Container>
        );
    } else {
        return (
            <Container>
                <Title>Vermietungsstatistik</Title>
                <Image src={QuestionCoinImage} alt="Rental Question Illustration" />
                <Description>Für Einheiten, die von managd verwaltet werden, erscheinen die Statistiken hier.</Description>
                <ButtonGroup>
                    <CTAButton variant="primary" size="L" label="Verwaltungspakete ansehen" onClick={() => { }} />
                    <CTAButton variant="secondary" size="L" label="Mehr Erfahren" onClick={() => { }} />
                </ButtonGroup>
            </Container>
        );
    }
};

export default EmptyRentalStatsCard;
