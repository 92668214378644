import { useCallback, useState } from 'react';
import KeysIllustration from '../../../../assets/images/keys.svg';
import CTAButton from '../../../../components/cta-button/cta-button';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import useIsMobile from '../../../../hooks/useIsMobile';
import useInvestorDetailStore from '../../../../stores/investorDetailStore';
import { ButtonContainer, ContainerCard, IllustrationImage, StyledContainer, Subtitle, Title } from './update-password.styles';
import useAlertStore from '../../../../stores/alertStore';
import { updateOnboardingPassword } from '../../../../services/investorService';

interface UpdatePasswordProps {
    isLoading: boolean;
    onClickNext: () => void;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({ isLoading, onClickNext }) => {
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const investor = useInvestorDetailStore(state => state.investor);

    // States
    const [isUpdating, setIsUpdating] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const updatePassword = useCallback(async () => {
        if (investor) {
            let valid = true;
            setPasswordError("");

            // Basic password validation
            if (!password) {
                setPasswordError('Passwort darf nicht leer sein.');
                valid = false;
            }

            if (valid) {
                try {
                    setIsUpdating(true);
                    await updateOnboardingPassword(investor.investor_id, password);
                    onClickNext();
                } catch (error) {
                    console.log('Error while change password:', error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsUpdating(false);
                }
            }
        }
    }, [password, investor]);

    if (!investor) return null;

    return (
        <StyledContainer>
            <ContainerCard>
                <IllustrationImage src={KeysIllustration} width={isMobile ? 60 : 77} />
                <Title>Lege zunächst dein Passwort fest.</Title>
                <Subtitle>Gib ein neues Passwort ein.</Subtitle>
                <ButtonContainer>
                    <CustomInputField
                        id="new-password"
                        placeholder="Dein neues Passwort"
                        type="password"
                        value={password}
                        errorMessage={passwordError}
                        onClear={() => setPassword("")}
                        onChange={(value: string) => {
                            setPassword(value);
                            setPasswordError("");
                        }}
                        disabled={isUpdating || isLoading}
                    />
                    <div style={{ marginTop: 24 }}>
                        <CTAButton
                            variant="primary"
                            size={isMobile ? "M" : "L"}
                            label="Passwort ändern"
                            onClick={updatePassword}
                            loading={isUpdating || isLoading}
                            fullWidth
                        />
                    </div>
                </ButtonContainer>
            </ContainerCard>
        </StyledContainer>
    );
};

export default UpdatePassword;
