import { useEffect, useMemo, useState } from "react";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import {
    LoadingContainer,
    Container,
    Header,
    HeaderSubtitle,
    HeaderSubtitleContainer,
    HeaderTagsContainer,
    HeaderTitle,
} from './project-page.styles';
import GoodToKnowArticles from "../../components/good-to-know-articles/good-to-know-articles";
import PropertyUpdates from "../../components/property-updates/property-updates";
import { useParams } from "react-router-dom";
import ConstructionProgressCard from "../../components/construction-progress-card/construction-progress-card";
import PaymentPlanCard from "../../components/payment-plan-card/payment-plan-card";
import { fetchProperty, IProperty } from "../../services/propertyService";
import ProjectPictures from "./components/project-pictures/project-pictures";
import ProjectKeyData from "./components/project-key-data/project-key-data";
import OwnedUnits from "./components/owned-units/owned-units";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../components/material-icon";
import Tag from "../../components/tag/tag";
import { colorPalette } from "../../utils/theme";
import ImageModal from "../../modals/image-modal/image-modal";

const ProjectPage = () => {
    const { propertyId } = useParams();

    // States
    const [isLoading, setIsLoading] = useState(false);
    const [property, setProperty] = useState<IProperty | null>(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    useEffect(() => {
        if (propertyId) {
            const loadProperty = async () => {
                try {
                    setIsLoading(true);

                    const property = await fetchProperty(Number(propertyId));
                    setProperty(property);
                } catch (error) {
                    console.log(`error while fetching property ID: ${propertyId}`, error);
                } finally {
                    setIsLoading(false);
                }
            };
            loadProperty();
        }
    }, [propertyId]);

    return (
        <Container>
            {isLoading ? (
                <LoadingContainer>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </LoadingContainer>
            ) : property ? (
                <>
                    {/* HEADER */}
                    <Header>
                        <div className="d-flex justify-content-items align-items-center" style={{ gap: 16 }}>
                            <HeaderTitle className="m-0">{property.property_name}</HeaderTitle>
                            <HeaderTagsContainer>
                                {property.under_construction && (
                                    <Tag label="Im Bau" type="unitStatus" variant="inProgress" />
                                )}
                            </HeaderTagsContainer>
                        </div>
                        <HeaderSubtitleContainer>
                            <MaterialIcon icon="distance" size={20} color={colorPalette.textDark} />
                            <HeaderSubtitle>{property.property_location}</HeaderSubtitle>
                        </HeaderSubtitleContainer>
                    </Header>

                    <ProjectPictures
                        images={[
                            property.property_image,
                            property.property_image,
                            property.property_image,
                            property.property_image,
                            property.property_image
                        ]}
                        onClickPicture={() => setIsImageModalOpen(true)}
                    />

                    <div className="container-fluid mt-4">
                        <Row className="gy-3">
                            {property.construction_progress_items.length > 0 && (
                                <Col sm={12} md={6}>
                                    <ConstructionProgressCard
                                        property_name={property.property_name}
                                        constructionProgressItems={property.construction_progress_items.sort((a, b) => new Date(a.published_date).getTime() - new Date(b.published_date).getTime())}
                                        showReportDownload={false}
                                    />
                                </Col>
                            )}
                            {property.payment_plan_items.length > 0 && (
                                <Col sm={12} md={6}>
                                    <PaymentPlanCard
                                        paymentPlanItems={property.payment_plan_items.sort((a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime())}
                                        property_name={property.property_name}
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>

                    <PropertyUpdates
                        className="mt-4"
                        propertyId={property.property_id}
                    />

                    <OwnedUnits
                        className="mt-4"
                        property_id={property.property_id}
                        property_name={property.property_name}
                    />

                    <ProjectKeyData
                        className="mt-4"
                        registrationNumber={property.registration_number}
                        buildingArea={property.total_build_up_area_size}
                        landArea={property.plot_size}
                        landPrice={property.plot_purchased_price}
                    />

                    <GoodToKnowArticles className="mt-4" />
                </>
            ) : (
                <></>
            )}

            {property && (
                <ImageModal
                    images={[
                        property.property_image,
                        property.property_image,
                        property.property_image,
                        property.property_image,
                        property.property_image
                    ]}
                    title="Außenfassade bei Nacht"
                    subtitle={property.property_name}
                    isOpen={isImageModalOpen}
                    onClose={() => setIsImageModalOpen(false)}
                />
            )}
        </Container >
    );
};

export default ProjectPage;
