import React, { useEffect } from 'react';
import {
    ModalContainer,
    Header,
    HeaderTitle,
    CloseButton,
    ProgressList,
    ProgressItem,
    ProgressCircle,
    ProgressLine,
    ProgressPercentage,
    ProgressDate,
    ProgressStatus,
    ProgressIcon,
    HeaderSubtitle,
    Overlay,
    ProgressItemDetail,
    StatusText,
} from './construction-progress-modal.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';
import { IConstructionProgressItem } from '../../services/constructionProgressService';
import { IDocument } from '../../services/documentService';

interface ProgressItem {
    percentage: number;
    date: string;
    status: string;
    reportLink?: string;
}

interface ConstructionProgressModalProps {
    property_name: string;
    completion_date: string;
    constructionProgressItems: IConstructionProgressItem[];
    onClose: () => void;
    onDownloadDLDReport: (item_id: number, dldReport: IDocument) => void;
}

const ConstructionProgressModal: React.FC<ConstructionProgressModalProps> = ({
    property_name,
    completion_date,
    constructionProgressItems,
    onClose,
    onDownloadDLDReport,
}) => {
    // Prevent scrolling down
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        const preventDefault = (e: TouchEvent) => {
            e.preventDefault();
        };

        document.addEventListener('touchmove', preventDefault, { passive: false });

        return () => {
            document.body.style.overflow = 'unset';
            document.removeEventListener('touchmove', preventDefault);
        };
    }, []);

    return (
        <Overlay onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <Header>
                    <div>
                        <HeaderTitle>Baufortschritt: {property_name}</HeaderTitle>
                        <HeaderSubtitle>
                            Geplante Fertigstellung: {new Date(completion_date).toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })}
                        </HeaderSubtitle>
                    </div>
                    <CloseButton onClick={onClose}>
                        <MaterialIcon icon="close" size={24} color={colorPalette.blue500} />
                    </CloseButton>
                </Header>
                <ProgressList>
                    <div style={{ display: 'grid', gridTemplateColumns: '40px 100px 1fr auto', alignItems: 'center', paddingLeft: 16 }}>
                        <div></div>
                        <StatusText isFirst={true}>Aktueller Status</StatusText>
                        <div></div>
                        <StatusText isFirst={true}>DLD Report</StatusText>
                    </div>
                    {constructionProgressItems.map((item, index) => (
                        <ProgressItem key={index} isLast={index === constructionProgressItems.length - 1}>
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <ProgressCircle isFirst={index === 0} />
                                <ProgressLine
                                    isTopItem={index === 0}
                                    isBottomItem={index === constructionProgressItems.length - 1}
                                />
                            </div>
                            <ProgressPercentage isActive={index === 0}>
                                {Math.round(item.percentage)}%
                            </ProgressPercentage>
                            <ProgressItemDetail>
                                <ProgressDate>{new Date(item.published_date).toLocaleDateString()}</ProgressDate>
                                <ProgressStatus isFirst={index === 0}>{item.description}</ProgressStatus>
                            </ProgressItemDetail>
                            {item.document && (
                                <ProgressIcon onClick={(event) => {
                                    event.stopPropagation();
                                    onDownloadDLDReport(item.id, item.document!);
                                }}>
                                    <MaterialIcon icon="file_open" size={24} color={colorPalette.blue500} />
                                </ProgressIcon>
                            )}
                        </ProgressItem>
                    ))}
                </ProgressList>
            </ModalContainer>
        </Overlay>
    );
};

export default ConstructionProgressModal;
