import React, { useEffect, useState } from 'react';
import LogoMulticolor from '../../assets/images/logo/logo-multicolor.svg';
import { SidebarContainer, NavItem, LogoImage, SidebarHeader, Overlay } from './sidebar.styles';
import MENU_ITEMS, { IMenuItem } from '../../config/menuConfig';
import { colorPalette } from '../../utils/theme';
import MaterialIcon from '../material-icon';
import { useSwipeable } from 'react-swipeable';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { useLocation } from 'react-router-dom';

interface SidebarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarVisible, toggleSidebar }) => {
    const navigate = useCustomNavigate();
    const location = useLocation();

    // States
    const [activeItem, setActiveItem] = useState<string>('Dashboard');
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [menuItems, setMenuItems] = useState<IMenuItem[]>(MENU_ITEMS);

    // Effects
    useEffect(() => {
        const findActiveItem = () => {
            const active = menuItems.find((item) => location.pathname === item.path);
            return active ? active.name : '';
        };
        setActiveItem(findActiveItem());
    }, [menuItems, location.pathname]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            // Show "Account" menu item on mobile
            setMenuItems(prevItems =>
                prevItems.map(item =>
                    item.name === "Account" ? { ...item, isVisible: true } : item
                )
            );
        } else {
            // Hide "Account" on larger screens
            setMenuItems(prevItems =>
                prevItems.map(item =>
                    item.name === "Account" ? { ...item, isVisible: false } : item
                )
            );
        }
    }, [isMobile]);

    const handleItemClick = (item: IMenuItem) => {
        if (!item.isActivated) return;
        if (activeItem !== item.name) {
            setActiveItem(item.name);
            toggleSidebar();
            navigate(item.path, { replace: true, clearHistory: true });
        }
    };

    // Swipeable configuration for mobile
    const handlers = useSwipeable({
        onSwipedLeft: () => toggleSidebar(),
        onSwipedRight: () => toggleSidebar(),
        delta: 30,
        trackMouse: true,
    });

    return (
        <>
            <SidebarContainer isVisible={isSidebarVisible} {...handlers}>
                <SidebarHeader>
                    <LogoImage src={LogoMulticolor} alt="Logo" onClick={() => navigate("/", { replace: true, clearHistory: true })} />
                    <MaterialIcon
                        className="d-lg-none d-block"
                        icon="close"
                        size={24}
                        color={colorPalette.textMain}
                        onClick={toggleSidebar}
                    />
                </SidebarHeader>
                <ul className="nav flex-column">
                    {menuItems.map((item) => {
                        const isActive = activeItem === item.name;
                        if (!item.isVisible) return null;

                        return (
                            <NavItem key={item.id} isActive={isActive} isDeactivated={!item.isActivated} onClick={() => handleItemClick(item)}>
                                <button
                                    className={`nav-link ${!item.isActivated ? 'deactivated' : ''}`}
                                    disabled={!item.isActivated}
                                >
                                    <MaterialIcon icon={item.icon} size={24} color={colorPalette.primaryDark} />
                                    {item.name}
                                </button>
                            </NavItem>
                        );
                    })}
                </ul>
            </SidebarContainer>
            {isSidebarVisible && <Overlay onClick={toggleSidebar} />}
        </>
    );
};

export default Sidebar;
