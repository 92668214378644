import React, { useState, useEffect, useRef } from 'react';
import { MdCheck } from 'react-icons/md';
import { CheckboxContainer, CheckboxInput, CustomCheckbox } from './checkbox.styles';

interface CheckboxProps {
    label?: string;
    checked?: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
    label,
    checked = false,
    indeterminate = false,
    disabled = false,
    onChange,
}) => {
    const [isChecked, setIsChecked] = useState(checked);
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    const handleChange = () => {
        if (!disabled) {
            const newChecked = !isChecked;
            setIsChecked(newChecked);
            if (onChange) onChange(newChecked);
        }
    };

    return (
        <CheckboxContainer disabled={disabled}>
            <CheckboxInput
                ref={checkboxRef}
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                disabled={disabled}
            />
            <CustomCheckbox isChecked={isChecked} indeterminate={indeterminate} disabled={disabled}>
                {indeterminate ? '-' : isChecked ? <MdCheck /> : ''}
            </CustomCheckbox>
            {label && <span>{label}</span>}
        </CheckboxContainer>
    );
};

export default Checkbox;
