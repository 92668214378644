import React from 'react';
import {
    SectionContainer,
    Container,
    Title,
    Subtitle,
    CTAButtonContainer,
    OnboardingImage,
} from './onboarding-formular-section.styles';
import Image from '../../../../assets/images/onboarding-image.png';
import CTAButton from '../../../../components/cta-button/cta-button';

interface OnboardingFormularSectionProps {
    onContinue: () => void;
}

const OnboardingFormularSection: React.FC<OnboardingFormularSectionProps> = ({
    onContinue,
}) => {
    return (
        <SectionContainer>
            <Container>
                <OnboardingImage src={Image} alt="Onboarding Image" />
                <Title>Fülle das Onboarding Formular aus.</Title>
                <Subtitle>
                    Fast geschafft! Fülle das Onboarding-Formular aus und wir setzen uns mit dir in Verbindung, um deine Anmeldung zu finalisieren.
                </Subtitle>

                <CTAButtonContainer>
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Onboarding-Formular ausfüllen"
                        onClick={onContinue}
                        fullWidth
                    />
                </CTAButtonContainer>
            </Container>
        </SectionContainer>
    );
};

export default OnboardingFormularSection;
