import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";
import BackgroundImage from "../../../../assets/images/logo-background-big.png";

// Header Container
export const HeaderContainer = styled.div`
  background: url(${BackgroundImage}) no-repeat top right,
    /* Add the image */
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 1) 100%
      ),
    /* White fade */ linear-gradient(247.92deg, #0f1c4d 30.75%, #7fccfa 97.83%); /* Blue gradient */

  background-repeat: no-repeat;
  background-position: top 20px right 20px, /* Image position */ top left,
    /* Gradient position */ top left; /* Another gradient position if needed */

  padding: 12px 16px 0px;

  @media (max-width: 767.98px) {
    background: url(${BackgroundImage}) no-repeat center top,
      /* Center the image horizontally */
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 1) 100%
        ),
      /* White fade */
        linear-gradient(227.92deg, #0f1c4d 55.75%, #7fccfa 97.83%); /* Blue gradient */

        background-position: top 0px right -100px, /* Image position */ top left,
        /* Gradient position */ top left; /* Another gradient position if needed */
  }
`;

// Welcome message
export const WelcomeMessage = styled.h1`
  margin: 0;
  color: ${colorPalette.blue50};
  ${typeScale.headingL};

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

// Header title
export const HeaderTitle = styled.p`
  margin: 0;
  color: ${colorPalette.blue200};
  ${typeScale.bodySM};
`;

// Headline
export const Headline = styled.h2`
  margin-top: 24px !important;
  margin-bottom: 0px;
  color: ${colorPalette.white};
  ${typeScale.headingMain};
`;
