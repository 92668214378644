import { styled } from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const StyledContainer = styled.div<{ disableScroll?: boolean }>`
  width: 100%;
  height: calc(100 * var(--vh) - 70px);
  overflow-y: ${({ disableScroll }) => (disableScroll ? "hidden" : "auto")};
  overflow-x: hidden;

  /* Optional: Customize scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorPalette.blue200};
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 24px auto 0;
  width: 60%;
  gap: 16px;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const Title = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 768px) {
    ${typeScale.headingSMMain};
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
`;

export const InfoText = styled.p`
  margin-top: 32px;
  margin-bottom: 0;
  text-align: center;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};

  @media (max-width: 768px) {
    ${typeScale.bodyXXS};
    text-align: center;
  }
`;

/* Card */
export const CardContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 14px;
  border-bottom: 1px solid ${colorPalette.blue100};

  &:last-child {
    border-bottom: none;
  }
`;

export const Label = styled.span`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
`;

export const Value = styled.span`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};
`;
