import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";
import { IUnit } from "./unitService";

export interface ITenant {
  tenant_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  birth_date: string;
  gender: string;
  nationality_code: string;
  created_at: string;
  updated_at: string;
  passport: IDocument[];
  documents: IDocument[];
}

export enum PaymentMethod {
  CASH = "cash",
  CHEQUE = "cheque",
  BANK_TRANSFER = "bank_transfer",
}

export const formatPaymentMethod = (method: PaymentMethod) => {
  switch (method) {
    case PaymentMethod.CHEQUE:
      return "Scheck";
    case PaymentMethod.CASH:
      return "Bar";
    case PaymentMethod.BANK_TRANSFER:
      return "Banküberweisung";
    default:
      return "-";
  }
};

export enum PaymentPurpose {
  RENT = "rent",
  SECURITY_DEPOSIT = "security_deposit",
  PET_DEPOSIT = "pet_deposit",
  ADMIN_FEE = "admin_fee",
}

export const formatPaymentPurpose = (purpose: PaymentPurpose) => {
  switch (purpose) {
    case PaymentPurpose.RENT:
      return "Miete";
    case PaymentPurpose.SECURITY_DEPOSIT:
      return "Kaution";
    case PaymentPurpose.PET_DEPOSIT:
      return "Haustierkaution";
    case PaymentPurpose.ADMIN_FEE:
      return "Verwaltungsgebühr";
    default:
      return "-";
  }
};

export interface ILeasePayment {
  payment_id: number;
  lease_id: number;
  due_date: string;
  amount: number;
  cheque_no: string | null;
  bank_name: string | null;
  remark: string | null;
  method: PaymentMethod;
  purpose: PaymentPurpose;
  bounced: boolean;
  payment_date: string | null;
  document: IDocument | null;
  created_at: string;
  updated_at: string;
}

export interface ILease {
  lease_id: number;
  unit: IUnit;
  tenant: ITenant;
  start_date: string;
  end_date: string;
  annual_rent_amount: number;
  total_rent_amount: number;
  deposit_amount: number;
  occupants: number;
  remark: string;
  created_at: string;
  updated_at: string;
  documents: IDocument[];
  payments: ILeasePayment[];
}

export const fetchLeases = async (unitId: number): Promise<ILease[]> => {
  const response = await fetch(`${API_URL}/units/${unitId}/leases`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.leases;
};

export const fetchLease = async (lease_id: number): Promise<ILease> => {
  const response = await fetch(`${API_URL}/leases/${lease_id}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const downloadLeaseDocument = async (
  leaseId: number,
  documentId: number
): Promise<Blob | null> => {
  const response = await fetch(
    `${API_URL}/leases/${leaseId}/documents/${documentId}/download`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};
