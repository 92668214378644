// UnitDetailPage.tsx
import { useCallback, useEffect, useMemo } from "react";
import Lottie from "lottie-react";
import { useLocation, useParams } from "react-router-dom";
import _ from 'lodash';
import useAlertStore from "../../stores/alertStore";
import { downloadUnitDocument, fetchUnit, UnitPurposeType } from "../../services/unitService";
import useUnitDetailStore from "../../stores/unitDetailStore";
import { getActiveLease } from "../../utils/helpers";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import DocumentSection from "../../components/document-section/document-section";
import { Header, HeaderSubtitle, HeaderSubtitleContainer, HeaderTagsContainer, HeaderTitle, StyledContainer } from "./unit-detail-page.styles";
import Tag from "../../components/tag/tag";
import GoodToKnowArticles from "../../components/good-to-know-articles/good-to-know-articles";
import PropertyUpdates from "../../components/property-updates/property-updates";
import UnitDetailsCard from "./components/unit-details-card/unit-details-card";
import NextRentPaymentCard from "./components/next-rent-payment-card/next-rent-payment-card";
import RentPaymentCard from "./components/rent-payments-card/rent-payments-card";
import ConstructionProgressCard from "../../components/construction-progress-card/construction-progress-card";
import PaymentPlanCard from "../../components/payment-plan-card/payment-plan-card";
import { SPACES_ENDPOINT } from "../../utils/constants";
import { ILease, PaymentPurpose } from "../../services/leaseService";
import { parseISO, isAfter } from "date-fns";
import MaterialIcon from "../../components/material-icon";
import { colorPalette } from "../../utils/theme";
import TenancyContractCard from "./components/tenancy-contract-card/tenancy-contract-card";
import { Col, Row } from "react-bootstrap";
import EmptyTenantCard from "./components/empty-tenant-card/empty-tenant-card";
import { IDocument } from "../../services/documentService";
import useIsMobile from "../../hooks/useIsMobile";
import PmUpsellingCard from "./components/pm-upselling-card/pm-upselling-card";
import PropertyCard from "./components/property-card/property-card";
import MobilePropertyCard from "./components/mobile-property-card/mobile-property-card";
import MoreDetails from "./components/more-details/more-details";

const UnitDetailPage = () => {
    let { unitId } = useParams();
    const location = useLocation();
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        unit,
        isInitialLoaded,
        isLoading,
        setUnit,
        setIsInitalLoaded,
        setIsLoading,
        reset,
    } = useUnitDetailStore();

    // Use Effects
    useEffect(() => {
        return () => {
            reset();
        };
    }, [location, reset]);

    useEffect(() => {
        if (unitId) {
            const loadUnit = async () => {
                try {
                    setIsLoading(true);
                    const unit = await fetchUnit(Number(unitId));
                    setUnit(unit);
                    setIsInitalLoaded(true);
                } catch (error) {
                    console.log(`Error while fetching unit data:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            loadUnit();
        }
    }, [unitId]);

    // Memos
    const activeLease: ILease | null = useMemo(() => {
        if (!unit) return null;

        const activeLease = getActiveLease(unit.leases);
        return activeLease;
    }, [unit]);

    const getLeaseHistory = (leases: ILease[], activeLease: ILease | null): ILease[] => {
        if (!leases || leases.length === 0) return [];

        const today = new Date();

        return leases.filter(lease =>
            lease !== activeLease && new Date(lease.end_date) < today
        );
    };

    const leaseHistory = useMemo(() => {
        if (!unit) return [];

        return getLeaseHistory(unit.leases, activeLease);
    }, [unit, activeLease]);

    const nextRentPayment = useMemo(() => {
        if (!activeLease) return null;
        return activeLease.payments.filter((p) => p.purpose === PaymentPurpose.RENT)
            .filter((p) => !p.payment_date)
            .sort((a, b) => parseISO(a.due_date).getTime() - parseISO(b.due_date).getTime())
            .find((p) => isAfter(parseISO(p.due_date), new Date()));

    }, [activeLease]);

    const unitUnderConstruction: boolean = useMemo(() => {
        if (unit) {
            const constructionProgressItems = unit.property.construction_progress_items;
            if (constructionProgressItems.length > 0) {
                return constructionProgressItems[0].percentage < 100
            }
        }
        return false;
    }, [unit]);

    // Functions
    const onDownloadUnitDocument = useCallback(async (unitDocument: IDocument) => {
        if (unitId) {
            try {
                const blob: Blob | null = await downloadUnitDocument(Number(unitId), unitDocument.document_id);
                if (blob) {
                    const blobUrl = window.URL.createObjectURL(blob);

                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = unitDocument.file_name;
                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            } catch (error) {
                console.log("error while downloading unit document: ", error);
            }
        }
    }, [unitId]);

    return (
        <StyledContainer>
            {isLoading && !isInitialLoaded ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                unit && (
                    <>
                        {/* HEADER */}
                        <Header>
                            <div className="d-flex justify-content-items align-items-center" style={{ gap: 16 }}>
                                <HeaderTitle>
                                    Unit {unit.unit_number} •{" "}
                                    <span onClick={() => window.open(`/project/${unit.property.property_id}`, '_blank')}>
                                        {unit.property.property_name}
                                    </span>
                                </HeaderTitle>
                                <HeaderTagsContainer>
                                    {activeLease && (
                                        <Tag label="Vermietet" type="unitStatus" variant="rented" size={isMobile ? "S" : "L"} />
                                    )}
                                    {!activeLease && !unitUnderConstruction && unit.minimum_annual_rent && (
                                        <Tag label="Mietersuche" type="unitStatus" variant="vacant" size={isMobile ? "S" : "L"} />
                                    )}
                                    {unitUnderConstruction && (
                                        <Tag label="Im Bau" type="unitStatus" variant="inProgress" size={isMobile ? "S" : "L"} />
                                    )}
                                    {unit.purpose_type === UnitPurposeType.SELF_INTEREST && (
                                        <Tag label="Selbstnutzer" type="unitStatus" variant="selfInterest" size={isMobile ? "S" : "L"} />
                                    )}
                                </HeaderTagsContainer>
                            </div>
                            <HeaderSubtitleContainer>
                                <MaterialIcon icon="distance" size={isMobile ? 14 : 20} color={colorPalette.textDark} />
                                <HeaderSubtitle>{unit.property.property_location}</HeaderSubtitle>
                            </HeaderSubtitleContainer>
                        </Header>

                        <div className="container-fluid" style={isMobile ? { padding: "0px 12px" } : { padding: "16px 12px 0px" }}>
                            {/* UNIT OVERVIEW */}
                            <div className="row gy-4">
                                <div className="col-12 col-lg-6 col-xl-5">
                                    <div className="row gy-4 h-100">
                                        <div className="col-12 h-100">
                                            <UnitDetailsCard
                                                images={unit.pictures.map(picture => `${SPACES_ENDPOINT}${picture.picture_path}`)}
                                                bedrooms={unit.bedroom}
                                                bathrooms={unit.bathroom}
                                                size={unit.square_feet}
                                                purchaseDate={unit.owner?.created_at ? new Date(unit.owner.created_at).toLocaleDateString() : "N/A"}
                                                purchasePrice={unit.owner?.purchase_price ?? 0}
                                                unit_number={unit.unit_number}
                                                property_name={unit.property.property_name}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 col-xl-7">
                                    <div className={`row h-100 ${isMobile ? "gy-2" : "gy-4"}`}>

                                        {activeLease && (
                                            <>
                                                {nextRentPayment && (
                                                    <div className="col-12">
                                                        <NextRentPaymentCard
                                                            payment={nextRentPayment}
                                                            onViewAll={() => console.log('View all transactions')}
                                                        />
                                                    </div>
                                                )}
                                                <div className="col-12">
                                                    <RentPaymentCard
                                                        unitNumber={unit.unit_number}
                                                        propertyName={unit.property.property_name}
                                                        annualRent={activeLease.annual_rent_amount}
                                                        grossReturn={((activeLease.annual_rent_amount / unit.owner!.purchase_price) * 100)}
                                                        onViewAll={() => console.log('View all tenancy contracts')}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {!activeLease && !unitUnderConstruction && unit.minimum_annual_rent && (
                                            <div className="col-12 h-100">
                                                <EmptyTenantCard />
                                            </div>
                                        )}

                                        {unitUnderConstruction && (
                                            <>
                                                <div className="col-12">
                                                    <ConstructionProgressCard
                                                        property_name={unit.property.property_name}
                                                        constructionProgressItems={unit.property.construction_progress_items.sort((a, b) => new Date(a.published_date).getTime() - new Date(b.published_date).getTime())}
                                                        showReportDownload
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <PaymentPlanCard
                                                        paymentPlanItems={unit.property.payment_plan_items.sort((a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime())}
                                                        property_name={unit.property.property_name}
                                                        purchase_price={unit.owner?.purchase_price}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {unit.purpose_type === UnitPurposeType.SELF_INTEREST && (
                                            <div className="col-12 h-100">
                                                <PmUpsellingCard />
                                            </div>
                                        )}

                                        {/*<div className="col-12">
                                            <HandoverChecklistCard
                                                checklistItems={[
                                                    {
                                                        title: 'PM Package Selection & Contract Signing',
                                                        status: 'completed',
                                                    },
                                                    {
                                                        title: 'Unit Inspection & Snagging',
                                                        details: 'Die Inspektion der Unit wurde erfolgreich durchgeführt. Falls Mängel festgestellt wurden, werden diese zeitnah behoben.',
                                                        status: 'completed',
                                                    },
                                                    {
                                                        title: 'Payment of Handover Charges',
                                                        details: 'Bitte überweise die offenen Gebühren, damit wir mit dem Handover fortfahren können.',
                                                        status: 'action',
                                                    },
                                                    {
                                                        title: 'Utility Connection',
                                                        status: 'pending',
                                                    },
                                                    {
                                                        title: 'Ownership Transfer & Title Deed',
                                                        status: 'pending',
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <CompletionPromptCard />
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            {!unitUnderConstruction && (activeLease || unit.minimum_annual_rent) ? (
                                <>
                                    <Col xs={12}>
                                        <TenancyContractCard
                                            activeLease={activeLease ?? undefined}
                                            leaseHistory={leaseHistory}
                                            unit_number={unit.unit_number}
                                            bedroom={unit.bedroom}
                                            square_feet={unit.square_feet}
                                            property_name={unit.property.property_name}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <DocumentSection
                                            documents={unit.documents}
                                            showCategory={false}
                                            onDownload={onDownloadUnitDocument}
                                        />
                                    </Col>
                                </>
                            ) : (
                                <Col>
                                    <DocumentSection
                                        documents={unit.documents}
                                        onDownload={onDownloadUnitDocument}
                                    />
                                </Col>
                            )}
                        </Row>

                        <GoodToKnowArticles />

                        {unitUnderConstruction && (
                            <PropertyUpdates propertyId={unit.property.property_id} propertyName={unit.property.property_name} />
                        )}

                        <MoreDetails
                            propertyMakaniNumber={unit.property.registration_number}
                            gasNumber={unit.gas_no}
                            dewaPremiseNumber={unit.dewa}
                        />

                        {isMobile ? (
                            <MobilePropertyCard property={unit.property} />
                        ) : (
                            <PropertyCard property={unit.property} />
                        )}
                    </>
                )
            )}
        </StyledContainer>
    );
};

export default UnitDetailPage;
