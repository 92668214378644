// ProfilePage.tsx
import HeaderStatus from "./components/header-status/header-status";
import { StyledContainer, Title } from "./profile-page.styles";
import { SPACES_ENDPOINT } from "../../utils/constants";
import { useEffect, useMemo } from "react";
import { fetchInvestor } from "../../services/investorService";
import useAlertStore from "../../stores/alertStore";
import useInvestorDetailStore from "../../stores/investorDetailStore";
import { Col, Row } from "react-bootstrap";
import BankDetailsBanner from "./components/bank-details-banner/bank-details-banner";
import SettingsList from "./components/settings-list/settings-list";
import ContactsList from "./components/contacts-list/contacts-list";
import { DocumentType, formatDocumentType } from "../../services/documentService";
import AboutUsList from "./components/about-us-list/about-us-list";

const ProfilePage = () => {
    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        investor,
        setInvestor,
        setAvatar,
        setIsInitalLoaded,
        setIsLoading
    } = useInvestorDetailStore();

    // Effects
    useEffect(() => {
        const loadInvestor = async () => {
            try {
                setIsLoading(true);
                const investor = await fetchInvestor();
                setInvestor(investor);
                if (investor.avatar && investor.avatar.length > 0) {
                    setAvatar(`${SPACES_ENDPOINT}${investor.avatar}`);
                }
                setIsInitalLoaded(true);
            } catch (error) {
                console.log(`Error while fetching investor data:`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
        loadInvestor();
    }, []);

    // Memos
    const hasAddress = useMemo(() => {
        if (!investor) return false;
        return investor.address.country !== null || investor.address.country !== undefined;
    }, [investor]);

    const expiredDocuments = useMemo(() => {
        if (!investor) return [];
        return investor.documents.filter(doc => {
            return doc.valid_until !== null && new Date(doc.valid_until) < new Date();
        });
    }, [investor]);

    const hasMandatoryDocumentsUploaded = useMemo(() => {
        if (!investor) return false;
        return investor.documents.filter(document => document.document_type === DocumentType.PASSPORT || document.document_type === DocumentType.PROOF_OF_ADDRESS).length > 1
    }, [investor]);

    // Header States
    const headerStatus = useMemo(() => {
        if (!hasAddress && !hasMandatoryDocumentsUploaded) return "inactive";
        if (!hasMandatoryDocumentsUploaded || expiredDocuments.length > 0) return "inactive";
        return "active";
    }, [expiredDocuments, investor, hasAddress, hasMandatoryDocumentsUploaded]);

    const headerMessage = useMemo(() => {
        if (!hasAddress && !hasMandatoryDocumentsUploaded) return "Bitte vervollständige untenstehende Details.";

        return expiredDocuments.length > 0 ?
            `${formatDocumentType(expiredDocuments[0].document_type ?? null)} ist abgelaufen, bitte lade eine Aktuelle kopie hoch`
            : !hasMandatoryDocumentsUploaded ? `Bite lade die benötigten Dokumente hoch.` : undefined
    }, [expiredDocuments, investor, hasAddress, hasMandatoryDocumentsUploaded]);

    const headerButtonTitle = useMemo(() => {
        return expiredDocuments.length > 0 ? `${formatDocumentType(expiredDocuments[0].document_type ?? null)} hochladen` : undefined
    }, [expiredDocuments]);

    return (
        <StyledContainer>
            <Title>Dein Account</Title>

            {investor && (
                <Row className="justify-content-center">
                    <Col xs={12} lg={8}>
                        <HeaderStatus
                            name={`${investor.first_name} ${investor.last_name}`}
                            avatar={investor?.avatar && investor.avatar.length > 0 ? `${SPACES_ENDPOINT}${investor.avatar}` : undefined}
                            investorSince={investor.created_at}
                            status={headerStatus}
                            message={headerMessage}
                            buttonText={headerButtonTitle}
                        />

                        {/* BANK DETAILS BANNER */}
                        {investor.bank_details.length === 0 && (
                            <BankDetailsBanner />
                        )}

                        <SettingsList
                            addressStored={hasAddress}
                            documentsUploaded={hasMandatoryDocumentsUploaded}
                            documentExpired={expiredDocuments.length > 0}
                            bankingDetailsStored={investor.bank_details.length > 0}
                        />

                        <ContactsList />

                        <AboutUsList />
                    </Col>
                </Row>
            )}
        </StyledContainer>
    );
};

export default ProfilePage;
