import React from 'react';
import {
    HeaderContainer,
    BackButton,
    Title,
    HelpContainer,
    HelpText,
    HelpLink,
    ProgressBarContainer,
    LoadingIndicator,
} from './progress-header.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';

interface ProgressHeaderProps {
    title: string;
    onBack: () => void;
    onHelpClick: () => void;
    isLoading?: boolean;
}

const ProgressHeader: React.FC<ProgressHeaderProps> = ({
    title,
    onBack,
    onHelpClick,
    isLoading = false,
}) => {
    return (
        <>
            <HeaderContainer className="d-flex justify-content-between align-items-center">
                <BackButton className="d-flex align-items-center">
                    <MaterialIcon icon="arrow_back" size={20} color={colorPalette.primaryDark} onClick={onBack} />
                    <Title>{title}</Title>
                </BackButton>
                <HelpContainer className="d-flex align-items-center">
                    <HelpText>Hast du Fragen?</HelpText>
                    <HelpLink onClick={onHelpClick}>
                        Kontaktiere uns
                        <MaterialIcon icon="call" size={20} color={colorPalette.blue500} />
                    </HelpLink>
                </HelpContainer>
            </HeaderContainer>
            {isLoading && (
                <ProgressBarContainer>
                    <LoadingIndicator />
                </ProgressBarContainer>
            )}
        </>
    );
};

export default ProgressHeader;
