import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

// Container for the entire section
export const Container = styled.div`
  width: 100%;
  margin-top: 48px;
  padding: 0 16px;

  .news-cards-wrapper {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

// Header Title
export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

// News Card
export const MobileUnitCard = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(0.98);
    cursor: pointer;
  }
`;

export const MobileUnitCardContent = styled.div`
  padding: 16px;
`;

export const UnitTitle = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}
`;

// Layout for the statistics
export const UnitStatistics = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-bottom: 12px;
`;

// Individual statistic item
export const UnitStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  ${typeScale.bodyXS}
  color: ${colorPalette.textMain};
`;

// Label for the statistics
export const UnitStatisticLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}
`;

export const UnitTagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const UnitRentalContainer = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 4px;
  align-items: center;

  p {
    margin: 0;
    color: ${colorPalette.textMain};
    ${typeScale.bodyMDMain};
  }

  span {
    margin: 0;
    color: ${colorPalette.textDark};
    ${typeScale.bodyXS};
  }
`;
