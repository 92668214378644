import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-left: 12px;
  padding-right: 12px;

  @media (max-width: 767.98px) {
    margin-top: 24px;
  }
`;

export const Headline = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Card = styled.div`
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  position: relative;
`;

export const CustomTable = styled.table`
  width: 100%;
  border: none !important;
  margin-bottom: 0;

  thead {
    th {
      background-color: ${colorPalette.blue200} !important;
      padding: 12px 16px !important;
      border: none !important;
      text-align: left;
      color: ${colorPalette.textMain} !important;
      ${typeScale.bodySMMain};

      &:first-child {
        width: 25%; /* Einheit column */
      }

      &:nth-child(2) {
        width: 20%; /* Typ column */
      }

      &:nth-child(3) {
        width: 25%; /* Zeitraum column */
      }

      &:nth-child(4) {
        width: 10%; /* Status column */
      }

      &:last-child {
        width: 20%; /* Aktionen column */
        text-align: right;
        padding-right: 24px !important;
      }
    }
  }

  tbody {
    td {
      padding: 24px 16px !important;
      color: ${colorPalette.textMain} !important;
      border-right: none !important;
      vertical-align: middle !important;
      ${typeScale.bodyXS};

      &:first-child {
        color: ${colorPalette.textMain} !important;
        ${typeScale.bodySMMain};
      }

      &:last-child {
        text-align: right;
        padding-right: 24px !important;
      }

      /* Remove margin-bottom from any text content */
      & > * {
        margin-bottom: 0 !important;
      }
    }

    tr:hover {
      background-color: ${colorPalette.blue50} !important;
      cursor: pointer;
    }

    /* Remove bottom border of the last row */
    tr:last-child td {
      border-bottom: none !important;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid ${colorPalette.blue200} !important;
    }
  }
`;
