import styled, { css } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const AlertContainer = styled.div<{ type: string }>`
  margin-top: 16px;
  border-radius: 8px;
  padding: 8px 16px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 600px;
  box-shadow: 0px 0px 10px 0px #00000040;

  ${({ type }) =>
    type === "success" &&
    css`
      background-color: ${colorPalette.successLight};
      color: ${colorPalette.successDark};
    `}

  ${({ type }) =>
    type === "warning" &&
    css`
      background-color: ${colorPalette.warningLight};
      color: ${colorPalette.warningDark};
    `}

  ${({ type }) =>
    type === "error" &&
    css`
      background-color: ${colorPalette.errorLight};
      color: ${colorPalette.errorDark};
    `}

  ${({ type }) =>
    type === "info" &&
    css`
      background-color: ${colorPalette.blue200};
      color: ${colorPalette.textMain};
    `}

  ${({ type }) =>
    type === "action" &&
    css`
      background-color: ${colorPalette.blue500};
      color: ${colorPalette.white};
    `}

    @media (max-width: 767.98px) {
    padding: 16px;
  }
`;

export const Title = styled.p`
  margin: 0;
  ${typeScale.bodyMDMain}

  @media (max-width: 767.98px) {
    ${typeScale.bodySMMain}
  }
`;

export const Message = styled.p`
  margin: 0;
  ${typeScale.bodySM}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS}
  }
`;

export const ActionButton = styled.button`
  background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 10px;
  padding: 0;

  &:hover {
    color: #0056b3;
  }
`;
