import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colorPalette.blue900};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
`;

export const MobileCloseButton = styled.div`
  position: absolute;
  right: 16px;
  top: 24px;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  @media (max-width: 767.98px) {
    height: 50%;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  color: white;
  font-size: 18px;
`;

export const ImageCount = styled.div`
  font-size: 1rem;
  color: white;
  position: absolute;
  top: calc(16px + 68px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1;
  width: auto;

  @media (max-width: 767.98px) {
    top: 0;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  text-align: center;
  color: white;
  padding: 16px;
`;

export const ImageTitle = styled.div`
  color: ${colorPalette.white};
  text-align: center;
  padding-bottom: 16px;
  ${typeScale.headingXXSMain}

  span {
    display: block;
    margin-top: 4px;
    color: ${colorPalette.textDark};
    ${typeScale.bodyXSMain}
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const NavigationButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 32px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  &:hover {
    color: ${colorPalette.textDark};
  }

  &:first-of-type {
    left: 16px;
  }

  &:last-of-type {
    right: 16px;
  }

  @media (max-width: 767.98px) {
    &:first-of-type {
      left: 0px;
    }

    &:last-of-type {
      right: 0px;
    }
  }
`;
