// useCustomNavigate.tsx
import { useNavigate, NavigateOptions, To } from 'react-router-dom';

const useCustomNavigate = () => {
    const navigate = useNavigate();

    const customNavigate = (to: To | number, options?: NavigateOptions & { clearHistory?: boolean }) => {
        if (options?.clearHistory) {
            window.history.replaceState(null, '', window.location.href);
        }

        navigate(to as To, options);
    };

    return customNavigate;
};

export default useCustomNavigate;
