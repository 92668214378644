import React from 'react';
import {
    CardContainer,
    Title,
    Description,
    ButtonContainer,
    List,
    ListItem,
    ListItemText,
    ListItemDescription,
    CheckIconContainer
} from './empty-tenant-card.styles';
import CTAButton from '../../../../components/cta-button/cta-button';
import { colorPalette } from '../../../../utils/theme';
import TogetherWaving from '../../../../assets/images/together_waving.png';
import AloneWaving from '../../../../assets/images/alone_waving.png';
import MaterialIcon from '../../../../components/material-icon';
import useIsMobile from '../../../../hooks/useIsMobile';

const EmptyTenantCard: React.FC = () => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <CardContainer>
                <div className="d-flex align-items-center" style={{ gap: 16, padding: "0px 16px" }}>
                    <img src={AloneWaving} width={45} height={59} alt="Together Waving" />
                    <div>
                        <Title>Wir suchen gerade nach einem großartigen Mieter für dich.</Title>
                        <Description>
                            Das tun wir, um die höchstmögliche Rendite für dich zu erzielen:
                        </Description>
                    </div>
                </div>
                <List>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.blue500} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Open-Houses am Wochenende</ListItemText>
                            <ListItemDescription>
                                Wir kümmern uns um alles - von Verträgen und Verlängerungserinnerungen bis zur Unterstützung beim Ein- und Auszug.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.blue500} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Tägliche Vertretung vor Ort</ListItemText>
                            <ListItemDescription>
                                Wir kümmern uns um alle Wünsche und Probleme der Mieter und lösen sie.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.blue500} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Werbung auf Vermietungsplattformen</ListItemText>
                            <ListItemDescription>
                                Wir entsenden ein Inspektionsteam, holen Angebote von vertrauenswürdigen Anbietern ein und überwachen deren Arbeit.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                </List>
                <ButtonContainer>
                    <CTAButton
                        variant="secondary"
                        size="L"
                        label={"Erfahre mehr"}
                        onClick={() => {
                            alert("Coming soon...");
                        }}
                        fullWidth={isMobile}
                    />
                </ButtonContainer>
            </CardContainer>
        );
    } else {
        return (
            <CardContainer>
                <img src={TogetherWaving} width={170} alt="Together Waving" />
                <Title>Wir suchen gerade nach einem großartigen Mieter für dich.</Title>
                <Description>
                    Das tun wir, um die höchstmögliche Rendite für dich zu erzielen:
                </Description>
                <List>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.primaryDark} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Veranstaltung von Open-Houses am Wochenende</ListItemText>
                            <ListItemDescription>
                                Wir kümmern uns um alles - von Verträgen und Verlängerungserinnerungen bis zur Unterstützung beim Ein- und Auszug.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.primaryDark} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Tägliche Vertretung vor Ort</ListItemText>
                            <ListItemDescription>
                                Wir kümmern uns um alle Wünsche und Probleme der Mieter und lösen sie.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                    <ListItem>
                        <CheckIconContainer>
                            <MaterialIcon icon="check" size={20} color={colorPalette.primaryDark} />
                        </CheckIconContainer>
                        <div>
                            <ListItemText>Werbung auf Vermietungsplattformen</ListItemText>
                            <ListItemDescription>
                                Wir entsenden ein Inspektionsteam, holen Angebote von vertrauenswürdigen Anbietern ein und überwachen deren Arbeit.
                            </ListItemDescription>
                        </div>
                    </ListItem>
                </List>
                <ButtonContainer>
                    <CTAButton
                        variant="secondary"
                        size="L"
                        label={"Erfahre mehr über unseren Vermietungsprozess"}
                        onClick={() => {
                            alert("Coming soon...");
                        }}
                        fullWidth={isMobile}
                    />
                </ButtonContainer>
            </CardContainer>
        );
    }
};

export default EmptyTenantCard;
