import React, { useEffect } from 'react';
import {
    ModalContainer,
    Header,
    HeaderTitle,
    CloseButton,
    Footer,
    HeaderSubtitle,
    Overlay,
    FooterText,
    PaymentList,
    PaymentItem,
    PaymentIcon,
    PaymentInfo,
    PaymentTitle,
    PaymentDetail,
    PaymentAmount,
    PaymentPercentage,
} from './payment-plan-modal.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';
import { IPaymentPlanItem } from '../../services/propertyService';
import { formatMoney } from '../../utils/helpers';

interface PaymentPlanModalProps {
    completionDate: string;
    property_name: string;
    paymentPlanItems: IPaymentPlanItem[];
    purchase_price?: number;
    onClose: () => void;
}

const PaymentPlanModal: React.FC<PaymentPlanModalProps> = ({
    completionDate,
    property_name,
    paymentPlanItems,
    purchase_price,
    onClose,
}) => {
    // Prevent scrolling down
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        const preventDefault = (e: TouchEvent) => {
            e.preventDefault();
        };

        document.addEventListener('touchmove', preventDefault, { passive: false });

        return () => {
            document.body.style.overflow = 'unset';
            document.removeEventListener('touchmove', preventDefault);
        };
    }, []);

    return (
        <Overlay onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <Header>
                    <div>
                        <HeaderTitle>Payment Plan: {property_name}</HeaderTitle>
                        <HeaderSubtitle>Geplante Fertigstellung: {completionDate}</HeaderSubtitle>
                    </div>
                    <CloseButton onClick={onClose}>
                        <MaterialIcon icon="close" size={24} color={colorPalette.blue500} />
                    </CloseButton>
                </Header>
                <PaymentList>
                    {paymentPlanItems.map((item, index) => (
                        <PaymentItem key={index}>
                            <div className="d-flex">
                                <PaymentIcon>
                                    <MaterialIcon icon="credit_card_clock" size={24} color={colorPalette.white} />
                                </PaymentIcon>
                                <PaymentInfo>
                                    <PaymentTitle>
                                        {new Date(item.due_date).toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })}
                                    </PaymentTitle>
                                    <PaymentDetail>Beschreibung: {item.description}</PaymentDetail>
                                </PaymentInfo>
                            </div>
                            <div>
                                {purchase_price ? (
                                    item.percentage ? (
                                        <>
                                            <PaymentAmount>AED {formatMoney(purchase_price, false)}</PaymentAmount>
                                            <PaymentPercentage>{Math.round(item.percentage)}%</PaymentPercentage>
                                        </>
                                    ) : (
                                        <PaymentAmount>AED {formatMoney(item.fixed_amount!, false)}</PaymentAmount>
                                    )
                                ) : (
                                    item.percentage ? (
                                        <PaymentAmount>{Math.round(item.percentage)}%</PaymentAmount>
                                    ) : (
                                        <PaymentAmount>AED {formatMoney(item.fixed_amount!, false)}</PaymentAmount>
                                    )
                                )}
                            </div>
                        </PaymentItem>
                    ))}
                </PaymentList>
                <Footer>
                    <FooterText>Fortschritt im Paymentplan dient lediglich zur Information und spiegelt keine tatsächlichen Zahlungen wieder. Statements of Accounts können direkt bei Metrical angefragt werden.</FooterText>
                </Footer>
            </ModalContainer>
        </Overlay>
    );
};

export default PaymentPlanModal;
