import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Container for the entire page
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  background-color: white;
`;

// Title styling
export const Title = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingLMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

// Subtitle styling
export const Subtitle = styled.p`
  margin-bottom: 36px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyLG};

  @media (max-width: 767.98px) {
    ${typeScale.bodySM};
  }
`;

// Section styling
export const Section = styled.div`
  margin-top: 32px;
`;

// Headline styling
export const Headline = styled.h4`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

// Text styling
export const Text = styled.p`
  margin-bottom: 24px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    ${typeScale.bodySM};
    text-align: justify;
  }
`;
