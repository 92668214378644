// ProfileEditBankDetailsPage.tsx
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";
import useInvestorDetailStore from "../../../../stores/investorDetailStore";
import CTAButton from "../../../../components/cta-button/cta-button";
import { useNavigate } from "react-router-dom";
import currencyCodes from 'currency-codes';
import CustomInputField from "../../../../components/custom-input-field/custom-input-field";
import {
    StyledContainer,
    HeaderContainer,
    Title,
    HeaderLeftContent,
    FormSection,
    ButtonCol,
    CategoryTitle,
    CategorySubtitle
} from "./edit-bank-details-page.styles";
import CustomSelectField, { CustomSelectFieldOptions } from "../../../../components/custom-select-field/custom-select-field";
import { createBankDetail, IBankDetail, updateBankDetail } from "../../../../services/bankDetailService";
import useAlertStore from "../../../../stores/alertStore";
import useIsMobile from "../../../../hooks/useIsMobile";

const ProfileEditBankDetailsPage = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const investor = useInvestorDetailStore(state => state.investor);
    const setInvestor = useInvestorDetailStore(state => state.setInvestor);

    // States
    const [isLoading, setIsLoading] = useState(false);
    const [accountHolder, setAccountHolder] = useState("");
    const [iban, setIban] = useState("");
    const [swift, setSwift] = useState("");
    const [bankname, setBankname] = useState("");
    const [branchNumber, setBranchNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [currency, setCurrency] = useState("EUR");
    const [currencyOptions, setCurrencyOptions] = useState<CustomSelectFieldOptions[]>([]);

    // Error States
    const [ibanError, setIbanError] = useState("");
    const [swiftError, setSwiftError] = useState("");
    const [banknameError, setBanknameError] = useState("");
    const [currencyError, setCurrencyError] = useState("");

    const [initialValues, setInitialValues] = useState({
        iban: "",
        swift: "",
        bankname: "",
        branchNumber: "",
        accountNumber: "",
        currency: "EUR"
    });

    // Effects
    useEffect(() => {
        if (investor) {
            setAccountHolder(`${investor.first_name} ${investor.last_name}`);

            if (investor.bank_details.length > 0) {
                const bankDetails = investor.bank_details[0];
                setIban(bankDetails.iban);
                setSwift(bankDetails.swift);
                setBankname(bankDetails.bank_name);
                setBranchNumber(bankDetails.branch_number ?? "");
                setAccountNumber(bankDetails.account_number ? bankDetails.account_number.toString() : "");
                setCurrency(bankDetails.currency);

                setInitialValues({
                    iban: bankDetails.iban,
                    swift: bankDetails.swift,
                    bankname: bankDetails.bank_name,
                    branchNumber: bankDetails.branch_number ?? "",
                    accountNumber: bankDetails.account_number ? bankDetails.account_number.toString() : "",
                    currency: bankDetails.currency
                });
            }
        }
    }, [investor]);

    useEffect(() => {
        const allowedCurrencies = ['EUR', 'USD', 'AED'];

        const currencies = currencyCodes.data
            .filter(({ code }) => allowedCurrencies.includes(code))
            .map(({ code, currency }) => ({
                value: code,
                label: `${currency} (${code})`
            }));

        setCurrencyOptions(currencies);
    }, []);

    const onClickSave = useCallback(async () => {
        if (!investor) return;

        let isValid = true;

        // Check if the iban is not empty
        if (!iban.trim()) {
            setIbanError("IBAN muss angegeben sein");
            isValid = false;
        }

        // Check if the swift is not empty
        if (!swift.trim()) {
            setSwiftError("SWIFT/BIC muss angegeben sein");
            isValid = false;
        }

        // Check if the bankname is not empty
        if (!bankname.trim()) {
            setBanknameError("Bankname muss angegeben sein");
            isValid = false;
        }

        // Check if the bankname is not empty
        if (!currency.trim()) {
            setCurrencyError("Währung muss ausgewählt sein");
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);

                let bankDetails: IBankDetail | null = null;
                if (investor.bank_details.length > 0) {
                    bankDetails = await updateBankDetail(
                        investor.bank_details[0].details_id,
                        iban,
                        swift,
                        bankname,
                        currency,
                        branchNumber.length > 0 ? branchNumber : null,
                        accountNumber.length > 0 ? Number(accountNumber) : null
                    );
                } else {
                    bankDetails = await createBankDetail(
                        iban,
                        swift,
                        bankname,
                        currency,
                        branchNumber.length > 0 ? branchNumber : null,
                        accountNumber.length > 0 ? Number(accountNumber) : null
                    );
                }

                if (bankDetails) {
                    showAlert("success", "Bankverbindung hinzugefügt!");
                    setInvestor({ ...investor, bank_details: [bankDetails] });
                }
            } catch (error) {
                console.log("error while creating/updating bank details:", error);

                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [investor, accountHolder, iban, swift, bankname, branchNumber, accountNumber, currency]);

    // Memos
    const hasChanges = useMemo(() => {
        return (
            iban !== initialValues.iban ||
            swift !== initialValues.swift ||
            bankname !== initialValues.bankname ||
            branchNumber !== initialValues.branchNumber ||
            accountNumber !== initialValues.accountNumber ||
            currency !== initialValues.currency
        );
    }, [iban, swift, bankname, branchNumber, accountNumber, currency, initialValues]);

    return (
        <StyledContainer>
            {investor && (
                <Row>
                    <Col xs={12} lg={10} xl={8}>
                        <HeaderContainer>
                            <HeaderLeftContent>
                                {!isMobile && (
                                    <MaterialIcon icon="arrow_back" size={24} color={colorPalette.textMain} onClick={() => navigate(-1)} />
                                )}
                                <Title>Bankverbindung ändern</Title>
                            </HeaderLeftContent>
                        </HeaderContainer>

                        <FormSection className="gy-3">
                            <Col xs={12}>
                                <CategoryTitle>Primäres Bankkonto</CategoryTitle>
                                <CategorySubtitle>An dieses Konto überweisen wir deine Mieteinnahmen der Wohnungen, die durch managd verwaltet werden. Deine Daten werden sicher und verschlüsselt gespeichert.</CategorySubtitle>
                            </Col>
                            <Col xs={12}>
                                <CustomInputField
                                    id="investor-bank-account-holder"
                                    type="text"
                                    value={accountHolder}
                                    placeholder="Kontoinhaber"
                                    onChange={setAccountHolder}
                                    onClear={() => setAccountHolder("")}
                                    disabled
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-bank-iban"
                                    type="text"
                                    value={iban}
                                    placeholder="IBAN"
                                    errorMessage={ibanError}
                                    onChange={(value) => {
                                        setIban(value);
                                        if (value.trim().length > 0) {
                                            setIbanError("");
                                        } else {
                                            setIbanError("IBAN muss angegeben sein");
                                        }
                                    }}
                                    onClear={() => {
                                        setIban("");
                                        setIbanError("");
                                    }}
                                    required
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-bank-swift"
                                    type="text"
                                    value={swift}
                                    placeholder="SWIFT-Code"
                                    errorMessage={swiftError}
                                    onChange={(value) => {
                                        setSwift(value);
                                        if (value.trim().length > 0) {
                                            setSwiftError("");
                                        } else {
                                            setSwiftError("SWIFT/BIC muss angegeben sein");
                                        }
                                    }}
                                    onClear={() => {
                                        setSwift("");
                                        setSwiftError("");
                                    }}
                                    required
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-bank-name"
                                    type="text"
                                    value={bankname}
                                    placeholder="Bankname"
                                    errorMessage={banknameError}
                                    onChange={(value) => {
                                        setBankname(value);
                                        if (value.trim().length > 0) {
                                            setBanknameError("");
                                        } else {
                                            setBanknameError("Bankname muss angegeben sein");
                                        }
                                    }}
                                    onClear={() => {
                                        setBankname("");
                                        setBanknameError("");
                                    }}
                                    required
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-bank-branch-number"
                                    type="text"
                                    value={branchNumber}
                                    placeholder="Filialnummer"
                                    onChange={setBranchNumber}
                                    onClear={() => setBranchNumber("")}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomInputField
                                    id="investor-bank-account-number"
                                    type="number"
                                    value={accountNumber}
                                    placeholder="Kontonummer"
                                    onChange={setAccountNumber}
                                    onClear={() => setAccountNumber("")}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <CustomSelectField
                                    id="investor-bank-currency"
                                    options={currencyOptions}
                                    value={currency}
                                    placeholder="Währung"
                                    errorMessage={currencyError}
                                    onChange={(value) => {
                                        setCurrency(value);
                                        setCurrencyError("");
                                    }}
                                    required
                                />
                            </Col>

                            <ButtonCol xs={12} sm={6}>
                                <CTAButton
                                    variant="primary"
                                    size="L"
                                    label="Änderungen speichern"
                                    onClick={onClickSave}
                                    loading={isLoading}
                                    disabled={!hasChanges || isLoading}
                                    fullWidth
                                />
                            </ButtonCol>
                            <ButtonCol xs={12} sm={6}>
                                <CTAButton
                                    variant="secondary"
                                    size="L"
                                    label="Abbrechen"
                                    onClick={() => navigate(-1)}
                                    fullWidth
                                />
                            </ButtonCol>

                        </FormSection>
                    </Col>
                </Row>
            )}
        </StyledContainer>
    );
};

export default ProfileEditBankDetailsPage;
