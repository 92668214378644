import React, { ReactElement, useMemo } from 'react';
import { SPACES_ENDPOINT } from '../../../../utils/constants';
import { formatMoney } from '../../../../utils/helpers';
import { UnitPurposeType } from '../../../../services/unitService';
import {
    UnitCardContainer,
    ImageContainer,
    UnitContent,
    UnitHeader,
    TitleTagsContainer,
    UnitTitle,
    UnitSubtitle,
    UnitDetails,
    UnitStatistics,
    UnitStatistic,
    UnitNumber,
    UnitTagsContainer,
    UnitStatus,
    UnitStatusItem,
    UnitStatisticLabel,
    UnitStatusLabel,
    Container,
    SubtitleWithIcon,
    ProgressBar,
    ProgressBarContainer,
    ProgressContainer,
    ProgressDescription,
    ProgressInfo,
    ProgressLabel,
    ProgressPercentage,
    ProgressTextWrapper,
    EstimatedAnnualRent,
    EstimatedAnnualRentContainer,
    TooltipIcon,
    EstimatedRoiPercentage,
} from './unit-card.styles';
import Tag from '../../../../components/tag/tag';
import { colorPalette } from '../../../../utils/theme';
import HandoverProgressTracker from './components/handover-progress-tracker/handover-progress-tracker';
import { IConstructionProgressItem } from '../../../../services/constructionProgressService';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MaterialIcon from '../../../../components/material-icon';
import useIsMobile from '../../../../hooks/useIsMobile';
import LazyLoadImage from '../../../../components/lazy-load-image/lazy-load-image';

interface UnitCardProps {
    banner?: ReactElement<any, any>,
    imageSrc?: string;
    propertyName: string;
    title: string;
    bedroom: number;
    bathroom: number;
    square_feet: number;
    purchasePrice: number;
    expectedAnnualRent?: number;
    rent?: number;
    constructionProgressItem?: IConstructionProgressItem,
    handoverStep?: number,
    acquisitionDate: string;
    purpose_type: UnitPurposeType;
    pmPackage?: "basic" | "premium";
    onClick: () => void;
}

const UnitCard: React.FC<UnitCardProps> = ({
    banner,
    imageSrc,
    propertyName,
    title,
    bedroom,
    bathroom,
    square_feet,
    purchasePrice,
    expectedAnnualRent,
    rent,
    constructionProgressItem,
    handoverStep,
    acquisitionDate,
    purpose_type,
    pmPackage,
    onClick,
}) => {
    const isMobile = useIsMobile();

    const isExtraSectionVisible = useMemo(() => {
        if (rent) {
            return true; // If rent is present, the section is visible
        }
        if (!constructionProgressItem && !rent && expectedAnnualRent) {
            return true; // If no construction progress item and expected annual rent is present
        }
        if (constructionProgressItem && constructionProgressItem.percentage < 100) {
            return true; // If construction progress is not complete
        }
        if (handoverStep) {
            return true; // If there is a handover step
        }
        return false; // Default to false if none of the conditions are met
    }, [rent, constructionProgressItem, expectedAnnualRent, purchasePrice, handoverStep]);

    return (
        <Container>
            {banner && (<>{banner}</>)}
            <UnitCardContainer onClick={onClick}>
                <ImageContainer>
                    <LazyLoadImage
                        src={`${SPACES_ENDPOINT}${imageSrc}`}
                        alt={title}
                        width={isMobile ? "100%" : 320}
                        height={isMobile ? 220 : "100%"}
                    />
                </ImageContainer>
                <UnitContent>
                    <UnitHeader>
                        <TitleTagsContainer>
                            <UnitTitle>{isMobile ? "" : "Unit "}{title} • {propertyName}</UnitTitle>
                            <UnitTagsContainer>
                                {rent && (
                                    <Tag label="Vermietet" type="unitStatus" variant="rented" size={isMobile ? "S" : "L"} />
                                )}
                                {!constructionProgressItem && !rent && expectedAnnualRent && (
                                    <Tag label="Mietersuche" type="unitStatus" variant="vacant" size={isMobile ? "S" : "L"} />
                                )}
                                {constructionProgressItem && constructionProgressItem.percentage < 100 && (
                                    <Tag label="Im Bau" type="unitStatus" variant="inProgress" size={isMobile ? "S" : "L"} />
                                )}
                                {purpose_type === UnitPurposeType.SELF_INTEREST && (
                                    <Tag label="Selbstnutzer" type="unitStatus" variant="selfInterest" size={isMobile ? "S" : "L"} />
                                )}
                                {pmPackage && (
                                    <Tag label="Premium" type="package" variant={pmPackage} tooltip="Nice premium content!" size={isMobile ? "S" : "L"} />
                                )}
                            </UnitTagsContainer>
                        </TitleTagsContainer>
                        <SubtitleWithIcon>
                            <MaterialIcon icon="distance" size={isMobile ? 12 : 20} color={colorPalette.textDark} />
                            <UnitSubtitle>Jumeirah Village Circle</UnitSubtitle>
                        </SubtitleWithIcon>
                    </UnitHeader>
                    <UnitDetails>
                        <UnitStatistics showBottomBorder={isExtraSectionVisible || !isMobile}>
                            <UnitStatistic>
                                <MaterialIcon icon="bed" size={isMobile ? 20 : 24} color={colorPalette.blue300} />
                                <UnitStatisticLabel>{bedroom} Bedroom</UnitStatisticLabel>
                            </UnitStatistic>
                            <UnitStatistic className="d-none d-md-flex">
                                <MaterialIcon icon="bathtub" size={isMobile ? 20 : 24} color={colorPalette.blue300} />
                                <UnitStatisticLabel>{bathroom} Bathroom</UnitStatisticLabel>
                            </UnitStatistic>
                            <UnitStatistic>
                                <MaterialIcon icon="straighten" size={isMobile ? 20 : 24} color={colorPalette.blue300} />
                                <UnitStatisticLabel>{square_feet} Sqft</UnitStatisticLabel>
                            </UnitStatistic>
                            <UnitStatistic>
                                <MaterialIcon icon="shoppingmode" size={isMobile ? 20 : 24} color={colorPalette.blue300} />
                                <UnitStatisticLabel>AED {formatMoney(purchasePrice, false)}</UnitStatisticLabel>
                            </UnitStatistic>
                        </UnitStatistics>
                        {rent ? (
                            <UnitStatus>
                                <UnitStatusItem>
                                    <UnitNumber>AED {formatMoney(rent, false)}</UnitNumber>
                                    <UnitStatusLabel>Jahresmiete</UnitStatusLabel>
                                </UnitStatusItem>
                                <UnitStatusItem>
                                    <UnitNumber className="text-end">{((rent / purchasePrice) * 100).toFixed(2)}%</UnitNumber>
                                    <UnitStatusLabel>Bruttorendite</UnitStatusLabel>
                                </UnitStatusItem>
                            </UnitStatus>
                        ) : !constructionProgressItem && !rent && expectedAnnualRent ? (
                            <UnitStatus>
                                {/* Minimum and Maximum Estimated Annual Rent */}
                                <UnitStatusItem>
                                    <EstimatedAnnualRentContainer>
                                        <EstimatedAnnualRent>
                                            {/* Calculate min and max as -10% and +10% of expectedAnnualRent */}
                                            {isMobile ?
                                                `AED ${formatMoney(expectedAnnualRent * 0.9, false)}`
                                                :
                                                `AED ${formatMoney(expectedAnnualRent * 0.9, false)} - AED ${formatMoney(expectedAnnualRent * 1.1, false)}`
                                            }
                                        </EstimatedAnnualRent>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Information zur berechneten Jahresmiete</Tooltip>}
                                        >
                                            <TooltipIcon>
                                                <MaterialIcon icon="info" size={20} color={colorPalette.textDark} />
                                            </TooltipIcon>
                                        </OverlayTrigger>
                                    </EstimatedAnnualRentContainer>
                                    <UnitStatusLabel>Erwartete Jahresmiete</UnitStatusLabel>
                                </UnitStatusItem>

                                {/* Minimum and Maximum Estimated ROI */}
                                <UnitStatusItem>
                                    <EstimatedRoiPercentage className="text-end">
                                        {/* Calculate min and max ROI as -10% and +10% of expectedAnnualRent */}
                                        {isMobile ?
                                            `${((expectedAnnualRent * 0.9 / purchasePrice) * 100).toFixed(2)}%`
                                            :
                                            `${((expectedAnnualRent * 0.9 / purchasePrice) * 100).toFixed(2)}% - ${((expectedAnnualRent * 1.1 / purchasePrice) * 100).toFixed(2)}%`
                                        }
                                    </EstimatedRoiPercentage>
                                    <UnitStatusLabel>Erwartete Bruttorendite</UnitStatusLabel>
                                </UnitStatusItem>
                            </UnitStatus>
                        ) : constructionProgressItem && constructionProgressItem.percentage < 100 ? (
                            <ProgressContainer>
                                <ProgressInfo>
                                    <ProgressTextWrapper>
                                        <ProgressLabel>Baufortschritt:</ProgressLabel>
                                        <ProgressDescription>{constructionProgressItem.description}</ProgressDescription>
                                    </ProgressTextWrapper>
                                    <ProgressPercentage>{Math.round(constructionProgressItem.percentage)}%</ProgressPercentage>
                                </ProgressInfo>
                                <ProgressBarContainer>
                                    <ProgressBar style={{ width: `${constructionProgressItem.percentage}%` }} />
                                </ProgressBarContainer>
                            </ProgressContainer>
                        ) : handoverStep ? (
                            <>
                                <HandoverProgressTracker
                                    steps={[
                                        { label: 'Vertragsabschluss', completed: true, actionRequired: false },
                                        { label: 'Zahlung Handover-Gebühren', completed: true, actionRequired: false },
                                        { label: 'Schlüsselübergabe', completed: false, actionRequired: true },
                                        { label: 'Inspektion', completed: false, actionRequired: false },
                                        { label: 'Vermietung', completed: false, actionRequired: false },
                                    ]}
                                    currentStep={2}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </UnitDetails>
                </UnitContent>
            </UnitCardContainer>
        </Container>
    );
};

export default UnitCard;
