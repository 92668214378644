import React, { useCallback, useState } from 'react';
import {
    ActionSheetContainer,
    Title,
    Description,
    Overlay,
    ImageIllustration,
    ButtonWrapper,
} from './onboarding-modal.styles';
import CTAButton from '../../components/cta-button/cta-button';
import FinishCoinIllustration from '../../assets/images/finish-coin.svg';
import useInvestorDetailStore from '../../stores/investorDetailStore';
import useAlertStore from '../../stores/alertStore';
import { updateOnboardingStep } from '../../services/investorService';

interface OnboardingProps {
    onClose: () => void;
}

const OnboardingModal: React.FC<OnboardingProps> = ({
    onClose,
}) => {
    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const { investor, setInvestor } = useInvestorDetailStore();

    // States
    const [isLoading, setIsLoading] = useState(false);

    const onFinishOnboarding = useCallback(async () => {
        if (investor) {
            try {
                setIsLoading(true);
                await updateOnboardingStep(investor.investor_id, investor.onboarding_step + 1);
            } catch (error) {
                console.log('error while finishing onboarding:', error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
                setInvestor({ ...investor, onboarding_step: investor.onboarding_step + 1 });
                onClose();
            }
        }
    }, [investor]);

    return (
        <Overlay>
            <ActionSheetContainer onClick={(e) => e.stopPropagation()}>
                <ImageIllustration src={FinishCoinIllustration} alt="Finish Coin Illustration" />
                <Title>Dein Account ist ready.</Title>
                <Description>Herzlichen Glückwunsch! Dein Konto ist jetzt vollständig aktiviert und einsatzbereit.</Description>
                <ButtonWrapper>
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Zum Dashboard"
                        onClick={onFinishOnboarding}
                        loading={isLoading}
                        fullWidth
                    />
                </ButtonWrapper>
            </ActionSheetContainer>
        </Overlay>
    );
};

export default OnboardingModal;
