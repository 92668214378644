import { Col, Row } from 'react-bootstrap';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';
import useInvestorDetailStore from '../../../../stores/investorDetailStore';
import { ButtonContainer, CardContainer, StyledContainer, Subtitle, Title } from './review-documents.styles';
import DocumentCard from './document-card/document-card';
import useAlertStore from '../../../../stores/alertStore';
import { useState, useCallback } from 'react';
import { DocumentUploadDialog } from '../../../../modals/document-upload-modal/document-upload-modal';
import { DocumentType, formatDocumentType } from '../../../../services/documentService';
import { uploadInvestorDocuments } from '../../../../services/investorService';

interface ReviewDocumentsProps {
    isLoading: boolean;
    onClickNext: () => void;
}

const ReviewDocuments: React.FC<ReviewDocumentsProps> = ({ isLoading, onClickNext }) => {
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const investor = useInvestorDetailStore(state => state.investor);
    const setInvestor = useInvestorDetailStore(state => state.setInvestor);

    // States
    const [isUploading, setIsUploading] = useState<DocumentType | null>(null);
    const [isUploadDocumentModalVisible, setIsUploadDocumentModalVisible] = useState<DocumentUploadDialog | null>(null);

    const onHandleUpdate = useCallback(async (file: File, expiryDate: Date | null) => {
        const document = isUploadDocumentModalVisible;
        if (investor && document) {
            try {
                setIsUploadDocumentModalVisible(null);
                setIsUploading(document.documentType);

                // Upload new passport documents
                if (document.documentType === DocumentType.PASSPORT) {
                    const newDocuments = await uploadInvestorDocuments(DocumentType.PASSPORT, [file], expiryDate ? [expiryDate.toDateString()] : []);

                    const investorDocuments = investor.documents;
                    investorDocuments.push(...newDocuments);
                    setInvestor({ ...investor, documents: investorDocuments });
                }

                // Upload new proof of address file
                if (document.documentType === DocumentType.PROOF_OF_ADDRESS) {
                    const newDocuments = await uploadInvestorDocuments(DocumentType.PROOF_OF_ADDRESS, [file]);

                    const investorDocuments = investor.documents;
                    investorDocuments.push(...newDocuments);
                    setInvestor({ ...investor, documents: investorDocuments });
                }

                showAlert("success", `${formatDocumentType(document.documentType)} erfolgreich hochgeladen!`);
            } catch (error) {
                console.log(`Error while upploading investor document (${formatDocumentType(document.documentType)}):`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUploading(null);
            }
        }
    }, [investor, isUploadDocumentModalVisible]);

    if (!investor) return null;

    return (
        <StyledContainer>
            <Row className="justify-content-center" style={{ marginTop: 32 }}>
                <Col xs={12} lg={8}>
                    <Title>Bitte lade folgende Dokumente hoch, um deinen Account zu verifizieren.</Title>
                    <Subtitle>Dies ist notwendig, um gesetzliche Regularien in den VAE einzuhalten.</Subtitle>

                    <CardContainer>
                        {/* Reisepass Document Card */}
                        <DocumentCard
                            investor_id={investor.investor_id}
                            documentType={DocumentType.PASSPORT}
                            subtitle="Lade deinen Reisepass hoch."
                            investor_document={investor.documents.find(document => document.document_type === DocumentType.PASSPORT)}
                            onUpload={() => setIsUploadDocumentModalVisible({ documentType: DocumentType.PASSPORT, enableDocumentExpiry: true })}
                            isUploading={isUploading === DocumentType.PASSPORT}
                            required
                        />

                        {/* Wohnsitznachweis Document Card */}
                        <DocumentCard
                            investor_id={investor.investor_id}
                            documentType={DocumentType.PROOF_OF_ADDRESS}
                            subtitle="Lade eine aktuelle Verbrauchsrechnung (Strom, Telefon) hoch."
                            investor_document={investor.documents.find(document => document.document_type === DocumentType.PROOF_OF_ADDRESS)}
                            onUpload={() => setIsUploadDocumentModalVisible({ documentType: DocumentType.PROOF_OF_ADDRESS, enableDocumentExpiry: false })}
                            isUploading={isUploading === DocumentType.PROOF_OF_ADDRESS}
                            required
                        />
                    </CardContainer>

                    <ButtonContainer>
                        <CTAButton
                            variant="primary"
                            size={isMobile ? "M" : "L"}
                            label="Fertig"
                            onClick={onClickNext}
                            loading={isLoading}
                            fullWidth
                        />
                    </ButtonContainer>

                    {!isLoading && (
                        <ButtonContainer>
                            <CTAButton
                                variant="ghost"
                                size={isMobile ? "M" : "L"}
                                label="Später hochladen"
                                onClick={onClickNext}
                                noPadding
                                fullWidth
                            />
                        </ButtonContainer>
                    )}
                </Col>
            </Row>
        </StyledContainer>
    );
};

export default ReviewDocuments;
