import React from 'react';
import {
    StepperContainer,
    Step,
    StepIcon,
    StepLine,
    ProgressWrapper,
    ProgressDescription,
    ProgressInfo,
    ProgressLabel,
    ProgressPercentage,
    ProgressTextWrapper,
    ProgressContainer,
} from './handover-progress-tracker.styles';
import MaterialIcon from '../../../../../../components/material-icon';
import { colorPalette } from '../../../../../../utils/theme';

interface StepData {
    label: string;
    completed: boolean;
    actionRequired: boolean;
}

interface HandoverProgressTrackerProps {
    steps: StepData[];
    currentStep: number;
}

const HandoverProgressTracker: React.FC<HandoverProgressTrackerProps> = ({ steps, currentStep }) => {
    return (
        <ProgressContainer>
            <ProgressInfo>
                <ProgressTextWrapper>
                    <ProgressLabel>Handover:</ProgressLabel>
                    <ProgressDescription>Zahlung der Handover-Gebühren</ProgressDescription>
                </ProgressTextWrapper>
                <ProgressPercentage>{currentStep}/{steps.length}</ProgressPercentage>
            </ProgressInfo>
            <StepperContainer>
                {/* Add line before first step */}
                <StepLine completed={steps[0].completed} actionRequired={false} first />

                {steps.map((step, index) => (
                    <ProgressWrapper key={index}>
                        <Step completed={step.completed}>
                            <StepIcon completed={step.completed} actionRequired={step.actionRequired}>
                                {(step.completed || step.actionRequired) && (
                                    <MaterialIcon
                                        icon={step.completed ? "check" : "warning"}
                                        size={10}
                                        color={colorPalette.white}
                                        zIndex={3}
                                    />
                                )}
                            </StepIcon>
                        </Step>
                        {index < steps.length - 1 && (
                            <StepLine completed={steps[index + 1].completed} actionRequired={steps[index + 1].actionRequired} />
                        )}
                    </ProgressWrapper>
                ))}

                {/* Add line after the last step */}
                <StepLine completed={false} actionRequired={false} last />
            </StepperContainer>
        </ProgressContainer>
    );
};

export default HandoverProgressTracker;
