import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";

export interface IContract {
  property_management_contract_documents: IDocument[];
  tenancy_contract_documents: IDocument[];
  purchase_contract_documents: IDocument[];
}

export const fetchContractData = async (): Promise<IContract> => {
  const response = await fetch(`${API_URL}/contracts/`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};
