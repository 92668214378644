import React, { useCallback, useState } from 'react';
import {
    Container,
    CardContainer,
    ViewAllLink,
    ExtraDetailItem,
    ExtraDetailLabel,
    ExtraDetailValue,
    IconWrapper,
    ExtraDetailHeadline,
    NoActiveContractDescription,
    NoActiveContractTitle,
    NoActiveContractContainer,
    Title,
    HeaderContainer,
} from './tenancy-contract-card.styles';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import { formatMoney } from '../../../../utils/helpers';
import { downloadLeaseDocument, ILease, PaymentPurpose } from '../../../../services/leaseService';
import DocumentCard from '../../../../components/document-card/document-card';
import CTAButton from '../../../../components/cta-button/cta-button';
import ContractHistorySidebarModal from '../../../../modals/sidebar/contract-history-sidemodal/contract-history-sidemodal';
import HouseKeys from '../../../../assets/images/house_keys.png';
import { IDocument } from '../../../../services/documentService';
import useIsMobile from '../../../../hooks/useIsMobile';
import { Col, Row } from 'react-bootstrap';
import DetailItem from '../../../../components/detail-item/detail-item';
import DocumentSection from '../../../../components/document-section/document-section';

interface TenancyContractCardProps {
    activeLease: ILease | undefined;
    leaseHistory: ILease[];
    unit_number: string;
    property_name: string;
    bedroom: number;
    square_feet: number;
}

const TenancyContractCard: React.FC<TenancyContractCardProps> = ({
    activeLease,
    leaseHistory,
    unit_number,
    property_name,
    bedroom,
    square_feet
}) => {
    const isMobile = useIsMobile();

    // States
    const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);

    // Functions
    const onDownloadLeaseDocument = useCallback(async (leaseDocument: IDocument) => {
        if (activeLease) {
            try {
                const blob: Blob | null = await downloadLeaseDocument(activeLease.lease_id, leaseDocument.document_id);
                if (blob) {
                    const blobUrl = window.URL.createObjectURL(blob);

                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = leaseDocument.file_name;
                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            } catch (error) {
                console.log("error while downloading lease document: ", error);
            }
        }
    }, [activeLease]);

    if (isMobile && !activeLease) return (<></>);

    return (
        <>
            <HeaderContainer>
                <Title>Mietdetails</Title>
                {leaseHistory.length > 0 && (
                    <CTAButton
                        variant="ghost"
                        size="S"
                        label="Miethistorie anzeigen"
                        icon="arrow_outward"
                        color={colorPalette.blue500}
                        onClick={() => setIsHistoryModalVisible(true)}
                        noPadding
                    />
                )}
            </HeaderContainer>
            <Container>
                <CardContainer centerContent={activeLease ? false : true}>
                    {activeLease ? (
                        <Row>
                            {/* TENANT */}
                            <Col xs={12} md={6}>
                                <DetailItem icon="location_away" label="Mieter" value={`${activeLease.tenant.first_name} ${activeLease.tenant.last_name}`} />
                            </Col>

                            {/* AMOUNT OF CHEQUES */}
                            <Col xs={12} md={6}>
                                <DetailItem icon="local_atm" label="Anzahl an Schecks" value={activeLease.payments.filter(payment => payment.purpose === PaymentPurpose.RENT).length} />
                            </Col>

                            {/* START DATE */}
                            <Col xs={12} md={6}>
                                <DetailItem icon="event_upcoming" label="Startdatum Mietvertrag" value={new Date(activeLease.start_date).toLocaleDateString()} hideBottomBorder />
                            </Col>

                            {/* SECURITY DEPOSIT */}
                            <Col xs={12} md={6}>
                                <DetailItem icon="move_to_inbox" label="Mietkaution" value={`AED ${formatMoney(activeLease.deposit_amount)}`} hideBottomBorder />
                            </Col>
                        </Row>
                    ) : (
                        <NoActiveContractContainer>
                            <img src={HouseKeys} width={130} style={{ marginBottom: 24 }} alt="House Keys" />
                            <NoActiveContractTitle>Du hast im Moment keinen aktiven Mietvertrag.</NoActiveContractTitle>
                            <NoActiveContractDescription>Sobald dein Mieter den Vertrag unterschrieben hat, findest du alle Details hier.</NoActiveContractDescription>
                            <CTAButton
                                variant="tertiary"
                                size="L"
                                label="Miethistorie anzeigen"
                                onClick={() => {
                                    alert("Coming soon...");
                                }}
                            />
                        </NoActiveContractContainer>
                    )}
                </CardContainer>

                {/* LEASE DOCUMENTS */}
                {activeLease && activeLease.documents.length > 0 && (
                    <DocumentSection
                        documents={activeLease.documents}
                        onDownload={onDownloadLeaseDocument}
                    />
                )}
            </Container>

            <ContractHistorySidebarModal
                leases={leaseHistory}
                unit_number={unit_number}
                property_name={property_name}
                bedroom={bedroom}
                square_feet={square_feet}
                isVisible={isHistoryModalVisible}
                onClose={() => setIsHistoryModalVisible(false)}
            />
        </>
    );
};

export default TenancyContractCard;
