// ArticleDetailPage.tsx
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";
import useCustomNavigate from "../../../../hooks/useCustomNavigate";
import useIsMobile from "../../../../hooks/useIsMobile";
import { ArticleImage, ArticleTitle, ArticleSubtitle, HeaderContainer, SharingContainer, StyledContainer, Title, ArticleDescription, Headline, ThreeDots, BulletPointList, BulletPointItem, ArticleText, FilterContainer, StyledLink, AuthorRole, AuthorAvatar, AuthorName, SubHeadline, Divider, BottomContainer, ArticleImageContainer, PlayButton, YouTubeFrame, VideoLoadingContainer } from "./article-detail-page.styles";
import InheritancePlanningImage from "../../../../assets/images/articles/inheritance-planning-large.jpeg";
import InheritancePlanningMobileImage from "../../../../assets/images/articles/inheritance-planning-mobile.jpeg";
import Filter from "../../../../components/filter/filter";
import CTAButton from "../../../../components/cta-button/cta-button";
import MoreArticles from "./components/more-articles";
import { useCallback, useState } from "react";

const ArticleDetailPage = () => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    // States
    const [showVideo, setShowVideo] = useState(false);
    const [videoLoaded, setVideoLoaded] = useState(false);

    // Functions
    const handleShareClick = useCallback(() => {
        const currentUrl = window.location.href;

        if (isMobile && navigator.share) {
            // Use the Web Share API for mobile devices
            navigator.share({
                title: document.title,
                text: "Schau dir folgenden Artikel von Managd an!",
                url: currentUrl,
            })
                .then(() => {
                    console.log("Successful share");
                })
                .catch((error) => {
                    console.error("Error sharing:", error);
                });
        } else {
            // Fallback to copy the link to clipboard on desktops or if Web Share API is unsupported
            navigator.clipboard.writeText(currentUrl)
                .then(() => {
                    alert("Der Link wurde in die Zwischenablage kopiert.");
                })
                .catch((err) => {
                    console.error("Failed to copy the link:", err);
                });
        }
    }, [isMobile]);

    const handlePlayButtonClick = () => {
        setShowVideo(true);
    };

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    const renderHeaderContent = useCallback(() => {
        return (
            <ArticleImageContainer>
                {!showVideo ? (
                    <>
                        <ArticleImage
                            src={isMobile ? InheritancePlanningMobileImage : InheritancePlanningImage}
                            alt="Article Thumbnail"
                            showBorderRadius={!isMobile}
                        />
                        <PlayButton onClick={handlePlayButtonClick}>
                            <MaterialIcon icon="play_arrow" size={48} color={colorPalette.white} />
                        </PlayButton>
                    </>
                ) : (
                    <>
                        {/* Show loading container until the video is fully loaded */}
                        {!videoLoaded && (
                            <VideoLoadingContainer>
                                <PlayButton>
                                    <MaterialIcon icon="play_arrow" size={48} color={colorPalette.white} />
                                </PlayButton>
                            </VideoLoadingContainer>
                        )}
                        
                        {/* Render YouTubeFrame initially with visibility control */}
                        <YouTubeFrame
                            src="https://www.youtube.com/embed/XZ0u551hldM?autoplay=1"
                            title="DGMA Legal YouTube Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            showBorderRadius={!isMobile}
                            onLoad={handleVideoLoad}
                            style={{ display: videoLoaded ? 'block' : 'none' }}
                        />
                    </>
                )}
            </ArticleImageContainer>
        );
    }, [videoLoaded, showVideo]);

    return (
        <StyledContainer>
            {!isMobile && (
                <HeaderContainer>
                    <MaterialIcon icon="arrow_back" size={24} color={colorPalette.textMain} onClick={() => navigate(-1)} />
                    <Title>Knowledge Hub</Title>
                </HeaderContainer>
            )}

            {isMobile && (
                renderHeaderContent()
            )}

            <Row className="justify-content-center flex-grow-1" style={{ paddingLeft: 16, paddingRight: 16 }}>
                <Col xs={12} lg={10} xl={8}>
                    <ArticleTitle>Nachlassplanung</ArticleTitle>
                    <ArticleSubtitle>Sicheres Vererben für Immobilienbesitzer in den VAE.</ArticleSubtitle>

                    <SharingContainer>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                            <AuthorAvatar />
                            <div>
                                <AuthorName>DGMA Legal Services</AuthorName>
                                <AuthorRole>Partner Content</AuthorRole>
                            </div>
                        </div>
                        <MaterialIcon icon="share" size={24} color={colorPalette.textDark} onClick={handleShareClick} />
                    </SharingContainer>

                    {!isMobile && (
                        renderHeaderContent()
                    )}

                    <ArticleDescription>Trotz des großen Anteils internationaler Investoren und einem Expat-Anteil von 80% sind die Vereinigten Arabischen Emirate in Sachen Erbschaft sehr stark von den Prinzipien der Sharia geprägt.</ArticleDescription>
                    <ArticleDescription>In diesem Artikel klären wir, was das für ausländische Investoren im Bezug auf ihre Vermögenswerte in den VAE oder für Auswanderer, die ihren Lebensmittelpunkt in die VAE verlagern, bedeutet.</ArticleDescription>

                    <ThreeDots />

                    <Headline>Erbrecht nach Sharia bedeutet im Allgemeinen:</Headline>
                    <BulletPointList>
                        <BulletPointItem>Die Ehefrau erbt nicht automatisch die Hälfte, sie bekommt nur 1/8</BulletPointItem>
                        <BulletPointItem>Töchter erben grundsätzlich weniger als Söhne</BulletPointItem>
                        <BulletPointItem>Sollte kein Testament registriert worden sein, muss ein Nachlass-verfahren vor dem Nachlassgericht in den VAE geführt werden</BulletPointItem>
                        <BulletPointItem>Das Sorgerecht für minderjährige Kinder, die mit ihren Eltern in den VAE leben, fällt bei Versterben des Vaters nicht automatisch an die Mutter</BulletPointItem>
                        <BulletPointItem>
                            Die Aufteilung des Nachlasses geschieht nach den Prinzipen der Sharia. Unter folgendem&nbsp;
                            <StyledLink href="https://www.tamm.abudhabi/wb/doh/inheritance-calculator?lang=en" target="_blank" rel="noopener noreferrer">
                                Link
                            </StyledLink>
                            &nbsp;kann individuell berechnet werden, welches Familienmitglied welchen Teil des Nachlasses bekommen würde.
                        </BulletPointItem>
                    </BulletPointList>

                    <ThreeDots />

                    <Headline>Wie läuft ein Nachlassverfahren ab, wenn kein Testament vorhanden ist?</Headline>
                    <ArticleText>Der Nachlassprozess vor einem lokalen Gericht ist kosten- und zeitaufwendig. Darüber hinaus wird der Prozess in einer Sprache geführt, die den meisten gänzlich unzugänglich ist!</ArticleText>
                    <ArticleText>Zunächst muss ein lokaler Anwalt beauftragt werden, das Nachlassverfahren einzuleiten. Nur lokale Anwälte, also Anwälte, die bei emiratischen Anwalts-kanzleien beschäftigt sind, dürfen vor Gericht auftreten.  Diese Beauftragung ist oft mit hohen Kosten verbunden. Amtssprache ist Arabisch! </ArticleText>
                    <ArticleText><strong>Alle Anträge und Dokumente müssen auf Arabisch, das heißt entsprechend übersetzt, vorgelegt werden.</strong></ArticleText>
                    <ArticleText>Nach Einleitung des Nachlassverfahrens muss zwingend nachgewiesen werden, dass der Erblasser kein Muslim gewesen ist. Dies geschieht in der Regel durch zwei Zeugen, die in Person bei Gericht auftreten, und über die Familienkonstellation des Erblassers Auskunft erteilen müssen.</ArticleText>
                    <ArticleText>Im Anschluss werden alle übermittelten Dokumente und Anträge von einem Richter überprüft und ein Nachlassbeschluss erlassen. In diesem werden die entsprechenden Erbquoten der Erben auf Grundlage der Sharia bestimmt und ausgewiesen.</ArticleText>
                    <ArticleText>Es kann die Anwendung des Heimatrechts des Erblassers beantragt werden. Hierbei ist jedoch Vorsicht geboten, da der Richter in Folge das Gesetz einer Jurisdiktion anwenden muss, die ihm gänzlich unbekannt ist! </ArticleText>

                    <ThreeDots />

                    <Headline>Die Lösung?</Headline>
                    <ArticleText>Die Registrierung eines Testaments mit dem Dubai International Financial Centre (DIFC) Courts Wills Service mit DGMA.</ArticleText>
                    <ArticleText>Der DIFC Courts Wills Service ist eine gemeinsame Initiative der Regierung von Dubai und der DIFC Courts. Der Wills Service wurde durch den Beschluss Nr. 4 von 2014 durch seine Hoheit Sheikh Maktoum bin Mohammed bin Rashid Al Maktoum, dem Präsidenten des Dubai International Financial Centre (DIFC), eingerichtet und durch das Gesetz Nr. 15 von 2017 nochmals bekräftigt.</ArticleText>

                    <SubHeadline>Wer kann ein Testament registrieren?</SubHeadline>
                    <ArticleText>Nicht-Muslime über 21 Jahren, die Vermögenswerte in den VAE haben und/oder dort leben können ein Testament mit dem DIFC Courts Wills Service registrieren.</ArticleText>

                    <SubHeadline>Was kann in dem Testament geregelt werden?</SubHeadline>
                    <ArticleText>Es können die Verteilung des Nachlasses an die Erben, Vermächtnisse, aber auch  die Vormundschaft für Minderjährige Kinder geregelt werden.</ArticleText>

                    <SubHeadline>Muss man in den VAE leben um ein DIFC Testament zu registrieren?</SubHeadline>
                    <ArticleText>Nein, es müssen lediglich Vermögenswerte in den VAE vorhanden sein.</ArticleText>

                    <Divider />

                    <Headline>Weitere Informationen zum Testament können im Rahmen einem kostenlosen Erstgespräch mit unserer Partnerkanzlei DGMA eingeholt werden.</Headline>
                    <CTAButton
                        variant="primary"
                        size="M"
                        label="Kostenlose Beratung buchen"
                        icon="open_in_new"
                        onClick={() => {
                            window.open('https://calendly.com/dgma-legal/erstberatung-testamente', '_blank', 'noopener,noreferrer');
                        }}
                        fullWidth={isMobile}
                    />

                    <Divider />

                    <SubHeadline>Über DGMA Legal Consultancy</SubHeadline>
                    <ArticleText>Das Umfeld für Auswanderer und Unternehmer in Dubai befindet sich im steten Wandel. Wir von DGMA fühlen uns tief mit unserer Wahlheimat in den Vereinigten Arabischen Emiraten verbunden und möchten für unsere deutschsprachigen Mandanten alle Türen zum rechtssicheren Wegzug und einer unbürokratischen Unternehmensführung im Ausland öffnen.</ArticleText>
                    <ArticleText>Die Erarbeitung individueller Lösungen, die genau auf die Vorhaben und Strategien unserer Mandanten abgestimmt werden, ist unsere Stärke auf dem unübersichtlichen Consulting-Markt in den Vereinigten Arabischen Emiraten.</ArticleText>

                    <CTAButton
                        variant="ghost"
                        size="L"
                        label="Zur DGMA Website"
                        icon="open_in_new"
                        onClick={() => {
                            window.open('https://dgma-legal.com/', '_blank', 'noopener,noreferrer');
                        }}
                        noPadding
                    />

                    <FilterContainer>
                        <Filter label="Handover" disabled />
                        <Filter label="Mietverwaltung" disabled />
                        <Filter label="Services" disabled />
                    </FilterContainer>
                </Col>
            </Row>

            <BottomContainer>
                <Divider />

                <MoreArticles currentVisibleArticleId={3} />
            </BottomContainer>

        </StyledContainer>
    );
};

export default ArticleDetailPage;
